import {
  Button,
  Header,
  Table,
} from '@cloudscape-design/components';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AddProjectModal from '../../components/Projects/addProjectModal';
import useCustomerProjects from '../../hooks/use-customerProjects';

export default function CustomerProjectsList() {
  const navigate = useNavigate();
  const [openAddProjectModal, setOpenAddProjectModal] = useState(false);
  const { customerProjects, isLoading } = useCustomerProjects();

  const projectRows = useMemo(() => customerProjects.map((project) => ({
    ...project,
    filename: (
      <span style={{ whiteSpace: 'normal' }}>{project.filename}</span>
    ),
    notes: (
      <span style={{ whiteSpace: 'normal' }}>{project.notes}</span>
    ),
    linkButton: (
      <Button
        variant="inline-link"
        target="_blank"
        href={project.adminLink}
      >
        {(/.*\/part\/([^/]+)/).test(project.adminLink) ? project.adminLink?.split('/').pop() : project.adminLink}
      </Button>
    ),
    toolButton: (
      <Button
        variant="inline-link"
        onClick={() => navigate(`/tools?tools_table-key=project&tools_table-operator=%3D&tools_table-value=${encodeURIComponent(project.name)}&tools_table-operation=and`)}
      >
        Check Tools
      </Button>
    ),
  })), [customerProjects, navigate]);

  return (
    <>
      <Table
        header={(
          <Header
            variant="awsui-h1-sticky"
            actions={(
              <Button onClick={() => setOpenAddProjectModal(true)}>
                Create a Customer Project
              </Button>
            )}
          >
            Customer Projects
          </Header>
      )}
        variant="full-page"
        stickyHeader
        loading={isLoading}
        items={projectRows}
        resizableColumns
        columnDefinitions={[
          {
            id: 'name',
            header: 'Project ID',
            cell: (item) => item.name,
            width: 120,
          },
          {
            id: 'filename',
            header: 'File Name',
            cell: (item) => item.filename,
          },
          {
            id: 'material',
            header: 'Material',
            cell: (item) => item.material,
          },
          {
            id: 'partId',
            header: 'Part ID',
            cell: (item) => item.linkButton,
          },
          {
            id: 'tools',
            header: 'Tools',
            cell: (item) => item.toolButton,
          },
          {
            id: 'notes',
            header: 'Notes',
            cell: (item) => item.notes,
          },
        ]}
      />
      {openAddProjectModal && (
        <AddProjectModal
          projects={customerProjects}
          open={openAddProjectModal}
          onClose={() => setOpenAddProjectModal(false)}
        />
      )}
    </>
  );
}
