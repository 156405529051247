import { Button, Header } from '@cloudscape-design/components';
import React, { useMemo, useState } from 'react';

import DataTable from '../../components/DataTable';
import AddToolModal from '../../components/Projects/addToolModal';
import useCustomerProjects from '../../hooks/use-customerProjects';
import useInternalProjects from '../../hooks/use-internalProjects';

export default function ToolsPage() {
  const { customerProjectOptions } = useCustomerProjects();
  const { internalProjectOptions } = useInternalProjects();

  const [openCreateModal, setOpenCreateModal] = useState(false);

  const projectOptions = useMemo(() => {
    const opts = [
      { label: 'Customer Projects', options: customerProjectOptions },
      { label: 'Internal Projects', options: internalProjectOptions },
    ];
    return opts;
  }, [customerProjectOptions, internalProjectOptions]);

  const filteringOptions = useMemo(() => [...customerProjectOptions, ...internalProjectOptions]
    .map((option) => ({
      propertyKey: 'project',
      value: option.value,
    })), [customerProjectOptions, internalProjectOptions]);

  const columnDefinitions = useMemo(() => [
    {
      id: 'project',
      header: 'Project',
      cell: (e) => e.project,
    },
    {
      id: 'name',
      header: 'Name',
      cell: (e) => e.name,
    },
    {
      id: 'createTime',
      header: 'Create Time',
      cell: (e) => (e.createTime ? e.createTime.toDate().toLocaleString() : ''),
    },
    {
      id: 'cavities',
      header: 'Cavities',
      cell: (e) => e.cavities?.map((c) => c.name).join(', '),
    },
    {
      id: 'notes',
      header: 'Notes',
      cell: (e) => e.notes,
    },
  ], []);

  return (
    <div>
      <DataTable
        id="tools_table"
        header={(
          <Header
            variant="awsui-h1-sticky"
            actions={(
              <Button onClick={() => setOpenCreateModal(true)}>Create tool</Button>
            )}
          >
            Tools
          </Header>
        )}
        type="tools"
        orderField="createTime"
        filters={[]}
        pageSize={25}
        columnDefinitions={columnDefinitions}
        filteringOptions={filteringOptions}
        filteringProperties={[
          {
            key: 'project',
            operators: ['='],
            propertyLabel: 'Project',
            groupValuesLabel: 'Project IDs',
          },
        ]}
        stickyColumns={{ first: 2, last: 0 }}
        resizableColumns
        emptyMessage="No tools found"
      />
      <AddToolModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        projects={projectOptions}
      />
    </div>
  );
}
