import {
  Alert,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@cloudscape-design/components';
import {
  FailureTagsMap, InspectionValues, StepProcessMap, StepTypes,
} from '@parallel-fluidics/constants';
import {
  doc,
  getFirestore,
  onSnapshot,
  runTransaction,
  serverTimestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import FileUploader from '../../components/FileUploader';
import { useGetOrderById } from '../../hooks/use-basicQueries';
import { useUserData } from '../../providers/userDataProvider';
import Changelog from './changelog';
import ProcessDisplay from './processDisplay';
import UnsavedAlert from './unsavedAlert';

const validationSchema = Yup.object().shape({
  steps: Yup.array().of(
    Yup.object().shape({
      inspected: Yup.string().test(
        'steps-sequence',
        'All previous steps must be marked as Passed for this step can be marked as Passed. Consider marking this step as "Tuning."',
        (value, context) => {
          if (value === InspectionValues.PASSED) {
            const { options } = context;
            const stepIndex = options.index; // Get the index of the current step
            const { steps } = options.context;
            const previousSteps = steps.slice(0, stepIndex);
            return previousSteps.every(
              (step) => step.inspected === InspectionValues.PASSED
              || step.type === StepTypes.INSPECTION,
            );
          }
          return true;
        },
      ),
      minThickness: Yup.number()
        .nullable()
        .when('type', {
          is: StepTypes.MOLDING,
          then: (schema) => schema.positive('minThickness must be greater than 0'),
        }),
      maxThickness: Yup.number()
        .nullable()
        .when(['type', 'minThickness'], {
          is: (type, minThickness) => type === StepTypes.MOLDING && minThickness !== null,
          then: (schema) => schema
            .moreThan(Yup.ref('minThickness'), 'maxThickness must be greater than minThickness'),
        }),
      inspectionReport: Yup.array().of(
        Yup.object().shape({
          downloadURL: Yup.string().url('Must be a valid URL').required('Download URL is required'),
          lastModifiedTS: Yup.number().required('Last Modified Timestamp is required'),
          name: Yup.string().required('Name is required'),
          ref: Yup.string().required('Reference URL is required'),
          type: Yup.string().required('Type is required'),
        }),
      ),
    }),
  ),
});

function TravelerPage() {
  const { user } = useUserData();
  const { travelerId } = useParams();

  const [error, setError] = useState(null);
  const [traveler, setTraveler] = useState(null);
  const [travelerStatus, setTravelerStatus] = useState('loading');
  const [travelerError, setTravelerError] = useState(null);

  useEffect(() => {
    if (!travelerId) return undefined;
    const unsubscribe = onSnapshot(
      doc(getFirestore(), 'travelers', travelerId),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setTraveler({ id: docSnapshot.id, ...docSnapshot.data() });
          setTravelerStatus('success');
        } else {
          setTraveler(null);
          setTravelerStatus('error');
          setTravelerError('Traveler not found');
        }
      },
      (err) => {
        setTravelerStatus('error');
        setTravelerError(err.message);
      },
    );

    return () => unsubscribe();
  }, [travelerId]);

  const { data: order = {}, isFetching, isError } = useGetOrderById(traveler?.order);

  const orderSteps = useMemo(() => {
    if (isFetching || isError || !traveler?.lineItem) {
      return [];
    }
    const foundLineItem = order.shipments?.flatMap((shipment) => shipment.lineItems)
      .find((lineItem) => lineItem.id === traveler?.lineItem);
    return foundLineItem?.steps || [];
  }, [isError, isFetching, order.shipments, traveler?.lineItem]);

  const initialValues = useMemo(() => {
    if (!traveler || travelerStatus !== 'success') {
      return {};
    }
    return { ...traveler };
  }, [traveler, travelerStatus]);

  const getInspectionOptions = useCallback((previousSteps) => {
    const shouldDisablePass = previousSteps.some(
      (step) => step.inspected !== InspectionValues.PASSED && step.type !== StepTypes.INSPECTION,
    );
    return Object.values(InspectionValues).map((value) => ({
      value,
      label: value,
      disabled: shouldDisablePass && value === InspectionValues.PASSED,
    }));
  }, []);

  if (travelerStatus === 'loading') {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            Edit Traveler
          </Header>
        )}
      >
        <Container>
          {' '}
          <Spinner />
        </Container>
      </ContentLayout>
    );
  }

  if (travelerStatus === 'error') {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            Edit Traveler
          </Header>
        )}
      >
        <Container>
          <Flashbar
            items={[{
              header: 'Error loading traveler',
              content: travelerError,
              type: 'error',
            }]}
          />
        </Container>
      </ContentLayout>
    );
  }

  if (!traveler) {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            {`Edit Traveler ${travelerId}`}
          </Header>
        )}
      >
        <Container>
          <Flashbar
            items={[{
              header: 'Traveler not found',
              content: `No traveler found with ID ${travelerId}. If you have a traveler sticker with this id, it might mean the traveler hasn't been used yet. Try starting a forming run with this travler!`,
              type: 'warning',
            }]}
          />
        </Container>
      </ContentLayout>
    );
  }

  return (
    <SpaceBetween direction="vertical" size="l">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setError(null);
          const db = getFirestore();
          try {
            await runTransaction(db, async (transaction) => {
              // Update traveler document
              transaction.update(doc(db, 'travelers', travelerId), {
                project: values.project || '',
                tool: values.tool || '',
                steps: values.steps,
                shipped: values.shipped || false,
                _updatedBy: {
                  email: user.email,
                  timestamp: serverTimestamp(),
                  uid: user.id,
                },
              });
            });
          } catch (e) {
            setError(e.message);
            // reset form to initial values if there is an error
            resetForm();
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit, setFieldValue, values, isSubmitting, dirty, errors,
        }) => (
          <ContentLayout
            header={(
              <Header
                variant="h2"
                actions={(
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!dirty}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              >
                Edit Traveler
              </Header>
            )}
          >
            <UnsavedAlert dirty={dirty} />
            <SpaceBetween direction="vertical" size="l">
              {error ? (
                <Flashbar
                  items={[{
                    header: 'Error saving traveler',
                    content: error,
                    type: 'error',
                  }]}
                />
              ) : null}

              {/* Project */}
              <Container>
                <Header variant="h3">
                  {`Traveler ID: ${travelerId}`}
                </Header>
                <ColumnLayout columns={2}>
                  <FormField
                    label="Project"
                    errorText={errors.project}
                    stretch
                  >
                    <Input
                      value={values.project}
                      onChange={({ detail }) => setFieldValue('project', detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Tool"
                    errorText={errors.tool}
                    stretch
                  >
                    <Input
                      value={values.tool}
                      onChange={({ detail }) => setFieldValue('tool', detail.value)}
                    />
                  </FormField>
                </ColumnLayout>
              </Container>

              {values.steps?.map((step, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Container key={index}>
                  <Header variant="h3">
                    {step.name}
                  </Header>
                  <SpaceBetween size="xs">
                    {orderSteps[index]?.note ? (
                      <Alert statusIconAriaLabel="Warning" type="warning">
                        <div style={{ whiteSpace: 'pre-line' }}>{orderSteps[index]?.note}</div>
                      </Alert>
                    ) : step.type === StepTypes.CONNECTORIZING && (
                      <Alert statusIconAriaLabel="Error" type="error">
                        No connector information provided
                      </Alert>
                    )}
                    <div className="even-columns">
                      {(step.type === StepTypes.MOLDING || step.type === StepTypes.BONDING) && (
                        <FormField
                          stretch
                          label="Run ID"
                          errorText={errors.steps && errors.steps[index]?.runId}
                        >
                          <ProcessDisplay
                            processName={StepProcessMap[step.type]}
                            selectedOptions={step.runId}
                          />
                        </FormField>
                      )}
                      <FormField
                        stretch
                        label="Inspection"
                        errorText={errors.steps && errors.steps[index]?.inspected}
                      >
                        <Select
                          selectedOption={{ value: step.inspected, label: step.inspected }}
                          onChange={({ detail }) => {
                            const { value } = detail.selectedOption;
                            if (
                              value !== InspectionValues.REJECT_MAJOR
                              && value !== InspectionValues.REJECT_COSMETIC
                            ) {
                              setFieldValue(`steps[${index}].failureTags`, []);
                            }
                            setFieldValue(`steps[${index}].inspected`, value);
                          }}
                          options={getInspectionOptions(values.steps.slice(0, index))}
                        />
                      </FormField>
                      {(
                        step.inspected === InspectionValues.REJECT_MAJOR
                        || step.inspected === InspectionValues.REJECT_COSMETIC
                        || step.failureTags?.length > 0
                      ) && !!FailureTagsMap[step.type] && (
                      <FormField
                        stretch
                        label="Failure tags"
                      >
                        <Multiselect
                          selectedOptions={
                            step.failureTags?.map((tag) => ({ value: tag, label: tag })) || []
                          }
                          onChange={({ detail }) => setFieldValue(`steps[${index}].failureTags`, detail.selectedOptions?.map((option) => option.value) || [])}
                          options={FailureTagsMap[step.type].map((value) => ({
                            value,
                            label: value,
                          }))}
                        />
                      </FormField>
                      )}
                    </div>
                    {step.type === StepTypes.MOLDING && (
                      <div className="even-columns">
                        <FormField
                          label="Min Thickness (mm)"
                          errorText={errors.steps && errors.steps[index]?.minThickness}
                        >
                          <Input
                            type="number"
                            value={step.minThickness}
                            onChange={({ detail }) => setFieldValue(`steps[${index}].minThickness`, detail.value)}
                          />
                        </FormField>
                        <FormField
                          label="Max Thickness (mm)"
                          errorText={errors.steps && errors.steps[index]?.maxThickness}
                        >
                          <Input
                            type="number"
                            value={step.maxThickness}
                            onChange={({ detail }) => setFieldValue(`steps[${index}].maxThickness`, detail.value)}
                          />
                        </FormField>
                      </div>
                    )}
                    <FormField
                      label="Inspection Note"
                      errorText={errors.steps && errors.steps[index]?.notes}
                      stretch
                    >
                      <Textarea
                        value={step.inspectedNote}
                        onChange={({ detail }) => setFieldValue(`steps[${index}].inspectedNote`, detail.value)}
                      />
                    </FormField>
                    {step.type === StepTypes.INSPECTION && (
                      <FormField label="Inspection Report">
                        <FileUploader
                          uploadPath={`orders/${traveler?.order}/inspectionReport/${travelerId}/${index}`}
                          uploadedFiles={step.inspectionReport || []}
                          uploadErrors={errors.steps?.[index]?.inspectionReport || []}
                          onEmpty={() => setFieldValue(`steps[${index}].inspectionReport`, [])}
                          onUpload={(files) => setFieldValue(`steps[${index}].inspectionReport`, files)}
                          fileTypeName="Inspection Report"
                        />
                      </FormField>
                    )}
                  </SpaceBetween>
                </Container>
              ))}

              {/* Only show this for existing travelers with general inspection report */}
              {values.inspectionReport?.length > 0 && (
                <Container header={(<Header>Upload Inspection Report</Header>)}>
                  <FileUploader
                    uploadPath={`orders/${traveler?.order}/inspectionReport/${travelerId}`}
                    uploadedFiles={values.inspectionReport || []}
                    uploadErrors={errors.inspectionReport || []}
                    onEmpty={() => {}}
                    onUpload={() => {}}
                    fileTypeName="Inspection Report"
                  />
                </Container>
              )}

              {/* Shipped */}
              <Container>
                <Header variant="h3">
                  Shipped
                </Header>
                <FormField
                  errorText={errors.shipped}
                  stretch
                >
                  <Checkbox
                    onChange={({ detail }) => {
                      setFieldValue('shipped', detail.checked);
                    }}
                    checked={values.shipped || false}
                  >
                    Shipped
                  </Checkbox>
                </FormField>
              </Container>
              {/* Save Button */}
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!dirty}
                loading={isSubmitting}
              >
                Save
              </Button>
            </SpaceBetween>
          </ContentLayout>
        )}
      </Formik>
      <Changelog travelerId={travelerId} />
    </SpaceBetween>
  );
}

export default TravelerPage;
