import {
  FormField,
  Select,
} from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React from 'react';

export default function PistonSelector({ handleChange, selectedValue = null, errorText = null }) {
  const { data: pistonData = [], error, isFetching } = useQuery({
    queryKey: ['pistonNumbers'],
    queryFn: async () => {
      const functions = getFunctions(getApp());
      const getInternalPartNumbers = httpsCallable(functions, 'getPistonNumbersV2');
      const { data } = await getInternalPartNumbers();
      return data;
    },
  });

  let statusType = 'finished';
  if (error) {
    statusType = 'error';
  } else if (isFetching) {
    statusType = 'loading';
  }

  return (
    <FormField
      label="Piston"
      errorText={errorText}
    >
      <Select
        selectedOption={{ value: selectedValue }}
        onChange={({ detail }) => handleChange(detail.selectedOption.value)}
        errorText={`${error}`}
        filteringType="auto"
        options={pistonData.map((piston) => ({
          value: piston.name,
          label: piston.name,
          description: [
            piston.size && `Size: ${piston.size}`,
            piston.flashGap && `Flash Gap: ${piston.flashGap}`,
            piston.designVersion && `Design Version: ${piston.designVersion}`,
          ].filter(Boolean).join(', '),
        }))}
        loadingText="Loading pistons"
        placeholder="Choose a piston"
        statusType={statusType}
      />
    </FormField>
  );
}

PistonSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  errorText: PropTypes.string,
};
