/* eslint-disable react/no-unstable-nested-components */
import {
  Button,
  Header,
  StatusIndicator,
  Table,
} from '@cloudscape-design/components';
import { useQueryClient } from '@tanstack/react-query';
import { doc, getFirestore, writeBatch } from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useGetTravelerById } from '../../hooks/use-basicQueries';

function TravelerStatus({ travelerId, field }) {
  const { data: traveler = {}, isError, isFetching } = useGetTravelerById(travelerId);
  if (isFetching) {
    return <div>Loading...</div>;
  }
  if (isError || !traveler?.id) {
    return <div>Not found</div>;
  }
  const renderField = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return value || 'No';
  };

  if (field === 'lastInspection') {
    return (
      <div>
        {traveler.steps[traveler.steps.length - 1].inspected || 'Not inspected'}
      </div>
    );
  }

  return (
    <div>
      {renderField(traveler[field])}
    </div>
  );
}
TravelerStatus.propTypes = {
  travelerId: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
};

export default function BatchScanTable({ scannedTravelers }) {
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <Formik
      initialValues={{ selectedItems: [] }}
      onSubmit={async (values) => {
        if (!values.selectedItems?.length) return;
        setErrorMessage(null);
        const db = getFirestore();
        const batch = writeBatch(db);
        values.selectedItems.forEach((id) => {
          const docRef = doc(db, 'travelers', id);
          batch.update(docRef, { shipped: true });
        });

        try {
          await batch.commit();
          values.selectedItems.forEach((id) => {
            queryClient.invalidateQueries(['getTravelerData', id]);
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error batch updating travelers: ', error);
          setErrorMessage(`Error batch updating travelers: ${error.message}`);
        }
      }}
    >
      {({
        values, handleSubmit, setFieldValue, isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {errorMessage && <div><StatusIndicator type="error">{errorMessage}</StatusIndicator></div>}
          <Table
            variant="embedded"
            header={(
              <Header
                variant="h3"
                actions={(
                  <Button loading={isSubmitting} disabled={!values.selectedItems?.length} type="submit" variant="primary">
                    Mark selected as shipped
                  </Button>
                )}
              >
                Batch Scan Results
              </Header>
            )}
            onSelectionChange={({ detail }) => setFieldValue('selectedItems', detail.selectedItems)}
            selectedItems={values.selectedItems}
            selectionType="multi"
            columnDefinitions={[
              {
                header: 'Traveler ID',
                cell: (item) => (
                  <a href={`/travelers/${item}`} target="_blank" rel="noopener noreferrer">
                    {item}
                  </a>
                ),
              },
              {
                header: 'Shipped',
                cell: (item) => <TravelerStatus travelerId={item} field="shipped" />,
              },
              {
                header: 'Last Inspection',
                cell: (item) => <TravelerStatus travelerId={item} field="lastInspection" />,
              },
            ]}
            items={scannedTravelers}
          />
        </form>
      )}
    </Formik>
  );
}

BatchScanTable.propTypes = {
  scannedTravelers: PropTypes.arrayOf(PropTypes.string).isRequired,
};
