/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Button,
  FormField,
  Header,
  Input,
  Popover,
  Select,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import {
  collection,
  documentId,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import TravelerScanner from '.';

function TravelersDisplay({ travelers, setTravelers }) {
  const [travelerData, setTravelerData] = useState([]);
  const [status, setStatus] = useState('loading');

  const {
    values: {
      project = {}, tool = {}, toolMap = {}, process,
    },
    errors: { travelers: travelersErrorMap, toolMap: toolMapError },
    setFieldError,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (!travelers?.length) return undefined;
    const unsubscribe = onSnapshot(
      query(
        collection(getFirestore(), 'travelers'),
        where(documentId(), 'in', travelers),
      ),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTravelerData(data);
        setStatus('success');
      },
      () => {
        setStatus('error');
      },
    );

    return () => unsubscribe();
  }, [travelers]);

  // validate existing travelers matches project id
  useEffect(() => {
    if (!project?.value) return;
    const unmatchedTravelers = travelerData?.filter((traveler) => (
      // no need to check lineItemId, as we may have Tuning travelers from other orders
      traveler.project !== project.value
    ));
    if (unmatchedTravelers?.length > 0) {
      const errorMap = {};
      unmatchedTravelers.forEach((traveler) => {
        errorMap[traveler.id] = `This traveler belongs to project ${traveler.project}, which does not match the selected project ${project.value}.`;
      });
      setFieldError('travelers', errorMap);
    } else {
      setFieldError('travelers', null);
    }
  }, [project, setFieldError, travelerData]);

  const travelerItems = useMemo(() => {
    if (status === 'loading') {
      return travelers.map((traveler) => ({
        label: traveler,
        description: 'Loading info...',
      }));
    }
    const travelerMap = new Map(travelerData.map((traveler) => [traveler.id, traveler]));
    return travelers.map((traveler) => {
      if (!travelerMap.has(traveler)) {
        return {
          label: traveler,
          description: 'New traveler!',
        };
      }
      const { steps } = travelerMap.get(traveler);
      let description = 'Existing traveler';
      // loop through steps and add to description
      steps.forEach((step) => {
        if (step.runId?.length > 0) {
          description += ` | ${step.name} run${step.runId.length > 1 ? 's' : ''}: ${step.runId.map((run) => `${run.project} - ${run.runID}`).join(', ')}`;
        }
      });
      return {
        label: traveler,
        description,
      };
    });
  }, [status, travelerData, travelers]);

  return (
    <Table
      columnDefinitions={[
        {
          id: 'label',
          header: (
            <Header
              description={<Box color="text-status-error" fontSize="body-s">{toolMapError}</Box>}
            >
              Traveler(s)
            </Header>
          ),
          cell: (item) => (
            <>
              <div>{item.label}</div>
              <Box color="text-status-inactive" fontSize="body-s">{item.description}</Box>
              {travelersErrorMap?.[item.label] && (
                <Box color="text-status-error" fontSize="body-s">{travelersErrorMap[item.label]}</Box>
              )}
            </>
          ),
          isRowHeader: true,
        },
        ...(process === 'forming' ? [
          {
            id: 'cavity',
            header: 'Cavity',
            cell: (item) => (
              <Select
                selectedOption={
                  toolMap[item.label]
                    ? { label: toolMap[item.label], value: toolMap[item.label] }
                    : null
                }
                onChange={({ detail }) => {
                  setFieldValue(`toolMap.${item.label}`, detail.selectedOption.value);
                }}
                placeholder="Choose a cavity"
                expandToViewport
                options={tool.cavities?.map((cavity) => (
                  { label: cavity.name, value: cavity.name }
                ))}
                invalid={!!toolMapError && !toolMap?.[item.label]}
              />
            ),
          },
        ] : []),
        {
          id: 'remove',
          header: '',
          cell: (item) => (
            <Button
              variant="icon"
              iconName="close"
              onClick={() => {
                if (travelers.length === 1) setFieldError('travelers', null);
                setTravelers(travelers.filter((t) => t !== item.label));
              }}
            />
          ),
        },
      ]}
      items={travelerItems}
    />
  );
}

TravelersDisplay.propTypes = {
  travelers: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTravelers: PropTypes.func.isRequired,
};

function TravelerInput({
  id, travelers = [], errorText = '',
}) {
  const { values: { tool, toolMap }, setFieldValue } = useFormikContext();
  const [travelerInput, setTravelerInput] = useState('');

  const handleSetTravelers = useCallback((newTravelers) => {
    setFieldValue('travelers', newTravelers);
    setFieldValue('numberOfChips', newTravelers.length);
    // make a shallow copy of toolMap,
    // we will need this to auto assign cavities and remove unused ones
    const toolMapCopy = { ...toolMap };
    if (tool.cavities?.length > 0) {
      newTravelers.forEach((traveler, index) => {
        if (!toolMapCopy[traveler] && index < tool.cavities.length) {
          toolMapCopy[traveler] = tool.cavities[index].name;
        }
      });
    }
    // remove toolMap entries for travelers that are no longer in the list
    setFieldValue(
      'toolMap',
      Object.fromEntries(
        Object.entries(toolMapCopy).filter(([traveler]) => newTravelers.includes(traveler)),
      ),
    );
  }, [setFieldValue, tool?.cavities, toolMap]);

  const handleQRCodeSuccess = useCallback((qrCode) => {
    // update traveler list
    const travelerId = qrCode.replace('https://admin.parallelfluidics.com/travelers/', '');
    if (!travelers.includes(travelerId)) {
      handleSetTravelers([...travelers, travelerId]);
    }

    // very hacky way to close the popover until this is resolved
    // https://github.com/cloudscape-design/components/issues/1995
    const popoverField = document.querySelector(`[data-popover-id='${id}']`);
    if (!popoverField) {
      return;
    }
    const popoverCloseButton = popoverField.querySelector('[aria-label="Close popover"]');
    if (!popoverCloseButton) {
      return;
    }
    popoverCloseButton.click();
  }, [id, handleSetTravelers, travelers]);

  return (
    <SpaceBetween direction="vertical" size="s">
      <FormField
        data-popover-id={id}
        label="Traveler(s)"
        secondaryControl={(
          <Popover
            position="bottom"
            size="large"
            triggerType="custom"
            header="Scan traveler"
            fixedWidth
            content={(
              <TravelerScanner
                id={id}
                onQRCodeSuccess={handleQRCodeSuccess}
              />
            )}
          >
            <Button iconName="search" />
          </Popover>
        )}
        errorText={errorText}
      >
        <Input
          onChange={({ detail }) => setTravelerInput(detail.value)}
          value={travelerInput}
          onKeyDown={(e) => {
            if (e?.detail?.key === 'Enter') {
              if (travelerInput.length > 0 && !travelers.includes(travelerInput)) {
                handleSetTravelers([...travelers, travelerInput]);
              }
              setTravelerInput('');
            }
          }}
        />
      </FormField>
      {travelers.length > 0
        ? <TravelersDisplay travelers={travelers} setTravelers={handleSetTravelers} />
        : null}
    </SpaceBetween>
  );
}

TravelerInput.propTypes = {
  id: PropTypes.string.isRequired,
  travelers: PropTypes.arrayOf(PropTypes.string),
  errorText: PropTypes.string,
};

export default TravelerInput;
