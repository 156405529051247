import './index.css';

import Flashbar from '@cloudscape-design/components/flashbar';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Table from '@cloudscape-design/components/table';
import {
  and,
  collection,
  getFirestore,
  or,
  orderBy,
  QueryFieldFilterConstraint,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import useCustomInfiniteQuery from '../../hooks/use-customInfiniteQuery';

function Filter({
  tableId,
  setConstraints,
  filteringOptions,
  filteringProperties,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const propertyFilters = useMemo(() => {
    const propertyKeys = searchParams.getAll(`${tableId}-key`);
    const operators = searchParams.getAll(`${tableId}-operator`);
    const values = searchParams.getAll(`${tableId}-value`);
    const operation = searchParams.get(`${tableId}-operation`) || 'and';
    const tokens = [];
    for (let i = 0; i < propertyKeys.length; i += 1) {
      tokens.push({
        propertyKey: propertyKeys[i],
        operator: operators[i],
        value: values[i],
      });
    }
    return {
      tokens,
      operation,
    };
  }, [searchParams, tableId]);

  const updateSearchParams = useCallback(({ detail }) => {
    const newSearchParams = {};
    searchParams.forEach((value, key) => {
      if (!key.startsWith(`${tableId}-`)) {
        newSearchParams[key] = value;
      }
    });

    const filteredTokens = detail.tokens.filter(
      (token) => (token.value && token.operator && token.propertyKey),
    );
    if (filteredTokens.length > 0) {
      newSearchParams[`${tableId}-key`] = filteredTokens.map((token) => token.propertyKey);
      newSearchParams[`${tableId}-operator`] = filteredTokens.map((token) => token.operator);
      newSearchParams[`${tableId}-value`] = filteredTokens.map((token) => token.value);
      newSearchParams[`${tableId}-operation`] = detail.operation;
    }
    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams, tableId]);

  useEffect(() => {
    if (propertyFilters.tokens.length === 0) {
      setConstraints([]);
      return;
    }
    const operator = {
      and,
      or,
    }[propertyFilters.operation];
    const filters = [];
    for (let i = 0; i < propertyFilters.tokens.length; i += 1) {
      const token = propertyFilters.tokens[i];
      filters.push(where(
        token.propertyKey,
        { '=': '==', '!=': '!=' }[token.operator],
        token.value,
      ));
    }
    setConstraints([operator(...filters)]);
  }, [propertyFilters, setConstraints]);

  Filter.propTypes = {
    tableId: PropTypes.string.isRequired,
    setConstraints: PropTypes.func.isRequired,
    filteringOptions: PropTypes.arrayOf(PropTypes.shape({
      propertyKey: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    filteringProperties: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      operators: PropTypes.arrayOf(PropTypes.string).isRequired,
      propertyLabel: PropTypes.string.isRequired,
      groupValuesLabel: PropTypes.string.isRequired,
    })).isRequired,
  };

  return (
    <PropertyFilter
      onChange={updateSearchParams}
      query={propertyFilters}
      expandToViewport
      filteringOptions={filteringOptions}
      filteringProperties={filteringProperties}
    />
  );
}

function DataTable({
  id,
  header,
  type,
  filters = [],
  orderField,
  pageSize,
  columnDefinitions,
  filteringOptions,
  filteringProperties,
  stickyColumns = null,
  resizableColumns = false,
  emptyMessage = 'No data',
}) {
  const [constraints, setConstraints] = useState([]);

  const queryParams = useMemo(
    () => [
      collection(getFirestore(), type),
      and(...filters, ...constraints),
      orderBy(orderField, 'desc'),
    ],
    [type, filters, constraints, orderField],
  );

  const {
    CustomPagination,
    pageData,
    error,
    isFetchingNextPage,
  } = useCustomInfiniteQuery({ queryKey: `getDataTableDocs${id}`, queryParams, pageSize });

  return (
    <Table
      variant="full-page"
      columnDefinitions={columnDefinitions}
      items={pageData}
      loadingText={`Loading ${type}`}
      loading={isFetchingNextPage}
      filter={(
        <Filter
          tableId={id}
          setConstraints={setConstraints}
          filteringOptions={filteringOptions}
          filteringProperties={filteringProperties}
        />
        )}
      empty={
          error ? (
            <Flashbar items={[{
              header: error?.message || 'Unknown error',
              type: 'error',
            }]}
            />
          ) : emptyMessage
        }
      header={header}
      pagination={<CustomPagination />}
      stickyColumns={stickyColumns}
      resizableColumns={resizableColumns}
    />
  );
}

DataTable.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.instanceOf(QueryFieldFilterConstraint)),
  pageSize: PropTypes.number.isRequired,
  orderField: PropTypes.string.isRequired,
  columnDefinitions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    cell: PropTypes.func.isRequired,
  })).isRequired,
  filteringOptions: PropTypes.arrayOf(PropTypes.shape({
    propertyKey: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  filteringProperties: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    operators: PropTypes.arrayOf(PropTypes.string).isRequired,
    propertyLabel: PropTypes.string.isRequired,
    groupValuesLabel: PropTypes.string.isRequired,
  })).isRequired,
  stickyColumns: PropTypes.shape({
    first: PropTypes.number,
    last: PropTypes.number,
  }),
  resizableColumns: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

export default DataTable;
