import {
  Alert,
  Button,
  Container,
  Header,
} from '@cloudscape-design/components';
import { getAuth, signOut } from 'firebase/auth';
import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

function ErrorPage() {
  const errorData = useRouteError();
  const navigate = useNavigate();
  console.log('ErrorPage', errorData, typeof errorData, JSON.stringify(errorData));

  // Extract error details safely
  const status = errorData?.status || 'Unknown';
  const statusText = errorData?.statusText || 'Something went wrong';
  const message = errorData?.error?.message || errorData?.data || errorData?.message || 'An unexpected error occurred.';

  return (
    <Container
      header={(
        <Header variant="h1">
          {`Error ${status}: ${statusText}`}
        </Header>
      )}
    >
      <Alert type="error">
        {message}
      </Alert>

      <div
        style={{
          display: 'flex',
          gap: '1rem',
          marginTop: '1rem',
          justifyContent: 'center',
        }}
      >
        {/* Refresh Button */}
        <Button variant="primary" onClick={() => navigate('/')}>
          🏠 Go to Home
        </Button>

        {/* Logout Button */}
        <Button variant="normal" onClick={() => signOut(getAuth()).then(() => navigate('/'))}>
          🚪 Logout
        </Button>
      </div>
    </Container>
  );
}

export default ErrorPage;
