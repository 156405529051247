import {
  StatusIndicator,
} from '@cloudscape-design/components';
import {
  collection,
  documentId,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import { useOrderDetail } from './orderDetailProvider';

export default function OrderCalendar({ orders, isLoading }) {
  const { setOrderId } = useOrderDetail();

  const [shipmentEvents, setShipmentEvents] = useState([]);

  useEffect(() => {
    if (isLoading || !orders?.length) return;
    async function getEvents() {
      const events = [];
      const orgIdsSet = new Set();
      for (let i = 0; i < orders.length; i += 1) {
        const order = orders[i];
        for (let j = 0; j < (order.shipments || []).length; j += 1) {
          const shipment = order.shipments[j];
          orgIdsSet.add(order.organization);
          events.push({
            id: `${order.id}-${j}`,
            orderId: order.id,
            start: new Date(`${shipment.targetShipDate} 00:00:00`),
            end: new Date(`${shipment.targetShipDate} 00:00:00`),
            shipmentName: shipment.name,
            organization: order.organization,
            allDay: true,
          });
        }
      }

      // add filter(Boolean) in case of missing orgId
      const orgIdsArr = Array.from(orgIdsSet).filter(Boolean);
      const orgsQuery = query(collection(getFirestore(), 'organizations'), where(documentId(), 'in', orgIdsArr));
      const orgsSnapshot = await getDocs(orgsQuery);
      const idOrgMap = {};
      orgsSnapshot.forEach((doc) => {
        const org = doc.data();
        idOrgMap[doc.id] = org.name;
      });
      setShipmentEvents(events.map(({ organization, shipmentName, ...event }) => ({
        ...event,
        title: `${idOrgMap[organization]} - ${shipmentName}`,
      })));
    }

    getEvents();
  }, [orders, isLoading]);

  const localizer = momentLocalizer(moment);

  if (isLoading) {
    return <StatusIndicator type="loading">Loading...</StatusIndicator>;
  }

  return (
    <Calendar
      events={shipmentEvents}
      localizer={localizer}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      views={{ month: true }}
      onSelectEvent={(event) => { setOrderId(event.orderId); }}
    />
  );
}
OrderCalendar.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    organization: PropTypes.string,
    shipments: PropTypes.arrayOf(PropTypes.shape({
      targetShipDate: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
  })).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
