import {
  doc,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import { useEffect } from 'react';

import { useNotifications } from '../../features/notifications';

const currentVersion = process.env.REACT_APP_VERSION;

export default function VersionCheck() {
  const { addNotification, removeNotification } = useNotifications();

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(getFirestore(), 'memstore', 'admin_site_version'), (snapshot) => {
      const data = snapshot.data();
      if (data?.version && currentVersion && currentVersion !== data.version) {
        addNotification({
          type: 'info',
          dismissible: true,
          header: 'New version detected',
          content: 'A new version is available. Please refresh to get the latest version.',
          id: 'VersionCheck',
        });
      } else {
        removeNotification('VersionCheck');
      }
    });

    return () => unsubscribe();
  }, [addNotification, removeNotification]);
}
