import {
  Box,
  Button,
  Cards,
  FileUpload,
  Modal,
  ProgressBar,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import {
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';

import StorageImage from './storageImage';

const modalReducer = (_, action) => {
  switch (action.type) {
    case 'open': {
      const { initialValue, fieldName } = action.data;
      return {
        visible: true,
        initialValue,
        fieldName,
      };
    }
    case 'close': {
      return {
        visible: false,
        initialValue: null,
        fieldName: null,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const useImageModalReducer = () => useReducer(modalReducer, {
  visible: false,
  path: null,
  initialValue: null,
  fieldName: null,
});

function UploadProgress({ uploadTask }) {
  const [percentComplete, setPercentComplete] = useState(0);

  useEffect(() => {
    const unsubscribe = uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPercentComplete(Math.round(progress));
      },
    );

    return () => unsubscribe();
  }, [uploadTask]);

  return (
    <ProgressBar
      value={percentComplete}
      label={`Uploading image: ${percentComplete}%`}
    />
  );
}
UploadProgress.propTypes = {
  uploadTask: PropTypes.shape({
    on: PropTypes.func.isRequired,
  }).isRequired,
};

function ImageModal({
  visible = false,
  onDismiss: handleDismiss,
  path,
  initialValue = null,
  fieldName = null,
  setFieldValue,
}) {
  // State to store all images in the storage path
  const { data: imagePaths, state: imageLoadingState, refetch: refetchImages } = useQuery({
    queryKey: ['imagePaths', path],
    queryFn: async () => {
      const storage = getStorage();
      const { items } = await listAll(ref(storage, path));
      return items.map((itemRef) => itemRef.fullPath);
    },
    enabled: visible,
    initialData: [],
  });

  // State to store the selected image and refresh on new initial values
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    if (initialValue) {
      setSelectedItems([initialValue]);
    } else {
      setSelectedItems([]);
    }
  }, [initialValue]);

  // Handle uploads
  const [currentUpload, setCurrentUpload] = useState();
  const onFileChange = useCallback(({ detail }) => {
    const fileList = detail.value;
    const fileToUpload = fileList[0];
    const fileName = fileToUpload.name;
    const storage = getStorage();
    const newRef = ref(storage, `${path}/${fileName}`);
    const newUploadTask = uploadBytesResumable(newRef, fileToUpload);
    newUploadTask.then(() => {
      refetchImages();
      setCurrentUpload(null);
    });
    setCurrentUpload({ uploadTask: newUploadTask, storageRef: newRef });
  }, [path]);

  return (
    <Modal
      size="large"
      visible={visible}
      onDismiss={handleDismiss}
      footer={(
        <Box float="right">
          <SpaceBetween size="xs" direction="horizontal">
            <Button onClick={handleDismiss}>
              Cancel
            </Button>
            <Button onClick={() => {
              setFieldValue(fieldName, null);
              handleDismiss();
            }}
            >
              Clear image
            </Button>
            <Button
              onClick={() => {
                if (selectedItems.length > 0) {
                  setFieldValue(fieldName, selectedItems[0]);
                } else {
                  setFieldValue(fieldName, null);
                }
                handleDismiss();
              }}
              variant="primary"
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      )}
    >
      <Cards
        selectionType="single"
        onSelectionChange={({ detail }) => setSelectedItems(detail?.selectedItems ?? [])}
        selectedItems={selectedItems}
        entireCardClickable
        cardDefinition={{
          header: (item) => (item.split('/')[item.split('/').length - 1]),
          sections: [
            {
              id: 'description',
              // eslint-disable-next-line react/no-unstable-nested-components
              content: (item) => <StorageImage storagePath={item} alt="Feedback image" style={{ width: '100%' }} />,
            },
          ],
        }}
        cardsPerRow={[{
          cards: 2,
        }, {
          minWidth: 768,
          cards: 3,
        }, {
          minWidth: 992,
          cards: 4,
        }, {
          minWidth: 1200,
          cards: 5,
        }, {
          minWidth: 1400,
          cards: 6,
        }, {
          minWidth: 1920,
          cards: 6,
        }]}
        items={imagePaths}
        loading={imageLoadingState === 'pending'}
        loadingText="Loading images..."
        header={(
          <Box
            textAlign="center"
            color="inherit"
          >
            { currentUpload ? (
              <UploadProgress
                uploadTask={currentUpload.uploadTask}
              />
            ) : (
              <FileUpload
                onChange={onFileChange}
                value={[]}
                i18nStrings={{
                  uploadButtonText: () => 'Upload image',
                  dropzoneText: () => 'Drop images here or click to upload',
                  removeFileAriaLabel: () => 'Remove file',
                  limitShowFewer: 'Show fewer files',
                  limitShowMore: 'Show more files',
                  errorIconAriaLabel: 'Error',
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={1}
                constraintText="Images will only be available for this quote. "
              />
            ) }
          </Box>
        )}
      />
    </Modal>
  );
}

ImageModal.propTypes = {
  visible: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  fieldName: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default ImageModal;
export { useImageModalReducer };
