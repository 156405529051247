const StepTypes = Object.freeze({
  MOLDING: 'Molding',
  POSTMACHINING: 'Postmachining',
  BONDING: 'Bonding',
  CONNECTORIZING: 'Connectorizing',
  INSPECTION: 'Inspection',
});

const InspectionValues = Object.freeze({
  NOT_INSPECTED: 'Not inspected',
  PASSED: 'Passed',
  REJECT_MAJOR: 'Reject: Major',
  REJECT_COSMETIC: 'Reject: Cosmetic',
  TUNING: 'Tuning',
});

const FailureTagsMap = Object.freeze({
  [StepTypes.MOLDING]: [
    'Void off channel',
    'Void on channel edge',
    'Void on channel surface',
    'Contamination off channel',
    'Contamination on channel',
    'Contamination below channel',
    'Scratch',
    'Thickness',
    'Sticking - Piston side',
    'Sticking - Tool side',
    'Alignment',
    'Cracked',
    'Other',
  ],
  [StepTypes.POSTMACHINING]: [
    'Alignment',
    'Feature depth',
    'Burrs',
    'Part kicked',
    'Other',
  ],
  [StepTypes.BONDING]: [
    'Unbonded patch away from channel',
    'Unbonded patch near channel',
    'Unbonded patch against channel edge',
    'Cap alignment',
    'Dimples',
    'Collapsed channels',
    'Contamination off channel',
    'Contamination on channel',
    'Other',
  ],
  [StepTypes.CONNECTORIZING]: [
    'Not fully welded',
    'Contaminant in weld joint',
    'Other',
  ],
});

const ProcessNames = Object.freeze({
  FORMING: 'forming',
  BONDING: 'bonding',
});

const ProcessStepMap = Object.freeze({
  [ProcessNames.FORMING]: StepTypes.MOLDING,
  [ProcessNames.BONDING]: StepTypes.BONDING,
});

const StepProcessMap = Object.freeze({
  [StepTypes.MOLDING]: ProcessNames.FORMING,
  [StepTypes.BONDING]: ProcessNames.BONDING,
});

const Materials = Object.freeze([
  'COP',
  'PMMA',
  'PC',
]);

module.exports = {
  StepTypes,
  InspectionValues,
  FailureTagsMap,
  ProcessNames,
  ProcessStepMap,
  StepProcessMap,
  Materials,
};
