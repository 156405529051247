import {
  Button,
  Header,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  orderBy,
} from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  OrderList,
} from '../../components/Orders';
import OrderDetailProvider from '../../components/Orders/orderDetailProvider';
import useCustomInfiniteQuery from '../../hooks/use-customInfiniteQuery';

function OrderListPage() {
  const navigate = useNavigate();
  const ordersQueryParams = useMemo(() => [
    collection(getFirestore(), 'orders'),
    orderBy('orderDate', 'desc'),
  ], []);

  const {
    CustomPagination,
    pageData,
    isFetchingNextPage,
  } = useCustomInfiniteQuery({
    queryKey: 'getOrdersData',
    queryParams: ordersQueryParams,
    pageSize: 25,
  });

  return (
    <OrderDetailProvider>
      <OrderList
        header={(
          <Header
            variant="awsui-h1-sticky"
            actions={
              <Button onClick={() => { navigate('/orders/create'); }}>Create Order</Button>
            }
          >
            Orders
          </Header>
        )}
        variant="full-page"
        orders={pageData}
        isLoading={isFetchingNextPage}
        Pagination={<CustomPagination />}
      />
    </OrderDetailProvider>
  );
}

export default OrderListPage;
