import {
  Box,
  Button,
  Container,
  SpaceBetween,
} from '@cloudscape-design/components';
import { formatDate } from '@parallel-fluidics/shipping';
import { pdf } from '@react-pdf/renderer';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import OrderStatus from '../../../constants/orderStatus';
import PDFPreviewPage from './pdfPreview/pdfPreviewPage';

const PackageContext = createContext();

export default function PackageProvider({ children }) {
  const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const defaultShipIdx = searchParams.get('shipIdx');
  const navigate = useNavigate();

  // fetch order based on orderId
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (!orderId) return undefined;
    const unsubscribe = onSnapshot(
      doc(getFirestore(), 'orders', orderId),
      (orderDoc) => {
        setOrder({ id: orderDoc.id, ...orderDoc.data() });
        setStatus('success');
      },
      () => {
        setStatus('error');
      },
    );

    return () => unsubscribe();
  }, [orderId]);

  const [selectedShipment, setSelectedShipment] = useState(null);
  const [toAddress, setToAddress] = useState({});
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [currentPkgNum, setCurrentPkgNum] = useState(1);
  const [totalPkgNum, setTotalPkgNum] = useState(0);
  const [shipDate, setShipDate] = useState(formatDate(new Date()));
  const [shipMethod, setShipMethod] = useState('');
  const [stripeQuoteNumber, setStripeQuoteNumber] = useState(' ');
  const [quoteMap, setQuoteMap] = useState({});
  const [shipmentId, setShipmentId] = useState('');
  const [rates, setRates] = useState([]);
  const [packageData, setPackageData] = useState({});

  const renderItems = useMemo(
    () => selectedLineItems.filter((item) => item.isChecked),
    [selectedLineItems],
  );

  useEffect(() => {
    if (!order) return;
    let firstNoneShipped = null;
    const curPkgNum = order.shipments.reduce((acc, s, idx) => {
      if (defaultShipIdx && idx === parseInt(defaultShipIdx, 10)) {
        firstNoneShipped = { ...s, index: idx };
      } else if (!s.isShipped && !firstNoneShipped) {
        firstNoneShipped = { ...s, index: idx };
      }
      const num = acc + (s.trackingData?.length || 0);
      return num;
    }, 1);
    setSelectedShipment(firstNoneShipped || { ...order.shipments[0], index: 0 });
    setCurrentPkgNum(curPkgNum);
    setTotalPkgNum(Math.max(curPkgNum, order.shipments.length));
    setShipMethod(order.shipments[0].shippingMethod?.replace(/ \(\$.*$/, ''));

    // if it's invoice, we need to get the quote number from stripe
    if (order.stripeId.startsWith('in_')) {
      const getStripeQuoteId = async () => {
        const getQuoteId = httpsCallable(getFunctions(), 'getQuoteByInvoiceIdV2');
        try {
          const { data: quoteId } = await getQuoteId({ invoiceId: order.stripeId });
          setStripeQuoteNumber(quoteId);
        } catch (error) {
          setStripeQuoteNumber('');
          // eslint-disable-next-line no-console
          console.error('Error fetching quoteId', error);
        }
      };
      getStripeQuoteId();
    } else {
      // else we need to create a projectId to quoteId mapping
      const createItemQuoteMap = async () => {
        const projectIdsSet = new Set();
        order.shipments.forEach((s) => {
          s.lineItems.forEach((item) => {
            projectIdsSet.add(item.projectId);
          });
        });

        // Query Firestore for project
        const projectIdsArr = Array.from(projectIdsSet);
        const projectsQuery = query(collection(getFirestore(), 'projects'), where('name', 'in', projectIdsArr));
        const querySnapshot = await getDocs(projectsQuery);

        // Create a map of project IDs to partIds(Quotes)
        const projectIdQuoteMap = {};
        querySnapshot.forEach((snapshot) => {
          const project = snapshot.data();
          const partId = project.adminLink.match(/part\/(.+?)(\/|$)/)[1];
          projectIdQuoteMap[project.name] = partId.substring(0, 6).toUpperCase();
        });

        setQuoteMap(projectIdQuoteMap);
      };

      createItemQuoteMap();
    }
  }, [order, defaultShipIdx]);

  useEffect(() => {
    if (!selectedShipment) return;
    setToAddress(selectedShipment.addressData);
    const items = selectedShipment.lineItems.map((item) => ({
      id: item.id,
      isChecked: true,
      name: item.name || item.projectId,
      projectId: item.projectId,
      description: item.description,
      quantity: item.quantity,
      quantityShipped: item.quantityShipped || item.quantity,
    }));
    setSelectedLineItems(items);
  }, [selectedShipment]);

  useEffect(() => {
    if (Object.keys(quoteMap).length === 0) return;
    const associatedQuote = renderItems.map((item) => quoteMap[item.projectId]).join(', ');
    setStripeQuoteNumber(associatedQuote);
  }, [renderItems, quoteMap]);

  const generatePDFBlob = useCallback(async () => {
    const document = pdf(
      <PDFPreviewPage
        order={order}
        toAddress={toAddress}
        renderItems={renderItems}
        currentPkgNum={currentPkgNum}
        totalPkgNum={totalPkgNum}
        shipDate={shipDate}
        shipMethod={shipMethod}
        stripeQuoteNumber={stripeQuoteNumber}
      />,
    );
    const blob = await document.toBlob();
    return blob;
  }, [
    order,
    toAddress,
    renderItems,
    currentPkgNum,
    totalPkgNum,
    shipDate,
    shipMethod,
    stripeQuoteNumber,
  ]);

  const uploadPDFToStorage = useCallback(async (blob, trackingCode) => {
    const storageRef = ref(getStorage(), `orders/${orderId}/packingSlip/${currentPkgNum}_${totalPkgNum}_${trackingCode}.pdf`);
    await uploadBytes(storageRef, blob, { contentType: 'application/pdf' });
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  }, [currentPkgNum, totalPkgNum, orderId]);

  const handleUpload = useCallback(async (trackingCode) => {
    try {
      const pdfBlob = await generatePDFBlob();
      const downloadURL = await uploadPDFToStorage(pdfBlob, trackingCode);
      return downloadURL;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading PDF:', error);
      return null;
    }
  }, [generatePDFBlob, uploadPDFToStorage]);

  const handleUpdateOrder = useCallback(async (trackingData) => {
    let isOrderComplete = true;
    const updatedShipments = order.shipments.map((shipment, shipmentIdx) => {
      let isAllItemShipped = true;
      if (shipmentIdx !== selectedShipment.index) {
        if (!shipment.isShipped) isOrderComplete = false;
        return shipment;
      }
      const updatedLineItems = shipment.lineItems.map((lineItem) => {
        const foundShippedItem = renderItems.find((item) => item.id === lineItem.id);
        if (!foundShippedItem) {
          if (lineItem.quantity > (lineItem.quantityShipped || 0)) isAllItemShipped = false;
          return lineItem;
        }
        const totalShipped = (lineItem.quantityShipped || 0) + foundShippedItem.quantityShipped;
        // update lineItem's quantityShipped
        if (lineItem.quantity > totalShipped) isAllItemShipped = false;
        const updatedLineItem = {
          ...lineItem,
          quantityShipped: totalShipped,
        };

        return updatedLineItem;
      });

      if (!isAllItemShipped) isOrderComplete = false;
      return {
        ...shipment,
        lineItems: updatedLineItems,
        // mark shipment as shipped if all lineItems are shipped,
        // we leverage isShipped to hide/show the tracker info
        isShipped: isAllItemShipped,
        addressData: toAddress,
        trackingData: [...(shipment.trackingData || []), trackingData],
      };
    });

    const updateOrder = {
      shipments: updatedShipments,
      hasShippedItems: updatedShipments.some((shipment) => shipment.isShipped),
    };
    if (isOrderComplete) {
      updateOrder.status = OrderStatus.COMPLETED;
    }

    await updateDoc(doc(getFirestore(), 'orders', order.id), updateOrder);
    setPackageData(trackingData);
  }, [order?.id, order?.shipments, renderItems, selectedShipment?.index, toAddress]);

  const value = useMemo(() => ({
    order,
    toAddress,
    setToAddress,
    selectedLineItems,
    setSelectedLineItems,
    renderItems,
    selectedShipment,
    setSelectedShipment,
    totalPkgNum,
    setTotalPkgNum,
    currentPkgNum,
    setCurrentPkgNum,
    shipDate,
    setShipDate,
    shipMethod,
    setShipMethod,
    stripeQuoteNumber,
    setStripeQuoteNumber,
    shipmentId,
    setShipmentId,
    rates,
    setRates,
    packageData,
    handleUpload,
    handleUpdateOrder,
  }), [
    order,
    selectedLineItems,
    renderItems,
    selectedShipment,
    toAddress,
    totalPkgNum,
    currentPkgNum,
    shipDate,
    shipMethod,
    stripeQuoteNumber,
    shipmentId,
    rates,
    packageData,
    handleUpload,
    handleUpdateOrder,
  ]);

  if (order?.status !== OrderStatus.IN_PROGRESS && Object.keys(packageData).length === 0) {
    return (
      <Container>
        <Box
          margin={{ vertical: 'xs' }}
          textAlign="center"
          color="inherit"
        >
          <SpaceBetween size="s">
            <div>This order is not in progress. Please check the order status.</div>
            <Button onClick={() => navigate(`/orders/update/${orderId}`)}>Back to order edit</Button>
          </SpaceBetween>
        </Box>
      </Container>
    );
  }

  return (
    <PackageContext.Provider value={value}>
      {!orderId || status === 'loading' || !order ? (
        <Container>{status === 'loading' ? 'Loading' : 'Invalid order ID or something went wrong'}</Container>
      ) : (
        children
      )}
    </PackageContext.Provider>
  );
}
PackageProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const usePackage = () => {
  const context = useContext(PackageContext);
  if (context === undefined) {
    throw new Error('usePackage must be used within a PackageProvider');
  }
  return context;
};
