import {
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import { StepTypes } from '@parallel-fluidics/constants';
import { formatDate } from '@parallel-fluidics/shipping';
import {
  collection,
  doc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import {
  FieldArray,
  Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import OrderStatus from '../../../constants/orderStatus';
import { useNotifications } from '../../../features/notifications';
import { formatCurrency } from '../../../utils';
import FileUploader from '../../FileUploader';
import OrganizationSelect from '../../Organization/organizationSelect';
import AddressDisplay from '../package/addressDisplay';
import LineItemsTable from './lineItemsTable';
import UIDInput from './uidInput';

const orderValidationSchema = Yup.object().shape({
  uid: Yup.string().required('UID is required'), // order creator user ID
  organization: Yup.string().required('Organization is required'),
  name: Yup.string(),
  orderDate: Yup.string()
    .required('Order Date is required')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Order Date must be in the format YYYY-MM-DD'),
  purchaseOrder: Yup.array().of(
    Yup.object().shape({
      downloadURL: Yup.string().url('Must be a valid URL').required('Download URL is required'),
      lastModifiedTS: Yup.number().required('Last Modified Timestamp is required'),
      name: Yup.string().required('Name is required'),
      ref: Yup.string().required('Reference URL is required'),
      type: Yup.string().required('Type is required'),
    }),
  ),
  shipments: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Shipment Name is required'),
      addressData: Yup.object().shape({
        name: Yup.string().required('Name is required'),
        phone: Yup.string().required('Phone is required'),
        street1: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zip: Yup.string().required('Zip Code is required'),
        country: Yup.string().required('Country is required'),
      }).required('Address is required'),
      targetShipDate: Yup.string()
        .required('Target Ship Date is required')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Target Ship Date must be in the format YYYY-MM-DD'),
      shippingMethod: Yup.string(),
      isShipped: Yup.boolean().required('Shipped is required'),
      lineItems: Yup.array().of(
        Yup.object().shape({
          projectId: Yup.string().required('Project ID is required'),
          quantity: Yup.number().positive('Quantity must be a positive number').required('Quantity is required'),
          bonding: Yup.string().required('Bonding type is required'),
          notes: Yup.string(),
          kickoffDoc: Yup.string().url('Must be a valid URL'),
          steps: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required('Step type is required'),
              goal: Yup.number().required('Step goal is required'),
              name: Yup.string().required('Step name is required'),
              note: Yup.string().when('type', {
                is: StepTypes.CONNECTORIZING,
                then: Yup.string().required('Include number and type of connectors in the notes'),
                otherwise: Yup.string(),
              }),
            }),
          ).min(1, 'At least one step is required'),
        }),
      ).min(1, 'At least one line item is required'),
      amount: Yup.string()
        .required('Amount is required')
        .matches(/^\$\d{1,3}(,\d{3})*(\.\d{2})?$/, 'Amount must be in the format $0.00'),
    }),
  ).min(1, 'At least one shipment is required'),
  status: Yup.object().shape({
    label: Yup.string().required('Status label is required'),
    value: Yup.string().required('Status value is required'),
  })
    .required('Status is required')
    .test(
      'is-shipped-when-completed',
      'All shipments must be marked as `Shipped` when status is Completed',
      (status, context) => {
        const { shipments } = context.parent;
        if (status?.value === OrderStatus.COMPLETED) {
          return shipments.every((shipment) => shipment.isShipped === true);
        }
        return true;
      },
    ),
  stripeId: Yup.string(),
});

const defaultLineItem = {
  projectId: '',
  quantity: null,
  bonding: '',
  notes: '',
  kickoffDoc: '',
  steps: Object.values(StepTypes).map((type) => ({
    type,
    goal: null,
    name: type,
    note: '',
  })),
};

const defaultShipment = {
  name: '',
  addressData: {
    name: '',
    company: '',
    phone: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  targetShipDate: '',
  shippingMethod: '',
  isShipped: false,
  lineItems: [
    { ...defaultLineItem },
  ],
  amount: '',
};

export default function ManageOrder({
  initData = {
    uid: '',
    name: '',
    orderDate: '',
    status: OrderStatus.NOT_STARTED,
    stripeId: '',
    purchaseOrder: [],
    shipments: [{ ...defaultShipment }],
  },
  isEditing = false,
}) {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [isInvalidUID, setIsInvalidUID] = useState();
  // Add a new document with a generated id
  const newOrderRef = useMemo(() => {
    if (isEditing) {
      return doc(getFirestore(), 'orders', initData.id);
    }
    return doc(collection(getFirestore(), 'orders'));
  }, [initData.id, isEditing]);

  const initialValues = useMemo(() => ({
    ...initData,
    status: { label: initData.status, value: initData.status },
  }), [initData]);

  const handleSubmitOrder = useCallback(async (values) => {
    // eslint-disable-next-line no-unused-vars
    const { id, ...castValue } = orderValidationSchema.cast(values);
    try {
      await setDoc(newOrderRef, {
        ...castValue,
        status: castValue.status?.value || OrderStatus.NOT_STARTED,
        shipments: castValue.shipments.map((shipment, shipIdx) => ({
          ...shipment,
          lineItems: shipment.lineItems.map((lineItem, idx) => ({
            ...lineItem,
            // add orderId and create a unique id for each line item
            orderId: newOrderRef.id,
            id: `${lineItem.projectId}_${newOrderRef.id}_${shipIdx}_${idx}`,
          })),
        })),
        hasShippedItems: castValue.shipments.some((shipment) => shipment.isShipped),
      });
      // redirect to the order detail page
      navigate('/orders');
    } catch (error) {
      addNotification({
        type: 'error',
        dismissible: true,
        content: `Failed to create order. ${error.message}`,
        id: 'create-order-error',
      });
    }
  }, [addNotification, navigate, newOrderRef]);

  const getLastSixUppercase = useCallback((str) => {
    if (str && typeof str === 'string') {
      return str.slice(-6).toUpperCase();
    }
    return ''; // or handle it as needed if the string doesn't exist
  }, []);

  const handleStripeIdChange = useCallback((stripeId, values, setFieldValue) => {
    const lastSixUppercase = getLastSixUppercase(stripeId);
    setFieldValue('stripeId', stripeId);
    setFieldValue('name', lastSixUppercase);
    values.shipments.forEach((shipment, index) => {
      setFieldValue(`shipments[${index}].name`, `${lastSixUppercase}.${index + 1}`);
    });
  }, [getLastSixUppercase]);

  const handleNewLineItem = useCallback(({ shipmentIndex, setFieldValue, currentLineItems }) => {
    setFieldValue(`shipments[${shipmentIndex}].lineItems`, [
      ...currentLineItems,
      defaultLineItem,
    ]);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={orderValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      validate={(validateValues) => {
        if (validateValues.uid && isInvalidUID) {
          return { uid: 'Invalid User ID' };
        }
        return {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitOrder(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        dirty,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <SpaceBetween direction="vertical" size="s">
            {/* Order Basic Info */}
            <Container>
              <ColumnLayout columns={2}>
                <OrganizationSelect
                  errorText={errors.organization}
                  selectedOrgId={values.organization}
                  disabled={isEditing}
                  onOrgChange={(orgId) => setFieldValue('organization', orgId)}
                />

                <UIDInput isEditing={isEditing} setIsInvalidUID={setIsInvalidUID} />

                <FormField
                  label="Order Date"
                  errorText={errors.orderDate}
                >
                  <Input
                    name="orderDate"
                    value={values.orderDate}
                    onChange={({ detail }) => { setFieldValue('orderDate', detail.value); }}
                  />
                </FormField>

                <FormField
                  label="Status"
                  errorText={errors.status}
                >
                  <Select
                    selectedOption={values.status}
                    onChange={({ detail }) => { setFieldValue('status', detail.selectedOption); }}
                    options={Object.values(OrderStatus).map((status) => (
                      { label: status, value: status }
                    ))}
                  />
                </FormField>

                <FormField
                  label="Stripe Id"
                  errorText={errors.stripeId}
                >
                  <Input
                    name="stripeId"
                    value={values.stripeId}
                    onChange={({ detail }) => {
                      handleStripeIdChange(detail.value, values, setFieldValue);
                    }}
                  />
                </FormField>

                <FormField
                  label="Name (Optional)"
                  errorText={errors.name}
                >
                  <Input
                    name="name"
                    value={values.name}
                    onChange={({ detail }) => { setFieldValue('name', detail.value); }}
                  />
                </FormField>
              </ColumnLayout>
            </Container>

            {/* Purchase Orders */}
            <Container header={(<Header>Upload Purchase Order</Header>)}>
              <FileUploader
                uploadPath={`orders/${newOrderRef.id}/purchaseOrder`}
                uploadedFiles={values.purchaseOrder || []}
                uploadErrors={errors.purchaseOrder || []}
                onEmpty={() => setFieldValue('purchaseOrder', [])}
                onUpload={(newFiles) => setFieldValue('purchaseOrder', newFiles)}
                fileTypeName="Purchase Order"
              />
            </Container>

            {/* Shipments */}
            <FieldArray name="shipments">
              {({ remove, push }) => (
                <SpaceBetween direction="vertical" size="s">
                  {values.shipments.map((shipment, index) => (
                    <Container
                      // eslint-disable-next-line react/no-array-index-key
                      key={`shipment_${index}`}
                      header={(
                        <Header
                          actions={index !== 0 && (
                            <Button
                              type="button"
                              onClick={() => remove(index)}
                            >
                              Delete Shipment
                            </Button>
                          )}
                        >
                          {`Shipment ${index + 1}`}
                        </Header>
                      )}
                    >
                      <SpaceBetween direction="vertical" size="s">
                        {/* Shipment basic Info */}
                        <ColumnLayout columns={2}>
                          <FormField
                            label="Name"
                            errorText={errors?.shipments?.[index]?.name}
                          >
                            <Input
                              name={`shipments[${index}].name`}
                              value={values.shipments[index]?.name}
                              onChange={({ detail }) => { setFieldValue(`shipments[${index}].name`, detail.value); }}
                            />
                          </FormField>
                          <FormField
                            label="Target Ship Date"
                            errorText={errors?.shipments?.[index]?.targetShipDate}
                          >
                            <Input
                              name={`shipments[${index}].targetShipDate`}
                              value={values.shipments[index]?.targetShipDate}
                              onChange={({ detail }) => { setFieldValue(`shipments[${index}].targetShipDate`, detail.value); }}
                            />
                          </FormField>
                          <FormField
                            label="Address"
                            errorText={errors?.shipments?.[index]?.addressData ? 'Address is required' : ''}
                          >
                            <AddressDisplay
                              backupAddress={values.shipments[index]?.address}
                              address={values.shipments[index]?.addressData}
                              onEdit={(newAddress) => {
                                setFieldValue(`shipments[${index}].addressData`, newAddress);
                              }}
                            />
                          </FormField>
                          <FormField
                            label="Amount"
                            errorText={errors?.shipments?.[index]?.amount}
                          >
                            <Input
                              name={`shipments[${index}].amount`}
                              value={values.shipments[index].amount}
                              onChange={({ detail }) => { setFieldValue(`shipments[${index}].amount`, detail.value); }}
                              onBlur={() => { setFieldValue(`shipments[${index}].amount`, formatCurrency(values.shipments[index].amount)); }}
                            />
                          </FormField>
                          <FormField
                            label="Shipping Method"
                            errorText={errors?.shipments?.[index]?.shippingMethod}
                          >
                            <Input
                              name={`shipments[${index}].shippingMethod`}
                              value={values.shipments[index]?.shippingMethod}
                              onChange={({ detail }) => { setFieldValue(`shipments[${index}].shippingMethod`, detail.value); }}
                            />
                          </FormField>

                          {isEditing && (
                            <FormField
                              label="Shipped"
                              errorText={errors?.shipments?.[index]?.isShipped}
                            >
                              <Checkbox
                                onChange={({ detail }) => setFieldValue(`shipments[${index}].isShipped`, detail.checked)}
                                checked={values.shipments[index]?.isShipped}
                              >
                                Shipped
                              </Checkbox>
                            </FormField>
                          )}
                        </ColumnLayout>

                        {/* Package Table */}
                        {isEditing && (
                          <Table
                            header={(
                              <Header
                                actions={
                                  <Button formAction="none" onClick={() => navigate(`/package/create/${newOrderRef.id}?shipIdx=${index}`)}>Create Package</Button>
                                }
                              >
                                Packages
                              </Header>
                          )}
                            wrapLines
                            empty={(
                              <Box
                                margin={{ vertical: 'xs' }}
                                textAlign="center"
                                color="inherit"
                              >
                                <SpaceBetween size="m">
                                  <b>No packages</b>
                                  <Button formAction="none" onClick={() => navigate(`/package/create/${newOrderRef.id}?shipIdx=${index}`)}>Create Package</Button>
                                </SpaceBetween>
                              </Box>
                            )}
                            columnDefinitions={[
                              {
                                id: 'shipDate',
                                header: 'Ship Date',
                                cell: (item) => (item.shipDate ? formatDate(item.shipDate) : 'N/A'),
                              },
                              {
                                id: 'carrier',
                                header: 'Carrier',
                                cell: (item) => item.carrier || 'N/A',
                              },
                              {
                                id: 'trackingCode',
                                header: 'Tracking Code',
                                cell: (item) => item.trackingCode || 'N/A',
                              },
                              {
                                id: 'trackingUrl',
                                header: 'Tracking Link',
                                // eslint-disable-next-line react/no-unstable-nested-components
                                cell: (item) => (
                                  item.trackingUrl ? (
                                    <a href={item.trackingUrl} target="_blank" rel="noreferrer">Tracking Link</a>
                                  ) : (
                                    'N/A'
                                  )
                                ),
                              },
                              {
                                id: 'shippingLabelUrl',
                                header: 'Shipping Label',
                                // eslint-disable-next-line react/no-unstable-nested-components
                                cell: (item) => (
                                  item.shippingLabelUrl ? (
                                    <a href={item.shippingLabelUrl} target="_blank" rel="noreferrer">Shipping Label</a>
                                  ) : (
                                    'N/A'
                                  )
                                ),
                              },
                              {
                                id: 'packingSlipUrl',
                                header: 'Packing Slip',
                                // eslint-disable-next-line react/no-unstable-nested-components
                                cell: (item) => (
                                  <a href={item.packingSlipUrl} target="_blank" rel="noreferrer">Packing Slip</a>
                                ),
                              },
                            ]}
                            items={shipment.trackingData}
                          />
                        )}

                        {/* LineItems */}
                        <LineItemsTable
                          shipment={shipment}
                          shipmentIndex={index}
                          onNewLineItem={() => handleNewLineItem({
                            shipmentIndex: index,
                            setFieldValue,
                            currentLineItems: values.shipments[index].lineItems,
                          })}
                        />
                      </SpaceBetween>
                    </Container>
                  ))}
                  <Button
                    type="button"
                    formAction="none"
                    onClick={() => push({ ...defaultShipment })}
                  >
                    Add Shipment
                  </Button>
                </SpaceBetween>
              )}
            </FieldArray>

            {/* Submit Button */}
            <Box padding={{ vertical: 's' }} textAlign="right">
              <Button type="submit" variant="primary" disabled={isSubmitting || !dirty}>
                {isEditing ? 'Update Order' : 'Create Order'}
              </Button>
            </Box>
          </SpaceBetween>
        </Form>
      )}
    </Formik>
  );
}

ManageOrder.propTypes = {
  initData: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    orderDate: PropTypes.string,
    status: PropTypes.string,
    stripeId: PropTypes.string,
    purchaseOrder: PropTypes.arrayOf(PropTypes.shape({
      downloadURL: PropTypes.string,
      lastModifiedTS: PropTypes.number,
      name: PropTypes.string,
      ref: PropTypes.string,
      type: PropTypes.string,
    })),
    shipments: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      targetShipDate: PropTypes.string,
      shippingMethod: PropTypes.string,
      lineItems: PropTypes.arrayOf(PropTypes.shape({
        projectId: PropTypes.string,
        quantity: PropTypes.number,
        bonding: PropTypes.string,
        notes: PropTypes.string,
        steps: PropTypes.arrayOf(PropTypes.shape({
          type: PropTypes.string,
          goal: PropTypes.number,
          name: PropTypes.string,
          note: PropTypes.string,
        })),
      })),
      amount: PropTypes.string,
    })),
  }),
  isEditing: PropTypes.bool,
};
