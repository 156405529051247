import { Header } from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  orderBy,
} from 'firebase/firestore';
import React, { useMemo } from 'react';

import PartsList from '../../components/PartsList';
import useCustomInfiniteQuery from '../../hooks/use-customInfiniteQuery';

function PartsListPage() {
  const partsQueryParams = useMemo(() => [
    collection(getFirestore(), 'parts'),
    orderBy('expiresAt', 'desc'),
  ], []);

  const {
    CustomPagination,
    pageData,
    isFetchingNextPage,
  } = useCustomInfiniteQuery({
    queryKey: 'getPartsData',
    queryParams: partsQueryParams,
    pageSize: 50,
  });

  return (
    <PartsList
      header={<Header variant="awsui-h1-sticky">Parts</Header>}
      variant="full-page"
      parts={pageData}
      isLoading={isFetchingNextPage}
      Pagination={<CustomPagination />}
    />
  );
}

export default PartsListPage;
