import {
  Box,
  Button,
  Checkbox,
  Container,
  ContentLayout,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  StatusIndicator,
  Table,
  Textarea,
  Wizard,
} from '@cloudscape-design/components';
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { usePartAndQuote } from '../../features/firebase';
import ImageModal, { useImageModalReducer } from './imageModal';
import StorageImage from './storageImage';
import FeedbackSummaryTable from './summaryTable';

function FeedbackWizard({ partId }) {
  const { quote } = usePartAndQuote(partId);
  const {
    id: quoteId,
    feedbackChecklistVersion,
    feedback: originalFeedback,
    optionalFeedback: originalOptionalFeedback,
    feedbackChecklistPasses: originalFeedbackChecklistPasses,
  } = quote;

  const [feedbackChecklistQueryResult, setFeedbackChecklistQueryResult] = useState([]);
  const [feedbackChecklistQueryStatus, setFeedbackChecklistQueryStatus] = useState('loading');

  useEffect(() => {
    if (!feedbackChecklistVersion) return undefined;
    const unsubscribe = onSnapshot(
      query(
        collection(getFirestore(), 'feedback_checklist'),
        where('version', '==', feedbackChecklistVersion),
      ),
      (snapshot) => {
        setFeedbackChecklistQueryResult(snapshot.docs.map((d) => d.data()));
        setFeedbackChecklistQueryStatus('success');
      },
      () => {
        setFeedbackChecklistQueryStatus('error');
      },
    );

    return () => unsubscribe();
  }, [feedbackChecklistVersion]);

  const { checklist, optionalChecklist } = useMemo(() => {
    if (feedbackChecklistQueryResult?.length === 1) {
      return {
        checklist: [...(feedbackChecklistQueryResult[0].items || []), { title: 'Custom' }],
        optionalChecklist: [...(feedbackChecklistQueryResult[0].optionalItems || [])],
      };
    }
    return { checklist: [], optionalChecklist: [] };
  }, [feedbackChecklistQueryResult]);

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const { feedbackChecklist, initialValues, ready } = useMemo(() => {
    let shouldIncrementActiveStepIndex = true;
    setActiveStepIndex(0);

    // wait until we get a valid checklist
    if (feedbackChecklistQueryResult?.length !== 1) {
      return {
        feedbackChecklist: { items: [], optionalItems: [] },
        initialValues: {},
        ready: false,
      };
    }

    // map original feedback and optional feedback to a map for looking up later
    const originalFeedbackMap = (originalFeedback || []).reduce((acc, item) => {
      const key = item.category;

      // ignore any old feedback from before we had categories
      if (!key) {
        return acc;
      }

      return {
        ...acc,
        [key]: [...(acc[key] || []), item],
      };
    }, {});
    const originalOptionalFeedbackMap = (originalOptionalFeedback || []).reduce((acc, item) => {
      const key = item.category;
      return {
        ...acc,
        [key]: [...(acc[key] || []), item],
      };
    }, {});

    // return starting values
    return {
      feedbackChecklist: { items: checklist, optionalItems: optionalChecklist },
      initialValues: {
        steps: [
          // map the checklist to steps
          ...checklist.reduce((acc, item) => {
            const step = {
              category: item.title,
              pass: false,
              feedbackType: 'feedback',
              feedback: [],
            };

            // if there is feedback for this item, add it to the step
            if (originalFeedbackMap[item.title]) {
              if (shouldIncrementActiveStepIndex) {
                setActiveStepIndex((i) => i + 1);
              }
              step.feedback = [...originalFeedbackMap[item.title]];
            } else if (originalFeedbackChecklistPasses
              && originalFeedbackChecklistPasses[item.title]) {
              if (shouldIncrementActiveStepIndex) {
                setActiveStepIndex((i) => i + 1);
              }
              step.pass = true;
            } else {
              shouldIncrementActiveStepIndex = false;
              step.feedback = [{
                category: item.title,
                title: item.title,
                text: item.text,
                image: '',
                checkboxText: item.checkboxText,
              }];
            }
            return [...acc, step];
          }, []),

          // map the optional checklist to steps
          ...optionalChecklist.reduce((acc, item) => {
            const step = {
              category: item.title,
              pass: false,
              feedbackType: 'optionalFeedback',
              feedback: [],
            };
            if (originalOptionalFeedbackMap[item.title]) {
              if (shouldIncrementActiveStepIndex) {
                setActiveStepIndex((i) => i + 1);
              }
              step.feedback = [...originalOptionalFeedbackMap[item.title]];
            } else if (originalFeedbackChecklistPasses
              && originalFeedbackChecklistPasses[item.title]) {
              if (shouldIncrementActiveStepIndex) {
                setActiveStepIndex((i) => i + 1);
              }
              step.pass = true;
            } else {
              shouldIncrementActiveStepIndex = false;
              step.feedback = [{
                category: item.title,
                title: item.title,
                text: item.text,
                image: '',
                options: item.options.map(({ discount, title }) => ({
                  discount,
                  title,
                  value: title,
                })),
              }];
            }
            return [...acc, step];
          }, []),
        ],
      },
      ready: true,
    };
  }, [
    checklist,
    optionalChecklist,
    originalFeedback,
    originalFeedbackChecklistPasses,
    originalOptionalFeedback,
  ]);

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  if (feedbackChecklistQueryStatus === 'loading' || !ready) {
    return (
      <ContentLayout>
        <Container header={<Header variant="h2">Feedback</Header>}>
          <Box textAlign="center">
            <StatusIndicator type="loading">Loading...</StatusIndicator>
          </Box>
        </Container>
      </ContentLayout>
    );
  }

  const mapFeedbackToFirebase = (values) => {
    const feedback = [];
    const optionalFeedback = [];
    const feedbackChecklistPasses = {};

    const mapFeedback = (feedbackItem) => {
      const retFeedbackItem = {
        category: feedbackItem.category,
        title: feedbackItem.title,
        text: feedbackItem.text,
      };
      if (feedbackItem.image) {
        retFeedbackItem.image = feedbackItem.image;
      }
      if (feedbackItem.checkboxText) {
        retFeedbackItem.checkboxText = feedbackItem.checkboxText;
      }
      if (feedbackItem.options) {
        retFeedbackItem.options = [...feedbackItem.options];
      }
      return retFeedbackItem;
    };
    values.steps.forEach((step) => {
      switch (step.feedbackType) {
        case 'feedback':
          feedback.push(...step.feedback.map(mapFeedback));
          break;
        case 'optionalFeedback':
          optionalFeedback.push(...step.feedback.map(mapFeedback));
          break;
        default:
          break;
      }
      if (step.pass) {
        feedbackChecklistPasses[step.category] = true;
      }
    });
    return {
      feedback,
      optionalFeedback,
      feedbackChecklistPasses,
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        // Check for duplicate titles
        const feedbackTitles = new Set();
        for (let i = 0; i < values.steps.length; i += 1) {
          const step = values.steps[i];
          for (let j = 0; j < step.feedback.length; j += 1) {
            const feedback = step.feedback[j];
            if (feedbackTitles.has(feedback.title)) {
              setErrors({ summary: `Feedback title "${feedback.title}" is duplicated. Titles must be unique.` });
              setSubmitting(false);
              return;
            }
            feedbackTitles.add(step.feedback[j].title);
          }
        }

        // save feedback to firebase
        try {
          const {
            feedback,
            optionalFeedback,
            feedbackChecklistPasses,
          } = mapFeedbackToFirebase(values);
          if (feedback.length === 0 && optionalFeedback.length === 0) {
            setErrors({ summary: 'Please add at least one piece of feedback.' });
            setSubmitting(false);
            return;
          }

          await updateDoc(doc(getFirestore(), 'quotes', quoteId), {
            feedback,
            optionalFeedback,
            feedbackChecklistPasses,
            feedbackChecklistReviewed: true,
          });
          navigate(-1);
        } catch (error) {
          setErrors({ summary: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values, setFieldValue, handleSubmit, isSubmitting,
      }) => {
        const validateCurrentStep = useCallback(() => {
          if (activeStepIndex === values.steps.length) {
            return null;
          }
          if (values.steps[activeStepIndex].pass) {
            return null;
          }
          if (values.steps[activeStepIndex].feedback.length === 0) {
            return 'Please add feedback';
          }
          const existingTitles = new Set();
          for (let i = 0; i < values.steps[activeStepIndex].feedback.length; i += 1) {
            const feedback = values.steps[activeStepIndex].feedback[i];
            if (!feedback.title || !feedback.text) {
              return 'Please fill out all feedback fields';
            }
            if (existingTitles.has(feedback.title)) {
              return 'Feedback titles must be unique';
            }
            existingTitles.add(feedback.title);
          }
          return null;
        }, [values, activeStepIndex]);

        const [{
          visible: imageModalVisible,
          initialValue: imageModalInitialValue,
          fieldName: imageModalFieldName,
        }, dispatchImageModal] = useImageModalReducer();

        const { reviewFeedback, reviewOptionalFeedback } = useMemo(() => {
          const { feedback, optionalFeedback } = mapFeedbackToFirebase(values);
          return {
            reviewFeedback: feedback.map((item) => ({
              ...item,
              category: (
                <Button
                  variant="inline-link"
                  onClick={() => {
                    setActiveStepIndex(
                      values.steps.findIndex((step) => step.category === item.category),
                    );
                  }}
                >
                  {item.category}
                </Button>
              ),
            })),
            reviewOptionalFeedback: optionalFeedback.map((item) => ({
              ...item,
              category: (
                <Button
                  variant="inline-link"
                  onClick={() => {
                    setActiveStepIndex(
                      values.steps.findIndex((step) => step.category === item.category),
                    );
                  }}
                >
                  {item.category}
                </Button>),
            })),
          };
        }, [values]);

        // cancel modal
        const [cancelModalOpen, setCancelModalOpen] = useState(false);

        return (
          <>
            <ImageModal
              visible={imageModalVisible}
              onDismiss={() => { dispatchImageModal({ type: 'close' }); }}
              path={`parts/${partId}/feedback-images`}
              initialValue={imageModalInitialValue}
              fieldName={imageModalFieldName}
              setFieldValue={setFieldValue}
            />
            <Modal
              size="small"
              header="Are you sure?"
              visible={cancelModalOpen}
              onDismiss={() => { setCancelModalOpen(false); }}
              footer={(
                <Box float="right">
                  <SpaceBetween size="xs" direction="horizontal">
                    <Button onClick={() => setCancelModalOpen(false)}>
                      Never mind
                    </Button>
                    <Button variant="primary" onClick={() => { navigate(-1); }}>
                      Yes, cancel
                    </Button>
                  </SpaceBetween>
                </Box>
                  )}
            >
              All changes will be lost
            </Modal>
            <Wizard
              i18nStrings={{
                stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) => `Check ${stepNumber} of ${stepsCount}`,
                navigationAriaLabel: 'Steps',
                cancelButton: 'Cancel',
                previousButton: 'Previous',
                nextButton: values.steps[activeStepIndex]?.pass ? 'Pass' : 'Add feedback',
                submitButton: 'Save',
                optional: 'optional',
              }}
              isLoadingNextStep={isSubmitting}
              onNavigate={({ detail }) => {
                const error = validateCurrentStep();
                if (error) {
                  setErrors({ [activeStepIndex]: error });
                  return;
                }
                setErrors({});
                setActiveStepIndex(detail.requestedStepIndex);
              }}
              onSubmit={handleSubmit}
              onCancel={() => { setCancelModalOpen(true); }}
              activeStepIndex={activeStepIndex}
              allowSkipTo
              steps={[
                ...feedbackChecklist.items.map((item, stepIndex) => ({
                  title: item.title,
                  description: item.hint ? (
                    // eslint-disable-next-line react/no-danger
                    <div dangerouslySetInnerHTML={{ __html: item.hint }} />
                  ) : item.text,
                  errorText: errors[stepIndex],
                  content: (
                    <Container
                      header={(
                        <Header
                          variant="h2"
                          actions={!values.steps[stepIndex].pass ? (
                            <Button
                              iconName="add-plus"
                              onClick={() => {
                                setFieldValue(`steps[${stepIndex}].feedback`, [
                                  ...values.steps[stepIndex].feedback,
                                  {
                                    title: item.title,
                                    text: item.text,
                                    image: '',
                                    checkboxText: item.checkboxText,
                                    category: item.title,
                                  },
                                ]);
                              }}
                            />
                          ) : null}
                        >
                          Review
                        </Header>
                  )}
                    >
                      <SpaceBetween direction="vertical" size="s">
                        <FormField label="Passes check?">
                          <Checkbox
                            onChange={({ detail }) => {
                              if (detail.checked) {
                                setFieldValue(`steps[${stepIndex}].feedback`, []);
                                setActiveStepIndex((prev) => prev + 1);
                              } else {
                                setFieldValue(`steps[${stepIndex}].feedback`, [{
                                  title: item.title,
                                  text: item.text,
                                  image: '',
                                  checkboxText: item.checkboxText,
                                  category: item.title,
                                }]);
                              }
                              setFieldValue(`steps[${stepIndex}].pass`, detail.checked);
                            }}
                            checked={values.steps[stepIndex].pass}
                          >
                            Pass
                          </Checkbox>
                        </FormField>
                        {values.steps[stepIndex].feedback.map((feedback, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <React.Fragment key={index}>
                            {values.steps[stepIndex].feedback.length > 1 ? (
                              <Header
                                variant="h3"
                                actions={(
                                  <Button
                                    iconName="delete-marker"
                                    onClick={() => { setFieldValue(`steps[${stepIndex}].feedback`, values.steps[stepIndex].feedback.filter((_, i) => i !== index)); }}
                                  />
                                )}
                              >
                                {`${item.title} #${index + 1}`}
                              </Header>
                            ) : null}
                            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                              <SpaceBetween direction="vertical" size="l">
                                <FormField label="Title">
                                  <Input
                                    value={feedback.title}
                                    onChange={({ detail }) => {
                                      // eslint-disable-next-line no-control-regex
                                      const asciiOnlyValue = detail.value.replace(/[^\x00-\x7F]/g, '').replaceAll('.', '').replaceAll('/', '');
                                      setFieldValue(`steps[${stepIndex}].feedback[${index}].title`, asciiOnlyValue);
                                    }}
                                  />
                                </FormField>
                                <FormField label="Feedback text">
                                  <Textarea
                                    value={feedback.text}
                                    onChange={({ detail }) => setFieldValue(`steps[${stepIndex}].feedback[${index}].text`, detail.value)}
                                  />
                                </FormField>
                                <FormField label="Checkbox text">
                                  <Input
                                    value={feedback.checkboxText}
                                    onChange={({ detail }) => setFieldValue(`steps[${stepIndex}].feedback[${index}].checkboxText`, detail.value)}
                                    placeholder="Accept changes"
                                  />
                                </FormField>
                              </SpaceBetween>
                              <Box>
                                <FormField label="Image">
                                  {feedback.image ? (
                                    <SpaceBetween direction="vertical" size="s">
                                      <StorageImage storagePath={feedback.image} alt={feedback.title} style={{ width: '100%' }} />
                                      <Button
                                        variant="normal"
                                        onClick={() => {
                                          dispatchImageModal({
                                            type: 'open',
                                            data: {
                                              initialValue: feedback.image,
                                              fieldName: `steps[${stepIndex}].feedback[${index}].image`,
                                            },
                                          });
                                        }}
                                      >
                                        Edit image
                                      </Button>
                                    </SpaceBetween>
                                  ) : (
                                    <Box textAlign="center" margin="m">
                                      <Button
                                        variant="primary"
                                        iconName="add-plus"
                                        onClick={() => {
                                          dispatchImageModal({
                                            type: 'open',
                                            data: {
                                              initialValue: null,
                                              fieldName: `steps[${stepIndex}].feedback[${index}].image`,
                                            },
                                          });
                                        }}
                                      >
                                        Add image
                                      </Button>
                                    </Box>
                                  ) }
                                </FormField>
                              </Box>
                            </Grid>
                            {values.steps[stepIndex].feedback.length > 1
                              && index < values.steps[stepIndex].feedback.length - 1
                              ? <hr /> : null}
                          </React.Fragment>
                        ))}
                      </SpaceBetween>
                    </Container>
                  ),
                })),
                ...feedbackChecklist.optionalItems.map((item, stepIndex) => ({
                  title: item.title,
                  description: item.hint ? (
                    // eslint-disable-next-line react/no-danger
                    <div dangerouslySetInnerHTML={{ __html: item.hint }} />
                  ) : item.text,
                  content: (
                    <Container header={<Header variant="h2">Review</Header>}>
                      <SpaceBetween direction="vertical" size="s">
                        <FormField label="Passes check?">
                          <Checkbox
                            onChange={({ detail }) => {
                              if (detail.checked) {
                                setFieldValue(`steps[${feedbackChecklist.items.length + stepIndex}].feedback`, []);
                                setActiveStepIndex((prev) => prev + 1);
                              } else {
                                setFieldValue(`steps[${feedbackChecklist.items.length + stepIndex}].feedback`, [{
                                  title: item.title,
                                  text: item.text,
                                  image: '',
                                  options: item.options.map(
                                    ({ discount, title, value }) => ({ discount, title, value }),
                                  ),
                                  category: item.title,
                                }]);
                              }
                              setFieldValue(`steps[${feedbackChecklist.items.length + stepIndex}].pass`, detail.checked);
                            }}
                            checked={values.steps[feedbackChecklist.items.length + stepIndex].pass}
                          >
                            Pass
                          </Checkbox>
                        </FormField>
                        {values.steps[feedbackChecklist.items.length + stepIndex]
                          .feedback.map((feedback, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                            <SpaceBetween size="m" key={index}>
                              <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                                <SpaceBetween direction="vertical" size="l">
                                  <FormField label="Title">
                                    <Input
                                      value={feedback.title}
                                      onChange={({ detail }) => setFieldValue(`steps[${feedbackChecklist.items.length + stepIndex}].feedback[${index}].title`, detail.value)}
                                    />
                                  </FormField>
                                  <FormField label="Feedback text">
                                    <Textarea
                                      value={feedback.text}
                                      onChange={({ detail }) => setFieldValue(`steps[${feedbackChecklist.items.length + stepIndex}].feedback[${index}].text`, detail.value)}
                                    />
                                  </FormField>
                                </SpaceBetween>
                                <Box>
                                  <FormField label="Image">
                                    {feedback.image ? (
                                      <SpaceBetween direction="vertical" size="s">
                                        <StorageImage storagePath={feedback.image} alt={feedback.title} style={{ width: '100%' }} />
                                        <Button
                                          variant="normal"
                                          onClick={() => {
                                            dispatchImageModal({
                                              type: 'open',
                                              data: {
                                                initialValue: feedback.image,
                                                fieldName: `steps[${feedbackChecklist.items.length + stepIndex}].feedback[${index}].image`,
                                              },
                                            });
                                          }}
                                        >
                                          Edit image
                                        </Button>
                                      </SpaceBetween>
                                    ) : (
                                      <Box textAlign="center" margin="m">
                                        <Button
                                          variant="primary"
                                          iconName="add-plus"
                                          onClick={() => {
                                            dispatchImageModal({
                                              type: 'open',
                                              data: {
                                                initialValue: null,
                                                fieldName: `steps[${feedbackChecklist.items.length + stepIndex}].feedback[${index}].image`,
                                              },
                                            });
                                          }}
                                        >
                                          Add image
                                        </Button>
                                      </Box>
                                    ) }
                                  </FormField>
                                </Box>
                              </Grid>
                              <Table
                                variant="embedded"
                                items={feedback.options}
                                submitEdit={(row, { id }, newValue) => {
                                  const optionIndex = feedback.options.findIndex(
                                    (option) => option[id] === row[id],
                                  );
                                  switch (id) {
                                    case 'title': {
                                      setFieldValue(`steps[${feedbackChecklist.items.length + stepIndex}].feedback[${index}].options[${optionIndex}].title`, newValue);
                                      setFieldValue(`steps[${feedbackChecklist.items.length + stepIndex}].feedback[${index}].options[${optionIndex}].value`, newValue);
                                      break;
                                    }
                                    case 'discount': {
                                      setFieldValue(`steps[${feedbackChecklist.items.length + stepIndex}].feedback[${index}].options[${optionIndex}].discount`, parseFloat(newValue));
                                      break;
                                    }
                                    default:
                                      break;
                                  }
                                }}
                                columnDefinitions={[
                                  {
                                    id: 'title',
                                    header: 'Title',
                                    cell: (row) => row.title,
                                    editConfig: {
                                      ariaLabel: 'Title',
                                      editIconAriaLabel: 'editable',
                                      errorIconAriaLabel: 'Title Error',
                                      // eslint-disable-next-line max-len
                                      // eslint-disable-next-line react/no-unstable-nested-components
                                      editingCell: (cell, { currentValue, setValue }) => (
                                        <Input
                                          autoFocus
                                          value={currentValue ?? cell.title}
                                          onChange={({ detail }) => setValue(detail.value)}
                                        />
                                      ),
                                    },
                                  },
                                  {
                                    id: 'discount',
                                    header: 'Discount',
                                    cell: (row) => row.discount,
                                    editConfig: {
                                      ariaLabel: 'Title',
                                      editIconAriaLabel: 'editable',
                                      errorIconAriaLabel: 'Title Error',
                                      // eslint-disable-next-line max-len
                                      // eslint-disable-next-line react/no-unstable-nested-components
                                      editingCell: (cell, { currentValue, setValue }) => (
                                        <Input
                                          autoFocus
                                          type="number"
                                          inputMode="numeric"
                                          value={currentValue ?? cell.discount}
                                          onChange={({ detail }) => setValue(detail.value)}
                                        />
                                      ),
                                    },
                                  },
                                ]}
                              />
                            </SpaceBetween>
                          ))}
                      </SpaceBetween>
                    </Container>
                  ),
                })),
                {
                  title: 'Summary',
                  description: 'Review and submit your feedback',
                  errorText: errors.summary,
                  content: (
                    <FeedbackSummaryTable
                      partId={partId}
                      feedback={reviewFeedback}
                      optionalFeedback={reviewOptionalFeedback}
                    />
                  ),
                },
              ]}
            />
          </>
        );
      }}
    </Formik>
  );
}

FeedbackWizard.propTypes = {
  partId: PropTypes.string.isRequired,
};

export default FeedbackWizard;
