import {
  FormField,
  Select,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

export default function ToolSelector({
  onChange,
  projectId = '',
  selected = null,
  error = null,
}) {
  const [tools, setTools] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(getFirestore(), 'tools'),
        where('project', '==', projectId || ''),
        orderBy('createTime', 'desc'),
      ),
      (snapshot) => {
        const toolsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTools(toolsData);
      },
    );

    return () => unsubscribe();
  }, [projectId]);

  const toolOptions = useMemo(() => tools.map((tool) => ({
    id: tool.id,
    value: tool.name,
    label: `${tool.name} - ${tool.project} - ${tool.cavities.map((cavity) => cavity.name).join(', ')}`,
    cavities: tool.cavities,
  })), [tools]);

  return (
    <FormField
      label="Tool name"
      stretch
      errorText={error}
      constraintText={(
        <span>
          {'Don\'t see the tool you\'re looking for? Add a new tool '}
          <a target="_blank" href="/tools" rel="noreferrer">here</a>
          .
        </span>
      )}
    >
      <Select
        selectedOption={{ value: selected?.value, label: selected?.value }}
        onChange={({ detail }) => onChange(detail.selectedOption)}
        options={toolOptions}
        placeholder="Choose a tool"
        empty="No tools found for this project"
      />
    </FormField>
  );
}

ToolSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  selected: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  error: PropTypes.string,
};
