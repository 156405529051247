import {
  Link,
  SpaceBetween,
} from '@cloudscape-design/components';
import {
  colorBackgroundItemSelected,
  colorBorderItemSelected,
} from '@cloudscape-design/design-tokens';
import PropTypes from 'prop-types';
import React from 'react';

function ProcessDisplay({
  processName,
  selectedOptions = [],
}) {
  if (selectedOptions.length === 0) {
    return 'N/A';
  }

  return (
    <SpaceBetween direction="horizontal" size="xs">
      {selectedOptions.map((option) => (
        <div
          key={option.id}
          style={{
            border: `2px solid ${colorBorderItemSelected}`,
            backgroundColor: colorBackgroundItemSelected,
            padding: '4px 12px',
            borderRadius: '8px',
            display: 'inline-flex',
            flexDirection: 'column',
          }}
        >
          <div>{`${option.project} - ${option.runID}${option.cavity ? ` - ${option.cavity}` : ''}`}</div>
          <Link variant="primary" fontSize="body-s" href={`${process.env.REACT_APP_ORIGIN}/process-data/${processName}/${option.id}`}>View process log</Link>
        </div>
      ))}
    </SpaceBetween>
  );
}

ProcessDisplay.propTypes = {
  processName: PropTypes.string.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    project: PropTypes.string,
    runID: PropTypes.number,
  })),
};

export default ProcessDisplay;
