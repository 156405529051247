import {
  Alert,
  Autosuggest,
  Box,
  Button,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import { Materials } from '@parallel-fluidics/constants';
import { useQuery } from '@tanstack/react-query';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

function incrementString(str) {
  if (!str) return '';
  const prefix = str.match(/[^\d]+/)[0]; // Non-digit prefix (e.g., 'C')
  const numberPart = str.match(/\d+/)[0]; // Digit part (e.g., '0011')

  // Increment the numeric part and pad with leading zeros
  const incrementedNumber = String(Number(numberPart) + 1).padStart(numberPart.length, '0');

  // Return the combined result
  return prefix + incrementedNumber;
}

export default function AddProjectModal({ open, onClose, projects = [] }) {
  const [debouncedPartId, setDebouncedPartId] = useState(null);
  const [foundDuplicate, setFoundDuplicate] = useState(null);
  const [isPartIdValid, setIsPartIdValid] = useState(false);

  const initialValues = useMemo(() => {
    const firstCProject = projects.find((project) => project.name.startsWith('C'));
    return {
      projectId: incrementString(firstCProject?.name),
      material: '',
      filename: '',
      partId: '',
      notes: '',
    };
  }, [projects]);

  const {
    values, errors, handleSubmit, setFieldValue, validateForm, isSubmitting,
  } = useFormik({
    initialValues,
    validateOnBlur: false,
    validate: (validateValues) => {
      const validateErrors = {};
      if (!validateValues.projectId) {
        validateErrors.projectId = 'Required';
      } else {
        const projectExists = projects.some((project) => project.name === validateValues.projectId);
        if (projectExists) validateErrors.projectId = 'Project ID already exists';
      }
      if (!validateValues.partId) {
        validateErrors.partId = 'Required';
      } else if (!isPartIdValid) {
        validateErrors.partId = 'Invalid part ID';
      }
      if (!validateValues.material) {
        validateErrors.material = 'Required';
      }
      if (!validateValues.filename) {
        validateErrors.filename = 'Required';
      }
      if (!validateValues.notes && foundDuplicate) {
        validateErrors.notes = 'Found a project for this part ID and material. Add a note to indicate why a new project ID is required.';
      }
      return validateErrors;
    },
    onSubmit: async (submitValues) => {
      try {
        await addDoc(collection(getFirestore(), 'projects'), {
          name: submitValues.projectId,
          material: submitValues.material,
          filename: submitValues.filename,
          adminLink: `${process.env.REACT_APP_ORIGIN}/part/${submitValues.partId}`,
          ...(submitValues.notes && { notes: submitValues.notes }),
        });
        onClose();
      } catch (error) {
        console.error('Error adding document:', error);
      }
    },
  });

  useEffect(() => {
    if (!debouncedPartId) return;
    // form validation run before isPartIdValid updated, so need to manually trigger this validation
    validateForm();
  }, [isPartIdValid, validateForm, debouncedPartId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPartId(values.partId);
    }, 500);

    return () => clearTimeout(handler);
  }, [values.partId]);

  useEffect(() => {
    if (!debouncedPartId || !values.material) return;
    // try to find the project that matches the partId and material
    const found = projects.find((project) => (
      project.adminLink?.includes(debouncedPartId)
      && project.material?.toLowerCase() === values.material?.toLowerCase()
    ));
    if (found) {
      setFoundDuplicate(found);
    } else {
      setFoundDuplicate(null);
    }
  }, [debouncedPartId, projects, values.material]);

  const { isFetching } = useQuery({
    queryKey: ['getPartById', debouncedPartId],
    queryFn: async () => {
      const docRef = doc(getFirestore(), 'parts', debouncedPartId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const part = docSnap.data();
        setFieldValue('filename', part.filename);
        setIsPartIdValid(true);
        return part;
      }
      setIsPartIdValid(false);
      return null;
    },
    enabled: !!debouncedPartId,
  });

  return (
    <Modal
      onDismiss={onClose}
      visible={open}
      header="Create a customer project"
    >
      <form onSubmit={handleSubmit}>
        <SpaceBetween direction="vertical" size="s">
          {foundDuplicate && (
            <Alert statusIconAriaLabel="Warning" type="warning">
              Found a project with the same part ID and material:
              {' '}
              <a href={foundDuplicate.adminLink} target="_blank" rel="noreferrer">{foundDuplicate.name}</a>
            </Alert>
          )}
          <FormField
            label="Project Id"
            errorText={errors.projectId}
          >
            <Input
              value={values.projectId}
              onChange={({ detail }) => { setFieldValue('projectId', detail.value); }}
            />
          </FormField>
          <FormField
            label="Part ID"
            errorText={errors.partId}
          >
            <Input
              value={values.partId}
              onChange={({ detail }) => { setFieldValue('partId', detail.value); }}
            />
          </FormField>
          <FormField
            label="Material"
            errorText={errors.material}
          >
            <Autosuggest
              value={values.material}
              onChange={({ detail }) => { setFieldValue('material', detail.value); }}
              options={Materials.map((material) => ({ value: material }))}
              placeholder="Type or select a value"
              empty="No options found"
            />
          </FormField>
          <FormField
            label="File name"
            errorText={errors.filename}
          >
            <Input
              value={values.filename}
              onChange={({ detail }) => { setFieldValue('filename', detail.value); }}
            />
          </FormField>
          <FormField
            label="Notes"
            errorText={errors.notes}
          >
            <Textarea
              value={values.notes}
              onChange={({ detail }) => { setFieldValue('notes', detail.value); }}
            />
          </FormField>

          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onClose}>Cancel</Button>
              <Button variant="primary" type="submit" loading={isSubmitting || isFetching}>Create</Button>
            </SpaceBetween>
          </Box>
        </SpaceBetween>
      </form>
    </Modal>
  );
}

AddProjectModal.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
