/* eslint-disable no-console */
import {
  Box,
  Button,
  FormField,
  Grid,
  Input,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import {
  deleteUser,
  EmailAuthProvider,
  getAuth,
  getMultiFactorResolver,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  multiFactor,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  unlink,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

async function handleSendSMSCode(resolver, auth, recaptchaVerifier) {
  const phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  };

  const phoneAuthProvider = new PhoneAuthProvider(auth);
  const verificationId = await phoneAuthProvider.verifyPhoneNumber(
    phoneInfoOptions,
    recaptchaVerifier,
  );
  return verificationId;
}

async function handleMFASignIn(resolver, verificationId, verificationCode) {
  const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);
  const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
  return userCredential;
}

export default function LoginProvider({ children }) {
  const auth = getAuth();
  const functions = getFunctions();
  const [searchParams] = useSearchParams();

  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openLinkGoogleModal, setOpenLinkGoogleModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openPersonalLogoutModal, setOpenPersonalLogoutModal] = useState(false);
  const [openCreateEmailModal, setOpenCreateEmailModal] = useState(false);
  const [openMFAModal, setOpenMFAModal] = useState(false);
  const [openEmailSentModal, setOpenEmailSentModal] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState('');
  const [verificationID, setVerificationID] = useState(null);
  const [canRedirect, setCanRedirect] = useState(false);

  // set up reCAPTCHA verifier
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const recaptchaRef = useCallback((node) => {
    if (node !== null) {
      setRecaptchaVerifier(new RecaptchaVerifier(auth, node, { size: 'invisible' }));
    }
  }, [auth]);
  const resolverRef = useRef();

  const googleProvider = useMemo(() => new GoogleAuthProvider(), []);

  useEffect(() => {
    const isEmailVerified = searchParams.get('emailVerified');
    if (isEmailVerified === 'true') {
      localStorage.removeItem('lastVerificationEmail');
    }
  }, [searchParams]);

  useEffect(() => {
    setOpenSignInModal(false);
    setOpenLinkGoogleModal(false);
    setOpenLogoutModal(false);
    setOpenCreateEmailModal(false);
    setOpenMFAModal(false);
    setOpenEmailSentModal(false);
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const { emailVerified } = authUser;
        // ✅ Check if the email is verified
        if (!emailVerified) {
          // send verification email if not sent in the last hour
          // https://medium.com/@beard0/firebase-admin-auth-link-expiration-times-9b5b090eae37
          // email verification link is expired in 3 days, so 1 hour should be fine
          const lastSent = localStorage.getItem('lastVerificationEmail');
          const now = Date.now();
          if (!lastSent || now - Number(lastSent) > 60 * 60 * 1000) {
            const actionCodeSettings = {
              url: `${window.location.href}?emailVerified=true`,
              handleCodeInApp: true,
            };
            await sendEmailVerification(authUser, actionCodeSettings);
            localStorage.setItem('lastVerificationEmail', now.toString());
          }
          setOpenEmailSentModal(true);
          return;
        }

        // ✅ Check if MFA is enabled
        const { enrolledFactors } = multiFactor(authUser);
        const isMFAEnabled = enrolledFactors.length > 0;
        if (!isMFAEnabled) {
          setOpenMFAModal(true);
          return;
        }

        if (authUser.providerData.length === 1) {
          const authType = authUser.providerData[0].providerId; // google.com or password
          if (authUser.email.endsWith('@parallelfluidics.com')) { // email login
            setOpenLinkGoogleModal(true);
          } else if (authType === 'google.com') { // google login
            if (authUser.email.endsWith('@parallel.design')) {
              // check if user has an email account exists
              const email = `${authUser.email.split('@')[0]}@parallelfluidics.com`;
              setSignUpEmail(email);
              const getUserIDByEmail = httpsCallable(functions, 'getUserIDByEmailV2');
              const result = await getUserIDByEmail({ userEmail: email }).catch(() => ({}));
              if (result.data) { // if yes, link the google account to the email account
              // User is logged in with Google account, but found email account
                setOpenLogoutModal(true);
              } else { // if not, create a new user with email account
                setOpenCreateEmailModal(true);
              }
            } else {
              // user logged in with google account, but email not ending with @parallel.design
              setOpenPersonalLogoutModal(true);
            }
          }
        } else if (emailVerified && isMFAEnabled) {
          setCanRedirect(true);
        }
      } else {
        // User is signed out
        setCanRedirect(false);
        setOpenSignInModal(true);
      }
    });
    return () => unsubscribe();
  }, [auth, functions, refresh]);

  const enrollMFA = useCallback(async (phoneNumber) => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User not logged in');

      const multiFactorSession = await multiFactor(user).getSession();
      const phoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession,
      };

      // Create a phone authentication provider
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier,
      );

      // Prompt user to enter the verification code sent to their phone
      // eslint-disable-next-line no-alert
      const verificationCode = prompt('Enter the SMS verification code:');

      // Create a phone credential
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      // Enroll the phone as a second factor
      await multiFactor(user).enroll(multiFactorAssertion, 'My Phone');

      // Reload user to update changes
      await auth.currentUser.reload();
      setRefresh((prev) => !prev);
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        // logout user to clear the session, so they can login again
        signOut(auth);
      }
      throw error;
    }
  }, [auth, recaptchaVerifier]);

  const signInWithGoogle = useCallback(async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        resolverRef.current = getMultiFactorResolver(auth, error);
        const verifyId = await handleSendSMSCode(resolverRef.current, auth, recaptchaVerifier);
        setVerificationID(verifyId);
        setOpenSignInModal(false);
      } else {
        console.error('Google Login failed:', error);
      }
    }
  }, [auth, googleProvider, recaptchaVerifier]);

  const handleUnlinkProvider = useCallback(async (providerId) => {
    unlink(auth.currentUser, providerId).then(() => {
      // Auth provider unlinked from account
      // eslint-disable-next-line no-console
      console.log('Provider unlinked', providerId);
    }).catch((error) => {
      // An error happened
      console.log('Error unlinking provider:', error);
    });
  }, [auth.currentUser]);

  const handleLinkGoogle = useCallback(async () => {
    try {
      // await linkWithPopup(auth.currentUser, provider);
      linkWithPopup(auth.currentUser, googleProvider).then(async () => {
        await auth.currentUser.reload();
      }).catch(async (error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          // show Link success, and let user in
          await auth.currentUser.reload();
          // verify google email is ending with @parallel.design
          const googleProviders = auth.currentUser.providerData.filter((provider) => provider.providerId === 'google.com');
          if (googleProviders.length === 0) {
            console.error('Google provider not found');
            return;
          }
          // unlink google provider with email not equal to `@parallel.design`
          let needReload = false;
          await Promise.all(googleProviders.map(async (provider) => {
            if (!provider.email.endsWith('@parallel.design')) {
              await handleUnlinkProvider(provider.providerId);
              needReload = true;
            }
          }));
          if (needReload) {
            await auth.currentUser.reload();
            // eslint-disable-next-line no-alert
            alert('Please re-login with your Parallel Google account.');
          }
        }
        setRefresh((prev) => !prev);
      });
    } catch (error) {
      console.error('Error linking accounts:', error);
    }
  }, [auth.currentUser, googleProvider, handleUnlinkProvider]);

  const handleLogoutAndDelete = useCallback(async () => {
    // sign out
    deleteUser(auth.currentUser).then(() => {
      // User deleted.
      setOpenLogoutModal(false);
    }).catch((error) => {
      // An error ocurred
      console.log('Error deleting user:', error);
    });
  }, [auth.currentUser]);

  return (
    <>
      <div ref={recaptchaRef} />
      {openSignInModal && (
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={async (values, { setFieldError }) => {
            try {
              await signInWithEmailAndPassword(
                auth,
                values.username,
                values.password,
              );
            } catch (err) {
              if (err.code === 'auth/multi-factor-auth-required') {
                try {
                  resolverRef.current = getMultiFactorResolver(auth, err);
                  const verifyId = await handleSendSMSCode(
                    resolverRef.current,
                    auth,
                    recaptchaVerifier,
                  );
                  setVerificationID(verifyId);
                  setOpenSignInModal(false);
                } catch (mfaErr) {
                  setFieldError('username', mfaErr.message);
                }
              } else {
                setFieldError('username', err.message);
              }
            }
          }}
        >
          {({
            values, errors, handleSubmit, setFieldValue, isSubmitting,
          }) => (
            <Modal
              size="medium"
              header="Sign in to continue"
              visible={openSignInModal}
              footer={(
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button onClick={() => signInWithGoogle()}>Login with Google</Button>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  >
                    Login
                  </Button>
                </div>
            )}
            >
              <Grid
                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
              >
                <FormField
                  label="Username"
                  errorText={errors.username}
                >
                  <Input
                    value={values.username}
                    onChange={({ detail }) => { setFieldValue('username', detail.value); }}
                    disabled={!!values.verificationID || isSubmitting}
                  />
                </FormField>
                <FormField
                  label="Password"
                  errorText={errors.password}
                >
                  <Input
                    value={values.password}
                    onChange={({ detail }) => { setFieldValue('password', detail.value); }}
                    type="password"
                    disabled={!!values.verificationID || isSubmitting}
                  />
                </FormField>
              </Grid>
            </Modal>
          )}
        </Formik>
      )}

      {verificationID && (
        <Formik
          initialValues={{ verificationCode: '' }}
          onSubmit={(values, actions) => {
            handleMFASignIn(resolverRef.current, verificationID, values.verificationCode)
              .then(() => {
                actions.resetForm();
                setVerificationID(null);
                setOpenSignInModal(false);
              })
              .catch((error) => {
                actions.setFieldError('verificationCode', error.message);
                actions.setSubmitting(false);
              });
          }}
        >
          {({
            values, setFieldValue, errors, handleSubmit, isSubmitting,
          }) => (
            <Modal
              header="Phone Verification"
              visible={!!verificationID}
              footer={(
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="m">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                      loading={isSubmitting}
                    >
                      Verify
                    </Button>
                  </SpaceBetween>
                </Box>
            )}
            >
              <FormField
                label="Enter the SMS verification code"
                errorText={errors.verificationCode}
              >
                <Input
                  value={values.verificationCode}
                  onChange={({ detail }) => setFieldValue('verificationCode', detail.value)}
                />
              </FormField>
            </Modal>
          )}
        </Formik>
      )}

      {/* Link Google Account Modal */}
      {openLinkGoogleModal && (
        <Modal
          onDismiss={() => setOpenLinkGoogleModal(false)}
          visible={openLinkGoogleModal}
          footer={(
            <Box float="right">
              <Button variant="link" onClick={() => setOpenLinkGoogleModal(false)}>Cancel</Button>
            </Box>
          )}
          header="Please link your Parallel Google account"
        >
          <Box>
            System detected that you are not logged in with Google account.
            Please link your Google account to continue.
          </Box>
          <Button onClick={() => handleLinkGoogle()}>Link Google account</Button>
        </Modal>
      )}

      {/* Link Email Password Modal */}
      {openCreateEmailModal && (
        <Formik
          initialValues={{
            email: signUpEmail,
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required('Email is required'),
            password: Yup.string()
              .required('Password is required')
              .min(8, 'Password must be at least 8 characters')
              .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
              .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
              .matches(/[0-9]/, 'Password must contain at least one number')
              .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),
          })}
          onSubmit={async (values, { setFieldError }) => {
            try {
              const credential = EmailAuthProvider.credential(values.email, values.password);
              await linkWithCredential(auth.currentUser, credential);
              await auth.currentUser.reload();
              setRefresh((prev) => !prev);
            } catch (error) {
              setFieldError('password', error.message);
            }
          }}
        >
          {({
            values, errors, handleSubmit, setFieldValue, isSubmitting,
          }) => (
            <Modal
              header="Please create a new Parallel email account"
              visible={openCreateEmailModal}
              footer={(
                <Box float="right">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  >
                    Create
                  </Button>
                </Box>
              )}
            >
              <SpaceBetween size="xs">
                <Box>
                  You just logged in using your Google account,
                  but the system could not find an existing account with email/password login.
                  Please create an email/password login using
                  your @parallelfluidics.com email address.
                  This is the account you will use to log into www.parallelfluidics.com.
                </Box>
                <FormField label="Email" errorText={errors.email}>
                  <Input value={values.email} disabled />
                </FormField>
                <FormField label="Password" errorText={errors.password}>
                  <Input
                    value={values.password}
                    onChange={({ detail }) => setFieldValue('password', detail.value)}
                    type="password"
                  />
                </FormField>
              </SpaceBetween>
            </Modal>
          )}
        </Formik>
      )}

      {/* MFA Enroll Modal */}
      {openMFAModal && (
        <Formik
          initialValues={{ phoneNum: '' }}
          validationSchema={Yup.object().shape({
            phoneNum: Yup.string()
              .matches(/^\+1\d{10}$/, 'Enter a valid US phone number in the format: +1XXXXXXXXXX.')
              .required('Phone number is required'),
          })}
          onSubmit={async (values, { setFieldError }) => {
            try {
              await enrollMFA(values.phoneNum);
            } catch (error) {
              setFieldError('phoneNum', error.message);
            }
          }}
        >
          {({
            values, errors, handleSubmit, setFieldValue, isSubmitting,
          }) => (
            <Modal
              header="Please enroll in Multi-Factor Authentication"
              visible={openMFAModal}
              footer={(
                <Box float="right">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  >
                    Enroll
                  </Button>
                </Box>
              )}
            >
              <Box>
                You have not enrolled in Multi-Factor Authentication.
                Please enroll to continue.
              </Box>
              <FormField label="Phone Number" errorText={errors.phoneNum}>
                <Input
                  type="tel"
                  value={values.phoneNum}
                  onChange={({ detail }) => setFieldValue('phoneNum', detail.value)}
                />
              </FormField>
            </Modal>
          )}
        </Formik>
      )}

      {/* Detected has Email account  */}
      {openLogoutModal && (
        <Modal
          header="Please re-login with your Parallel email account"
          visible={openLogoutModal}
          footer={(
            <Box float="right">
              <Button onClick={() => handleLogoutAndDelete()}>Logout</Button>
            </Box>
          )}
        >
          <Box>
            You just logged in using your Google account,
            but the system detected an existing account with email/password login.
            Please log out and re-login using your @parallelfluidics.com account.
            Once linked, you’ll be able to use Google for future logins.
          </Box>
        </Modal>
      )}

      {/* Detected Login with other gmail account  */}
      {openPersonalLogoutModal && (
        <Modal
          header="Please re-login with your Parallel email account"
          visible={openPersonalLogoutModal}
          footer={(
            <Box float="right">
              <Button onClick={() => handleLogoutAndDelete()}>Logout</Button>
            </Box>
          )}
        >
          <Box>
            You just logged in with your personal Google account.
            Please log out and use your parallel account to log in.
          </Box>
        </Modal>
      )}

      {/* Email Sent Modal */}
      {openEmailSentModal && (
        <Modal
          header="Verification Email Sent"
          visible={openEmailSentModal}
        >
          <Box>
            A verification email has been sent to your email address.
            Please verify your email to continue.
          </Box>
        </Modal>
      )}

      {canRedirect && children}
    </>
  );
}

LoginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
