import { onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

// The query needs to be wrapped by useMemo to avoid unnecessary re-renders
export default function useRealtimeDocs({ query }) {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const unsubscribe = onSnapshot(query, (snapshot) => {
      const dataArr = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(dataArr);
      setStatus('success');
    }, () => {
      setStatus('error');
    });
    return () => {
      unsubscribe();
    };
  }, [query]);

  return { data, status };
}
