/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Button,
  Checkbox,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { usePart } from '../../features/firebase';
import { fetchUserByEmail } from '../../utils/queries';
import UserEmail from '../UserEmail';

function SharePart({ partId }) {
  const { part } = usePart(partId);

  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finishedSuccessfully, setFinishedSuccessfully] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);

  const {
    values, setFieldValue, handleSubmit, dirty, handleReset, isSubmitting,
  } = useFormik({
    initialValues: {
      hasAccessUsers: part?.hasAccessUsers || [],
      uid: part?.uid,
    },
    enableReinitialize: true,
    onSubmit: async (vals, { setSubmitting }) => {
      try {
        if (!vals.hasAccessUsers.length) {
          throw new Error('At least one user is required');
        }
        // update part doc's hasAccessUsers field
        await updateDoc(doc(getFirestore(), 'parts', part.id), {
          hasAccessUsers: vals.hasAccessUsers,
          uid: vals.uid,
        });
        setFinishedSuccessfully(true);
      } catch (err) {
        setError(err.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSearchAndAddUser = useCallback(async (email) => {
    if (!email) return;
    try {
      setIsUserLoading(true);
      const user = await fetchUserByEmail(email);
      if (!user.id) {
        throw new Error('User not found');
      }
      if (values.hasAccessUsers.includes(user.id)) {
        throw new Error('User already has access');
      }
      setFieldValue('hasAccessUsers', [...values.hasAccessUsers, user.id]);
    } catch (err) {
      setEmailError(err.message);
    } finally {
      setIsUserLoading(false);
    }
  }, [setFieldValue, values.hasAccessUsers]);

  const handleClear = () => {
    setIsModalOpen(false);
    handleReset();
    setError(null);
    setFinishedSuccessfully(false);
  };

  // Disable share for parts created before 2023-04-26
  // See https://github.com/parallel-fluidics/parallel-fluidics/issues/27
  // for more information
  if (part.uploadedAt && part.uploadedAt < 1682467200000) {
    return (
      <Modal
        visible={isModalOpen}
        onDismiss={() => handleClear()}
        header="Unable to share part"
      >
        <p>
          This quote uses an outdated data model and cannot be shared safely.
          Please contact Andy if you need to share this part.
        </p>
      </Modal>
    );
  }

  if (finishedSuccessfully) {
    return (
      <Modal
        visible={isModalOpen}
        onDismiss={() => handleClear()}
        header="Share part"
      >
        <p>Successfully shared part!</p>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal
        visible={isModalOpen}
        onDismiss={() => handleClear()}
        header="Share part"
      >
        <p>
          Error:
          {' '}
          <span style={{ fontWeight: 800 }}>{error}</span>
        </p>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        visible={isModalOpen}
        onDismiss={() => handleClear()}
        header="Share part"
        footer={(
          <Box float="right">
            <SpaceBetween size="xs" direction="horizontal">
              <Button onClick={() => handleClear()}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={!dirty || !values.hasAccessUsers.length}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Share
              </Button>
            </SpaceBetween>
          </Box>
          )}
      >
        <SpaceBetween direction="vertical" size="xs">
          <Box>
            This part is currently owned by
            {' '}
            <span style={{ fontWeight: 800 }}>
              <UserEmail userID={part.uid} />
            </span>
            . Please modify the list below to add or remove users who can access this part.
            {part.uploader && part.uid !== part.uploader && (
              <div>
                This part was originally uploaded by
                {' '}
                <span style={{ fontWeight: 800 }}>
                  <UserEmail userID={part.uploader} />
                </span>
                .
              </div>
            )}
          </Box>
          <Table
            wrapLines
            empty="At least one user is required"
            items={values.hasAccessUsers}
            columnDefinitions={[
              {
                header: 'Owner',
                cell: (userId) => (
                  <Checkbox
                    onChange={() => setFieldValue('uid', userId)}
                    checked={values.uid === userId}
                  />
                ),
              },
              {
                header: 'User',
                cell: (userId) => <UserEmail userID={userId} />,
                isRowHeader: true,
              },
              {
                header: 'Actions',
                cell: (userId) => (
                  <Button
                    onClick={() => {
                      setFieldValue('hasAccessUsers', values.hasAccessUsers.filter((u) => u !== userId));
                    }}
                  >
                    Remove
                  </Button>
                ),
                minWidth: 140,
              },
            ]}
          />
          <FormField
            description="Search for a user by email address"
            errorText={emailError}
          >
            <Input
              onChange={({ detail }) => { setUserEmail(detail.value); setEmailError(null); }}
              value={userEmail}
              disabled={isUserLoading}
            />
          </FormField>
          <Button
            onClick={() => {
              const newEmail = userEmail.trim();
              handleSearchAndAddUser(newEmail);
            }}
            loading={isUserLoading}
          >
            Search and add user
          </Button>
        </SpaceBetween>
      </Modal>

      <Button onClick={() => setIsModalOpen(true)}>
        Share part
      </Button>
    </>
  );
}

SharePart.propTypes = {
  partId: PropTypes.string.isRequired,
};

export default SharePart;
