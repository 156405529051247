import * as Sentry from '@sentry/react';
import { getAuth } from 'firebase/auth';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { useGetUserById } from '../hooks/use-basicQueries';

const UserDataContext = createContext();

// we should use useUserData() in our components to access the user DB data
export default function UserDataProvider({ children }) {
  const { currentUser } = getAuth();
  const { data: user } = useGetUserById(currentUser?.uid);

  useEffect(() => {
    if (currentUser?.uid) {
      Sentry.setUser({
        id: currentUser?.uid,
        email: currentUser?.email,
      });
      return () => {
        Sentry.setUser(null);
      };
    }
    return undefined;
  }, [currentUser]);

  const value = useMemo(() => ({
    user,
  }), [user]);

  // if authUser and no user from db, return null
  if (currentUser && !user) return null;

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}
UserDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return context;
};
