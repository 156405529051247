import 'chartjs-adapter-luxon';

import {
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
} from '@cloudscape-design/components';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartAnnotation from 'chartjs-plugin-annotation';
import * as d3 from 'd3';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

import { useTheme } from '../../features/theme/themeProvider';
import { useProcessData } from './processDataProvider';

ChartJS.register(
  ChartAnnotation,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Legend,
);

const darkModeBorderColor = '#232b37';
function ProcessGraph() {
  const {
    instronData,
    plcData,
    isPlcDataLoading,
    offset,
    setOffset,
  } = useProcessData();
  const { isDarkMode } = useTheme();

  const data = useMemo(() => {
    if (!plcData) {
      return { labels: [], datasets: [] };
    }

    const palette = [
      'red',
      'blue',
      ...d3.schemeCategory10,
      ...d3.schemeSet3,
    ];
    const tempKeys = [
      { key: 'UpperHFTS_ToolTemp', label: 'Top Temp' },
      { key: 'LowerHFTS_ToolTemp', label: 'Bottom Temp' },
      { key: 'UpperHFTS_HP1Temp', label: 'Top HP1' },
      { key: 'UpperHFTS_HP2Temp', label: 'Top HP2' },
      { key: 'UpperHFTS_HP3Temp', label: 'Top HP3' },
      { key: 'UpperHFTS_HP4Temp', label: 'Top HP4' },
      { key: 'UpperHFTS_HP5Temp', label: 'Top HP5' },
      { key: 'UpperHFTS_HP6Temp', label: 'Top HP6' },
      { key: 'UpperHFTS_HotBlockTemp', label: 'Upper Hot Block' },
      { key: 'UpperHFTS_FrontBlockTemp', label: 'Upper Front Block' },
      { key: 'UpperHFTS_RearBlockTemp', label: 'Upper Rear Block' },
      { key: 'LowerHFTS_HP1Temp', label: 'Bottom HP1' },
      { key: 'LowerHFTS_HP2Temp', label: 'Bottom HP2' },
      { key: 'LowerHFTS_HP3Temp', label: 'Bottom HP3' },
      { key: 'LowerHFTS_HP4Temp', label: 'Bottom HP4' },
      { key: 'LowerHFTS_HP5Temp', label: 'Bottom HP5' },
      { key: 'LowerHFTS_HP6Temp', label: 'Bottom HP6' },
      { key: 'LowerHFTS_HotBlockTemp', label: 'Lower Hot Block' },
      { key: 'LowerHFTS_FrontBlockTemp', label: 'Lower Front Block' },
      { key: 'LowerHFTS_RearBlockTemp', label: 'Lower Rear Block' },
    ];

    // only add vacuum if it's exists in the plcData
    if (plcData[0].Vacuum !== null) {
      // insert vacuum after the first two elements (Bottom Temp)
      tempKeys.splice(2, 0, { key: 'Vacuum', label: 'Vacuum' });
    }

    const temps = tempKeys.map(({ label, key }, i) => ({
      label,
      data: plcData.map((row) => ({ timestamp: row.timestamp, [label]: row[key] })),
      borderColor: palette[i],
      backgroundColor: palette[i],
      stepped: key === 'Vacuum',
      yAxisID: 'y',
      pointStyle: false,
      tension: 0.1,
      borderWidth: 2,
      hidden: !['Top Temp', 'Bottom Temp', 'Vacuum'].includes(label),
      tooltip: {
        callbacks: {
          label(context) {
            if (key === 'Vacuum') {
              return `${label}: ${context.raw[label] ? 'On' : 'Off'}`;
            }
            return `${label}: ${context.raw[label].toFixed(1)}°C`;
          },
        },
      },
      parsing: {
        xAxisKey: 'timestamp',
        yAxisKey: label,
      },
    }));
    const forces = instronData?.map((instron, i) => ({
      label: `Force${i > 0 ? ` ${i + 1}` : ''}`,
      data: instron.timestamps.map((timestamp, j) => ({ timestamp, force: instron.forces[j] })),
      borderColor: 'green',
      backgroundColor: 'green',
      yAxisID: 'y1',
      pointStyle: false,
      tension: 0.1,
      borderWidth: 2,
      tooltip: {
        callbacks: {
          label(context) {
            return `Force: ${context.raw.force.toFixed(1)} kN`;
          },
        },
      },
      parsing: {
        xAxisKey: 'timestamp',
        yAxisKey: 'force',
      },
    }));

    const displacements = instronData?.map((instron, i) => ({
      label: `Displacement${i > 0 ? ` ${i + 1}` : ''}`,
      data: instron.timestamps.map(
        (timestamp, j) => ({ timestamp, displacement: instron.displacements[j] }),
      ),
      borderColor: 'darkcyan',
      backgroundColor: 'darkcyan',
      yAxisID: 'y2',
      pointStyle: false,
      tension: 0.1,
      borderWidth: 2,
      hidden: true,
      tooltip: {
        callbacks: {
          label(context) {
            return `Displacement: ${context.raw.displacement.toFixed(1)} mm`;
          },
        },
      },
      parsing: {
        xAxisKey: 'timestamp',
        yAxisKey: 'displacement',
      },
    }));

    return {
      labels: plcData.map(({ timestamp }) => timestamp),
      datasets: [...(forces || []), ...(displacements || []), ...temps],
    };
  }, [plcData, instronData]);

  const plugin = useMemo(() => ({
    id: 'verticalLine',
    defaults: {
      width: 1,
      dash: [3, 3],
    },
    // eslint-disable-next-line no-param-reassign
    afterInit: (chart) => { chart.verticalLine = { x: 0 }; },
    afterEvent: (chart, args) => {
      const { inChartArea, event } = args;
      const { x } = event;

      // eslint-disable-next-line no-param-reassign
      chart.verticalLine = { x, draw: inChartArea };
      chart.draw();
    },
    beforeDatasetsDraw: (chart, _, opts) => {
      const { ctx, chartArea, verticalLine } = chart;
      const { top, bottom } = chartArea;
      const { x, draw } = verticalLine;
      if (!draw) return;

      ctx.save();

      ctx.beginPath();
      ctx.lineWidth = opts.width;
      ctx.strokeStyle = opts.color;
      ctx.setLineDash(opts.dash);
      ctx.moveTo(x, bottom);
      ctx.lineTo(x, top);
      ctx.stroke();

      ctx.restore();
    },
  }), []);

  const options = useMemo(() => {
    const startTime = instronData?.[0]?.startTime ? new Date(instronData?.[0].startTime) : null;
    const getSecondsLabel = (timestamp) => {
      if (!startTime) return null;
      const time = new Date(timestamp).getTime();
      const diffInSeconds = Math.round((time - startTime) / 1000);

      const minutes = Math.floor(Math.abs(diffInSeconds) / 60);
      const seconds = Math.abs(diffInSeconds) % 60;
      const formattedTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;

      return diffInSeconds < 0 ? `-${formattedTime}` : formattedTime;
    };
    const isDatasetActive = (chart, label) => chart.data.datasets.some(
      (dataset) => dataset.label === label && !dataset.hidden,
    );
    const opts = {
      interaction: {
        mode: 'x',
        intersect: false,
      },
      stacked: false,
      plugins: {
        verticalLine: {
          color: isDarkMode ? 'white' : 'black',
        },
        tooltip: {
          animation: false,
          // Only show the first instance of each dataset in the tooltip.
          // TODO: Figure out if there's a more efficient way to do this.
          // The current complexity is O(n^2) because filter is called for
          // each element in the array.
          filter: (element, index, array) => {
            const firstIndexWithLabel = {};
            for (let i = 0; i < array.length; i += 1) {
              if (firstIndexWithLabel[array[i].dataset.label] === undefined) {
                firstIndexWithLabel[array[i].dataset.label] = i;
              }
            }
            return firstIndexWithLabel[element.dataset.label] === index;
          },
          callbacks: {
            title: (tooltipItems) => {
              if (tooltipItems.length > 0) {
                const timestamp = tooltipItems[0].parsed.x;
                const secLabel = getSecondsLabel(timestamp);
                return secLabel ? `${getSecondsLabel(timestamp)} (${new Date(timestamp).toLocaleString()})` : new Date(timestamp).toLocaleString();
              }
              return '';
            },
          },
        },
        legend: {
          position: 'right',
          onClick: (e, legendItem, legend) => {
            const { chart } = legend;
            const { datasets } = chart.data;
            const index = legendItem.datasetIndex;

            // Get the dataset label
            const clickedLabel = datasets[index].label;

            // Define Force and Displacement labels (update these if different)
            const forceLabel = 'Force';
            const displacementLabel = 'Displacement';

            datasets.forEach((dataset, i) => {
              if (i === index) {
                // Toggle clicked dataset
                datasets[i] = { ...dataset, hidden: !dataset.hidden };
              } else if (
                (clickedLabel === forceLabel && dataset.label === displacementLabel)
                || (clickedLabel === displacementLabel && dataset.label === forceLabel)
              ) {
                // Hide the other dataset
                datasets[i] = { ...dataset, hidden: true };
              }
            });

            // Update the y-axis visibility
            if (chart.options.scales.y1) {
              chart.options.scales.y1.display = isDatasetActive(chart, forceLabel);
            }
            if (chart.options.scales.y2) {
              chart.options.scales.y2.display = isDatasetActive(chart, displacementLabel);
            }

            chart.update();
          },
        },
      },
      animation: {
        duration: 0,
      },
      scales: {
        y: {
          title: { text: 'Temperature (°C)', display: true },
          type: 'linear',
          display: true,
          position: 'left',
          ...(isDarkMode && {
            grid: {
              color: darkModeBorderColor,
            },
            border: {
              color: darkModeBorderColor,
            },
          }),
        },
        x: {
          title: { text: 'Time', display: true },
          type: 'time',
          time: {
            unit: 'minute',
            stepSize: 1,
            displayFormats: {
              minute: 'mm:ss',
            },
          },
          ticks: {
            callback: (value) => {
              if (startTime) {
                return getSecondsLabel(value);
              }
              return new Date(value).toLocaleTimeString();
            },
          },
          ...(isDarkMode && {
            grid: {
              color: darkModeBorderColor,
            },
          }),
        },
      },
    };

    if (instronData?.[0]?.timestamps?.length > 0) {
      opts.scales.y1 = {
        title: { text: 'Force (kN)', display: true },
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ...(isDarkMode && {
          border: {
            color: darkModeBorderColor,
          },
        }),
      };
      opts.scales.y2 = {
        title: { text: 'Displacement (mm)', display: true },
        type: 'linear',
        display: false,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ...(isDarkMode && {
          border: {
            color: darkModeBorderColor,
          },
        }),
      };
    }

    if (isPlcDataLoading) {
      opts.plugins.annotation = {
        annotations: {
          annotation: {
            type: 'box',
            backgroundColor: 'transparent',
            borderWidth: 0,
            label: {
              drawTime: 'afterDatasetsDraw',
              display: true,
              color: 'rgba(208, 208, 208, 0.5)',
              content: 'Loading...',
              font: {
                size: (ctx) => ctx.chart.chartArea.height / 4,
              },
              position: 'center',
            },
          },
        },
      };
    }

    return opts;
  }, [instronData, isDarkMode, isPlcDataLoading]);

  return (
    <Container
      header={(
        <Header actions={(
          <SpaceBetween direction="horizontal">
            <FormField label="Process window (in minutes)">
              <Input
                onChange={({ detail }) => {
                  setOffset(parseInt(detail.value, 10));
                }}
                value={offset}
                type="number"
                inputMode="numeric"
              />
            </FormField>
          </SpaceBetween>
        )}
        >
          Process Graph
        </Header>
      )}
    >
      <Line options={options} data={data} plugins={[plugin]} />
    </Container>
  );
}

export default ProcessGraph;
