import {
  ContentLayout,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { OrderList } from '../../components/Orders';
import OrderDetailProvider from '../../components/Orders/orderDetailProvider';
import PartsList from '../../components/PartsList';
import PasswordReset from '../../components/PasswordReset';
import { useGetUserById } from '../../hooks/use-basicQueries';
import useRealtimeDocs from '../../hooks/use-realtimeDocs';

function User() {
  const { userID } = useParams();
  const db = getFirestore();
  const { data: userData, isFetching, error } = useGetUserById(userID);
  const userPartsQuery = useMemo(() => query(collection(db, 'parts'), where('uid', '==', userID), orderBy('expiresAt', 'desc')), [db, userID]);
  const ordersQuery = useMemo(() => query(collection(db, 'orders'), where('uid', '==', userID), orderBy('orderDate', 'desc')), [db, userID]);

  const { data: orders, status: ordersStatus } = useRealtimeDocs({ query: ordersQuery });
  const { data: parts, status: partsStatus } = useRealtimeDocs({ query: userPartsQuery });

  if (isFetching) {
    return <span>{`${userID} (loading email...)`}</span>;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <ContentLayout
      header={(
        <Header
          variant="h1"
          actions={<PasswordReset userEmail={userData.email} />}
        >
          <span>{userData.email}</span>
        </Header>
    )}
    >
      <SpaceBetween size="m">
        <PartsList
          header={<Header>Parts</Header>}
          parts={parts}
          isLoading={partsStatus === 'loading'}
        />

        {ordersQuery && (
          <OrderDetailProvider>
            <OrderList
              header={<Header>Orders</Header>}
              orders={orders}
              isLoading={ordersStatus === 'loading'}
            />
          </OrderDetailProvider>
        )}
      </SpaceBetween>
    </ContentLayout>
  );
}

export default User;
