import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';

import useCustomerProjects from '../../../hooks/use-customerProjects';
import useInternalProjects from '../../../hooks/use-internalProjects';
import AddProjectModal from '../../Projects/addProjectModal';

const ManageOrderContext = createContext();
export default function ManageOrderProvider({ children }) {
  const { internalProjectOptions } = useInternalProjects();
  const { customerProjects, customerProjectOptions } = useCustomerProjects();

  const [openAddProjectModal, setOpenAddProjectModal] = useState(false);

  const projectOptions = useMemo(() => {
    const opts = [
      { label: 'Customer Projects', options: customerProjectOptions },
      { label: 'Internal Projects', options: internalProjectOptions },
    ];
    return opts;
  }, [customerProjectOptions, internalProjectOptions]);

  const value = useMemo(() => ({
    projectOptions,
    setOpenAddProjectModal,
  }), [projectOptions]);

  return (
    <ManageOrderContext.Provider value={value}>
      {children}
      {openAddProjectModal && (
        <AddProjectModal
          projects={customerProjects}
          open={openAddProjectModal}
          onClose={() => setOpenAddProjectModal(false)}
        />
      )}
    </ManageOrderContext.Provider>
  );
}
ManageOrderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useManageOrder = () => {
  const context = useContext(ManageOrderContext);
  if (context === undefined) {
    throw new Error('useManageOrder must be used within a ManageOrderProvider');
  }
  return context;
};
