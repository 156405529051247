import { Pagination } from '@cloudscape-design/components';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getDocs,
  limit,
  query,
  startAfter,
} from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';

export default function useCustomInfiniteQuery({ queryKey, queryParams, pageSize }) {
  const [page, setPage] = useState(1);

  const fetchPaginatedData = useCallback(async ({
    pageParam = null,
    queryParamsArr,
    pageSizeNum,
  }) => {
    let q = query(
      ...queryParamsArr,
      limit(pageSizeNum),
    );

    if (pageParam) {
      q = query(
        ...queryParamsArr,
        startAfter(pageParam),
        limit(pageSizeNum),
      );
    }

    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    return {
      data,
      lastVisible: snapshot.docs.length < pageSizeNum
        ? null
        : snapshot.docs[snapshot.docs.length - 1],
    };
  }, []);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKey, queryParams],
    queryFn: ({ pageParam }) => fetchPaginatedData({
      pageParam,
      queryParamsArr: queryParams,
      pageSizeNum: pageSize,
    }),
    getNextPageParam: (lastPage) => lastPage.lastVisible || null,
    initialPageParam: null,
  });

  useEffect(() => {
    setPage(1);
  }, [queryParams]);

  const handlePageChange = async (newPage) => {
    if (newPage > data?.pages.length) {
      await fetchNextPage();
    }
    setPage(newPage);
  };

  function CustomPagination() {
    return (
      <Pagination
        currentPageIndex={page}
        pagesCount={data?.pages?.length || page}
        openEnd={hasNextPage}
        onChange={({ detail }) => handlePageChange(detail.currentPageIndex)}
        disabled={isFetchingNextPage}
      />
    );
  }

  return {
    data,
    error,
    isFetchingNextPage,
    pageData: data?.pages?.[page - 1]?.data || [],
    CustomPagination,
  };
}
