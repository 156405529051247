import {
  Box,
  Button,
  ColumnLayout,
  Header,
  Link,
  Modal,
  SpaceBetween,
  StatusIndicator,
  Table,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';

import { useGetOrderById, useGetProjectById } from '../../hooks/use-basicQueries';
import OrgName from '../Organization/orgName';
import AddressDisplay from './package/addressDisplay';

function QuoteIdCell({ projectId }) {
  const { data: project, isFetching } = useGetProjectById(projectId);
  if (isFetching) {
    return 'Loading...';
  }
  if (!project) {
    return 'Not Found';
  }

  // The quote ID is the first 6 characters of the part ID (pulled from the admin link)
  const partId = project.adminLink.match(/part\/(.+?)(\/|$)/)[1];
  return partId.substring(0, 6).toUpperCase();
}

QuoteIdCell.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function FilenameCell({ projectId }) {
  const { data: project, isFetching } = useGetProjectById(projectId);
  if (isFetching) {
    return 'Loading...';
  }
  if (!project) {
    return 'Not Found';
  }
  return (
    <Link href={project.adminLink}>
      {project.filename}
    </Link>
  );
}

FilenameCell.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function MaterialCell({ projectId }) {
  const { data: project, isFetching } = useGetProjectById(projectId);
  if (isFetching) {
    return 'Loading...';
  }
  if (!project) {
    return 'Not Found';
  }
  return project.material;
}

MaterialCell.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function LineItems({
  lineItems = [],
}) {
  const headerData = [
    {
      id: 'kickoffDoc',
      header: 'Kickoff Doc',
      cell: (item) => item.kickoffButton,
    },
    {
      id: 'filename',
      header: 'Filename',
      cell: (item) => item.filename,
    },
    {
      id: 'quoteId',
      header: 'Quote ID',
      cell: (item) => item.quoteId,
    },
    {
      id: 'projectId',
      header: 'Project ID',
      cell: (item) => item.projectId,
    },
    {
      id: 'quantity',
      header: 'Quantity',
      cell: (item) => item.quantity,
    },
    {
      id: 'material',
      header: 'Material',
      cell: (item) => item.material,
    },
    {
      id: 'bonding',
      header: 'Is Capped',
      cell: (item) => item.bonding,
    },
  ];

  const rowData = useMemo(() => {
    const rows = [];
    if (!lineItems) return rows;
    lineItems.forEach((lineItem) => {
      rows.push({
        id: lineItem.id,
        kickoffButton: lineItem.kickoffDoc ? <Button href={lineItem.kickoffDoc} target="_blank" rel="noreferrer" iconName="file" variant="inline-icon" /> : <Box color="text-status-error">MISSING</Box>,
        filename: <FilenameCell projectId={lineItem.projectId} />,
        quoteId: <QuoteIdCell projectId={lineItem.projectId} />,
        projectId: lineItem.projectId,
        quantity: lineItem.quantity,
        material: <MaterialCell projectId={lineItem.projectId} />,
        bonding: lineItem.bonding,
      });
    });
    return rows;
  }, [lineItems]);

  return (
    <Table
      columnDefinitions={headerData}
      items={rowData}
      variant="embedded"
    />
  );
}

LineItems.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({
    adminLink: PropTypes.string,
    filename: PropTypes.string,
    projectId: PropTypes.string,
    quantity: PropTypes.number,
    bonding: PropTypes.string,
  })),
};

function OrderDetail({ orderId = null, setOrderId }) {
  const { data: order = {}, isFetching } = useGetOrderById(orderId);
  const isOpen = useMemo(() => !!orderId, [orderId]);

  if (!orderId) {
    return null;
  }

  return (
    <Modal
      size="large"
      visible={isOpen}
      header={(
        <Header variant="h2">
          Order Details
        </Header>
      )}
      onDismiss={() => { setOrderId(undefined); }}
    >
      {isFetching ? (
        <StatusIndicator variant="loading">
          Loading...
        </StatusIndicator>
      ) : (
        <SpaceBetween size="s">
          <ColumnLayout columns={2}>
            <div>
              <Box variant="awsui-key-label">Organization</Box>
              {!!order && (
                <div><OrgName orgId={order.organization} /></div>
              )}
            </div>
            <div>
              <Box variant="awsui-key-label">Order Date</Box>
              <div>{order?.orderDate || 'Loading...'}</div>
            </div>
          </ColumnLayout>
          {(order?.shipments || []).map((shipment, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${shipment.name}_${index}`}>
              <hr style={{ margin: 0 }} />
              <SpaceBetween size="s">
                <ColumnLayout columns={2}>
                  <SpaceBetween size="s">
                    <div>
                      <Box variant="awsui-key-label">Target Ship Date</Box>
                      <div>{shipment.targetShipDate}</div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">Shipping Method</Box>
                      <div>{shipment.shippingMethod}</div>
                    </div>
                  </SpaceBetween>
                  <div>
                    <Box variant="awsui-key-label">Shipping Address</Box>
                    <AddressDisplay
                      address={shipment.addressData}
                      backupAddress={shipment.address}
                    />
                  </div>
                </ColumnLayout>
                <LineItems lineItems={shipment.lineItems} />
              </SpaceBetween>
            </React.Fragment>
          ))}
        </SpaceBetween>
      )}
    </Modal>
  );
}
OrderDetail.propTypes = {
  orderId: PropTypes.string,
  setOrderId: PropTypes.func.isRequired,
};

export default OrderDetail;
