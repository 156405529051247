import { Header } from '@cloudscape-design/components';
import { ProcessNames } from '@parallel-fluidics/constants';
import { where } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';

import useCustomerProjects from '../../hooks/use-customerProjects';
import useInternalProjects from '../../hooks/use-internalProjects';
import DataTable from '../DataTable';
import UserEmail from '../UserEmail';
import TravelerStatus from './travelerStatus';

function SummaryTable({ processType, pageSize }) {
  const { customerProjectOptions } = useCustomerProjects();
  const { internalProjectOptions } = useInternalProjects();

  const linkCell = useCallback(
    (e) => <Link to={`/process-data/${processType}/${e.id}`}>{e.number}</Link>,
    [processType],
  );
  const operatorCell = useCallback(
    (e) => <UserEmail userID={e.user} />,
    [],
  );

  const travelerStatus = useCallback((e) => (
    <TravelerStatus travelerIds={e.travelers} process={e.process} stepIndex={e.currentStepIndex} />
  ), []);

  const columnDefinitions = useMemo(() => {
    const baseHeaders = [
      {
        id: 'number',
        header: 'Chip Number',
        cell: (e) => linkCell(e),
      },
      {
        id: 'project',
        header: 'Project',
        cell: (e) => e.project,
      },
      {
        id: 'start',
        header: 'Start',
        cell: (e) => (e.start ? e.start.toDate().toLocaleString() : ''),
      },
      {
        id: 'user',
        header: 'Operator',
        cell: (e) => operatorCell(e),
      },
      {
        id: 'press',
        header: 'Press',
        cell: (e) => e.press,
      },
      {
        id: 'production',
        header: 'Production Run',
        cell: (e) => (e.production ? '✅' : ''),
      },
      {
        id: 'status',
        header: 'Status',
        cell: (e) => travelerStatus(e),
        minWidth: 200,
      },
      {
        id: 'hypothesis',
        header: 'Hypothesis',
        cell: (e) => e.hypothesis,
      },
      {
        id: 'observations',
        header: 'Observations',
        cell: (e) => e.observations,
      },
    ];

    switch (processType) {
      case ProcessNames.FORMING: {
        return [
          ...(baseHeaders.slice(0, 5)),
          {
            id: 'material',
            header: 'Material',
            cell: (e) => ({
              cop: 'COP',
              pc: 'PC',
              pmma: 'PMMA',
            }[e.material] || e.material),
          },
          {
            id: 'mass',
            header: 'Mass (g)',
            cell: (e) => e.mass,
          },
          ...(baseHeaders.slice(5, baseHeaders.length)),
        ];
      }

      case ProcessNames.BONDING: {
        return [
          ...(baseHeaders.slice(0, 5)),
          {
            id: 'numberOfChips',
            header: 'Number of Chips',
            cell: (e) => e.numberOfChips || '',
          },
          {
            id: 'orientation',
            header: 'Press Orientation',
            cell: (e) => e.pressOrientation,
          },
          ...(baseHeaders.slice(5, baseHeaders.length)),
        ];
      }
      default: {
        return [];
      }
    }
  }, [linkCell, operatorCell, processType, travelerStatus]);

  const filteringOptions = useMemo(() => [
    {
      propertyKey: 'material',
      value: 'cop',
      label: 'COP',
    },
    {
      propertyKey: 'material',
      value: 'pc',
      label: 'PC',
    },
    {
      propertyKey: 'material',
      value: 'pmma',
      label: 'PMMA',
    },
    {
      propertyKey: 'press',
      value: 'press1',
      label: 'Press 1',
    },
    {
      propertyKey: 'press',
      value: 'press2',
      label: 'Press 2',
    },
    {
      propertyKey: 'press',
      value: 'press3',
      label: 'Press 3',
    },
    {
      propertyKey: 'press',
      value: 'press4',
      label: 'Press 4',
    },
    ...[...customerProjectOptions, ...internalProjectOptions].map((option) => ({
      propertyKey: 'project',
      value: option.value,
    })),
  ], [customerProjectOptions, internalProjectOptions]);

  return (
    <DataTable
      id={processType}
      header={(
        <Header>
          {{
            [ProcessNames.FORMING]: 'Forming Data',
            [ProcessNames.BONDING]: 'Bonding Data',
          }[processType]}
        </Header>
      )}
      type="process_logs"
      orderField="start"
      filters={[where('process', '==', processType)]}
      pageSize={pageSize}
      columnDefinitions={columnDefinitions}
      filteringOptions={filteringOptions}
      filteringProperties={[
        {
          key: 'material',
          operators: ['='],
          propertyLabel: 'Material',
          groupValuesLabel: 'Materials',
        },
        {
          key: 'press',
          operators: ['='],
          propertyLabel: 'Press',
          groupValuesLabel: 'Presses',
        },
        {
          key: 'project',
          operators: ['='],
          propertyLabel: 'Project',
          groupValuesLabel: 'Project IDs',
        },
      ]}
      stickyColumns={{ first: 2, last: 0 }}
      resizableColumns
    />
  );
}

SummaryTable.propTypes = {
  processType: PropTypes.oneOf(['forming', 'bonding']).isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default SummaryTable;
