import { useQuery } from '@tanstack/react-query';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import {
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

const ProcessDataContext = createContext({});

function ProcessDataProvider({ children }) {
  const { sessionId } = useParams();
  const bufferTimeRef = useRef(30);

  const [session, setSession] = useState(null);
  const [status, setStatus] = useState('loading');

  const [offset, setOffset] = useState(20);
  const [endTime, setEndTime] = useState(null);

  const { startTime, press } = useMemo(() => {
    if (!session) {
      return {};
    }
    return {
      startTime: new Date(session.start.toDate()),
      press: session.press,
    };
  }, [session]);

  useEffect(() => {
    if (!sessionId) return undefined;
    const unsubscribe = onSnapshot(
      doc(getFirestore(), 'process_logs', sessionId),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setSession({ id: docSnapshot.id, ...docSnapshot.data() });
          setStatus('success');
        } else {
          setStatus('error');
        }
      },
      () => {
        setStatus('error');
      },
    );

    return () => unsubscribe();
  }, [sessionId]);

  useEffect(() => {
    if (!session?.end) {
      return;
    }
    setOffset(Math.ceil((
      session.end.toDate() - session.start.toDate()
    ) / 1000 / 60));
  }, [session]);

  useEffect(() => {
    if (!startTime) return () => {};

    // set a delay to update endTime to avoid unnecessary queries
    const handler = setTimeout(() => {
      const end = new Date(startTime);
      end.setSeconds(end.getSeconds() + offset * 60);
      setEndTime(end);
    }, 500);

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [startTime, offset]);

  const { data: instronData = [], isFetched } = useQuery({
    // instronData will not change by offset, so no need to listen to endTime
    queryKey: ['instronData', startTime, press],
    queryFn: async () => {
      if (!startTime) {
        return {};
      }
      // give ourselves 30s of buffer time
      const startTimeWithBuffer = new Date();
      startTimeWithBuffer.setTime(startTime.getTime() - bufferTimeRef.current * 1000);

      // query firestore for instron_data between start and end
      const q = query(
        collection(getFirestore(), 'instron_data'),
        where('startTime', '>', startTimeWithBuffer),
        where('startTime', '<', endTime),
        where('press', '==', press),
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        bufferTimeRef.current += 30;
        throw new Error('No matching Instron records.');
      }

      const dataArr = [];
      querySnapshot.forEach((snapshot) => {
        const docData = snapshot.data();
        dataArr.push({
          ...docData,
          startTime: docData.startTime.toDate().toISOString(),
          endTime: docData.endTime.toDate().toISOString(),
          timestamps: docData.timestamps.map((t) => t.toDate().toISOString()),
        });
      });
      // update endTime to be the last timestamps of the instron data
      const latestTimestamp = dataArr
        .map((data) => data.timestamps[data.timestamps.length - 1])
        .reduce((latest, current) => (new Date(current) > new Date(latest) ? current : latest));
      const lastDate = new Date(latestTimestamp);
      setEndTime((prev) => {
        if (prev && lastDate > new Date(prev)) {
          return lastDate;
        }
        return prev;
      });

      return dataArr;
    },
    enabled: !!startTime && !!endTime && !!press,
  });

  const { data: plcData, isPending: isPlcDataLoading } = useQuery({
    queryKey: ['plcData', startTime, endTime, press],
    queryFn: async () => {
      const functions = getFunctions();
      const { data } = await httpsCallable(functions, 'queryProcessDataV2')(
        { startTime, endTime, press },
      );
      const d = data.map((row) => ({
        ...row,
        Vacuum: row.Vacuum !== null ? (row.Vacuum === 'true') * 50 : null,
        timestamp: new Date(row.timestamp),
      }));
      return d;
    },
    // Wait for instronData query to be done before making this query
    enabled: !!startTime && !!endTime && !!press && isFetched,
  });

  const value = useMemo(() => ({
    sessionId,
    session,
    status,
    instronData,
    plcData,
    isPlcDataLoading,
    offset,
    setOffset,
  }), [sessionId, instronData, isPlcDataLoading, offset, plcData, session, status]);

  return (
    <ProcessDataContext.Provider value={value}>
      {children}
    </ProcessDataContext.Provider>
  );
}

ProcessDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ProcessDataProvider;

export const useProcessData = () => {
  const context = useContext(ProcessDataContext);
  if (context === undefined) {
    throw new Error('useProcessData must be used within a ProcessDataProvider');
  }
  return context;
};
