/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
import {
  Header,
  Table,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import UserEmail from '../../components/UserEmail';

function Changelog({ travelerId }) {
  const [changeLogs, setChangeLogs] = useState([]);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (!travelerId) return undefined;
    const unsubscribe = onSnapshot(
      query(
        collection(getFirestore(), 'travelersChangelog'),
        where('traveler', '==', travelerId),
      ),
      (snapshot) => {
        const changes = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setChangeLogs(changes);
        setStatus('success');
      },
      () => {
        setStatus('error');
      },
    );

    return () => unsubscribe();
  }, [travelerId]);

  const items = useMemo(() => {
    if (!changeLogs?.length) {
      return [];
    }
    return changeLogs.map((change) => {
      let user;
      const { before, after, timestamp } = change;
      if (after?._updatedBy) {
        user = after._updatedBy.uid;
      }
      const stepKeyDisplayMap = {
        runId: 'Run ID',
        inspected: 'Inspected',
        inspectedNote: 'Inspected Note',
        failureTags: 'Failure Tags',
        minThickness: 'Min Thickness',
        maxThickness: 'Max Thickness',
        inspectionReport: 'Inspection Report',
      };
      const changes = ([
        { key: 'project', label: 'Project', format: (item) => item?.project },
        { key: 'tool', label: 'Tool', format: (item) => item?.tool },
        { key: 'notes', label: 'Additional Notes', format: (item) => item?.notes },
        { key: 'finalInspection', label: 'Final Inspection', format: (item) => item?.finalInspection },
        { key: 'finalFailureTags', label: 'Final FailureTags', format: (item) => (item?.finalFailureTags || []).join(', ') },
        { key: 'inspectionReport', label: 'Inspection Report', format: (item) => (item?.inspectionReport || []).map((report) => report?.name).join(', ') },
        { key: 'shipped', label: 'Shipped', format: (item) => (item?.shipped ? 'Checked' : 'Unchecked') },

        // keep below for old travelers
        { key: 'moldRunId', label: 'Mold Run ID', format: (item) => (item?.moldRunId || []).map((run) => `${run.project} - ${run.runID}`).join(', ') },
        { key: 'moldedPartInspected', label: 'Molded Part Inspection', format: (item) => item?.moldedPartInspected },
        { key: 'bondRunId', label: 'Bond Run ID', format: (item) => (item?.bondRunId || []).map((run) => `${run.project} - ${run.runID}`).join(', ') },
        { key: 'bondedPartInspected', label: 'Bonded Part Inpsection', format: (item) => item?.bondedPartInspected },
        { key: 'moldedNotes', label: 'Molded Notes', format: (item) => item?.moldedNotes },
        { key: 'secondaryNotes', label: 'Secondary Notes', format: (item) => item?.secondaryNotes },
        { key: 'bondedNotes', label: 'Bonded Notes', format: (item) => item?.bondedNotes },
        { key: 'secondaryInspected', label: 'Secondary Inspection', format: (item) => item?.secondaryInspected },
        { key: 'componentsWeldedInspected', label: 'Components Welded Inspection', format: (item) => item?.componentsWeldedInspected },
      ]).map(({ key, label, format }) => {
        if (
          // eslint-disable-next-line max-len
          ((Array.isArray(before?.[key]) ? before[key].length > 0 : before?.[key]) || (Array.isArray(after?.[key]) ? after[key].length > 0 : after?.[key]))
          && !isEqual(before?.[key], after?.[key])
        ) {
          return {
            label,
            key,
            before: before?.[key] !== undefined ? format(before) : null,
            after: after?.[key] !== undefined ? format(after) : null,
          };
        }
        return null;
      }).filter(Boolean);

      // check changes for steps
      if (before?.steps?.length || after?.steps?.length) {
        const maxLength = Math.max(before?.steps?.length || 0, after?.steps?.length || 0);
        for (let idx = 0; idx < maxLength; idx += 1) {
          const beforeStep = before?.steps?.[idx] || {};
          const afterStep = after?.steps?.[idx] || {};
          // check each key in the step, display the difference
          const keys = new Set([...Object.keys(beforeStep), ...Object.keys(afterStep)]);
          keys.forEach((key) => {
            if (
              // eslint-disable-next-line max-len
              ((Array.isArray(beforeStep?.[key]) ? beforeStep[key].length > 0 : beforeStep?.[key]) || (Array.isArray(afterStep?.[key]) ? afterStep[key].length > 0 : afterStep?.[key]))
              && !isEqual(beforeStep?.[key], afterStep?.[key])
              && !!stepKeyDisplayMap[key]
            ) {
              if (key === 'runId') {
                changes.push({
                  label: `${afterStep.name || beforeStep.name} - ${stepKeyDisplayMap[key]}`,
                  key: `${idx}-${key}`,
                  before: beforeStep[key]?.map((run) => `${run.project} - ${run.runID}`).join(', ') || '',
                  after: afterStep[key]?.map((run) => `${run.project} - ${run.runID}`).join(', ') || '',
                });
              } else if (key === 'inspectionReport') {
                changes.push({
                  label: `${afterStep.name || beforeStep.name} - ${stepKeyDisplayMap[key]}`,
                  key: `${idx}-${key}`,
                  before: (beforeStep[key] || []).map((report) => report?.name).join(', ') || '',
                  after: (afterStep[key] || []).map((report) => report?.name).join(', ') || '',
                });
              } else {
                changes.push({
                  label: `${afterStep.name || beforeStep.name} - ${stepKeyDisplayMap[key]}`,
                  key: `${idx}-${key}`,
                  before: Array.isArray(beforeStep[key]) ? beforeStep[key].join(', ') : beforeStep[key] || '',
                  after: Array.isArray(afterStep[key]) ? afterStep[key].join(', ') : afterStep[key] || '',
                });
              }
            }
          });
        }
      }
      return {
        user,
        timestamp,
        changes,
      };
    }).sort((a, b) => b.timestamp - a.timestamp);
  }, [changeLogs]);

  return (
    <Table
      header={
        <Header>History</Header>
      }
      items={items}
      columnDefinitions={[
        {
          id: 'user',
          header: 'User',
          cell: (item) => <UserEmail userID={item.user} backupEmail="System user" />,
        },
        {
          id: 'timestamp',
          header: 'Timestamp',
          cell: (item) => item.timestamp.toDate().toLocaleString(),
        },
        {
          id: 'changes',
          header: 'Changes',
          cell: (item) => (
            <>
              {item.changes.map(({
                key, label, before, after,
              }) => (
                <p key={key}>
                  <strong>{label}</strong>
                  :
                  {' '}
                  <code style={{ backgroundColor: 'lightpink', color: 'red' }}>{before || '█'}</code>
                  {' '}
                  →
                  {' '}
                  <code style={{ backgroundColor: 'lightgreen', color: 'green' }}>{after || '█'}</code>
                </p>
              ))}
            </>
          ),
        },
      ]}
      loading={status === 'loading'}
      loadingText="Loading changelog..."
    />
  );
}

Changelog.propTypes = {
  travelerId: PropTypes.string.isRequired,
};

export default Changelog;
