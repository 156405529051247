import { useQuery } from '@tanstack/react-query';
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from 'firebase/firestore';

export const useGetOrgById = (orgId) => useQuery({
  queryKey: ['getOrgData', orgId],
  queryFn: async () => {
    const orgDocRef = doc(getFirestore(), 'organizations', orgId);
    const orgDoc = await getDoc(orgDocRef);
    if (orgDoc.exists()) {
      return {
        id: orgDoc.id,
        ...orgDoc.data(),
      };
    }
    throw new Error('Organization not found');
  },
  enabled: !!orgId,
  staleTime: 10 * 60 * 1000,
});

export const useGetUserById = (userId) => useQuery({
  queryKey: ['getUserData', userId],
  queryFn: async () => {
    const userDocRef = doc(getFirestore(), 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return {
        id: userDoc.id,
        ...userDoc.data(),
      };
    }
    throw new Error('User not found');
  },
  enabled: !!userId,
  staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
});

export const useGetProjectById = (projectId) => useQuery({
  queryKey: ['getProjectData', projectId],
  queryFn: async () => {
    const projectQuery = query(
      collection(getFirestore(), 'projects'),
      where('name', '==', projectId),
      limit(1),
    );
    const querySnapshot = await getDocs(projectQuery);
    if (!querySnapshot.empty) {
      const projectDoc = querySnapshot.docs[0];
      return {
        id: projectDoc.id,
        ...projectDoc.data(),
      };
    }
    throw new Error('Project not found');
  },
  enabled: !!projectId,
  staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
});

export const useGetPartById = (partId) => useQuery({
  queryKey: ['getPartData', partId],
  queryFn: async () => {
    const partDocRef = doc(getFirestore(), 'parts', partId);
    const partDoc = await getDoc(partDocRef);
    if (partDoc.exists()) {
      return {
        id: partDoc.id,
        ...partDoc.data(),
      };
    }
    throw new Error('Part not found');
  },
  enabled: !!partId,
  staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
});

export const useGetOrderById = (orderId) => useQuery({
  queryKey: ['getOrderData', orderId],
  queryFn: async () => {
    const orderDocRef = doc(getFirestore(), 'orders', orderId);
    const orderDoc = await getDoc(orderDocRef);
    if (orderDoc.exists()) {
      return {
        id: orderDoc.id,
        ...orderDoc.data(),
      };
    }
    throw new Error('Order not found');
  },
  enabled: !!orderId,
  staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
});

export const useGetTravelersByIds = (travelerIds) => useQuery({
  queryKey: ['getTravelersData', travelerIds],
  queryFn: async () => {
    const q = query(
      collection(getFirestore(), 'travelers'),
      where(documentId(), 'in', travelerIds),
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map((d) => d.data());
  },
  enabled: !!travelerIds && travelerIds.length > 0,
  staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
});

export const useGetTravelerById = (travelerId) => useQuery({
  queryKey: ['getTravelerData', travelerId],
  queryFn: async () => {
    const travelerDocRef = doc(getFirestore(), 'travelers', travelerId);
    const travelerDoc = await getDoc(travelerDocRef);
    if (travelerDoc.exists()) {
      return {
        id: travelerDoc.id,
        ...travelerDoc.data(),
      };
    }
    throw new Error('Traveler not found');
  },
  enabled: !!travelerId,
  staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
});

export default useGetOrgById;
