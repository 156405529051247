import {
  Button,
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
} from '@cloudscape-design/components';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';

import { usePackage } from './packageProvider';

const validationSchema = Yup.object({
  length: Yup.number()
    .moreThan(0, 'Length must be greater than 0')
    .required('Length is required'),
  width: Yup.number()
    .moreThan(0, 'Width must be greater than 0')
    .required('Width is required'),
  height: Yup.number()
    .moreThan(0, 'Height must be greater than 0')
    .required('Height is required'),
  weight: Yup.number()
    .moreThan(0, 'Weight must be greater than 0')
    .required('Weight is required'),
});

export default function ParcelForm({ onNext, onPrev }) {
  const { toAddress, setShipmentId, setRates } = usePackage();
  const {
    values, errors, setFieldValue, isValid,
  } = useFormik({
    initialValues: {
      length: 6,
      width: 8,
      height: 6,
      weight: 16,
    },
    validationSchema,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleNext = useCallback(async () => {
    if (!isValid) return;
    try {
      setIsLoading(true);
      const castValues = validationSchema.cast(values);
      const getShippingRates = httpsCallable(getFunctions(), 'getShippingRatesV2');
      const { data } = await getShippingRates({ shipTo: toAddress, parcel: castValues });
      setShipmentId(data.shipmentId);
      setRates(data.rates);
      onNext();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error getting rates', error);
    } finally {
      setIsLoading(false);
    }
  }, [isValid, toAddress, values, setShipmentId, setRates, onNext]);

  const handlePrev = () => {
    setShipmentId('');
    setRates([]);
    onPrev();
  };

  return (
    <Container header={<Header>Parcel Information</Header>}>
      <SpaceBetween size="m">

        <FormField label="Length (inches)" errorText={errors.length}>
          <Input
            type="number"
            value={values.length}
            onChange={({ detail }) => { setFieldValue('length', detail.value); }}
          />
        </FormField>
        <FormField label="Width (inches)" errorText={errors.width}>
          <Input
            type="number"
            value={values.width}
            onChange={({ detail }) => { setFieldValue('width', detail.value); }}
          />
        </FormField>
        <FormField label="Height (inches)" errorText={errors.height}>
          <Input
            type="number"
            value={values.height}
            onChange={({ detail }) => { setFieldValue('height', detail.value); }}
          />
        </FormField>
        <FormField label="Weight (oz)" errorText={errors.weight}>
          <Input
            type="number"
            value={values.weight}
            onChange={({ detail }) => { setFieldValue('weight', detail.value); }}
          />
        </FormField>

        <div style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
          <Button onClick={handlePrev}>Back</Button>
          <Button variant="primary" disabled={!isValid} loading={isLoading} onClick={handleNext}>Next</Button>
        </div>
      </SpaceBetween>
    </Container>
  );
}

ParcelForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};
