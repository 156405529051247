import './sentry';
import './index.scss';
import '@cloudscape-design/global-styles/index.css';

import { I18nProvider } from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en.json';
import { ProcessNames } from '@parallel-fluidics/constants';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import App from './App';
import ThemeProvider from './features/theme/themeProvider';
import EditCADPage from './pages/EditCAD';
import EditFeedbackPage from './pages/EditFeedback';
import EditPricingPage from './pages/EditPricing';
import ErrorPage from './pages/ErrorPage';
import HMI from './pages/HMI';
import HomePage from './pages/Home';
import OrdersPage from './pages/Orders';
import ActiveOrdersPage from './pages/Orders/active';
import CreateOrderPage from './pages/Orders/create';
import CreatePackagePage from './pages/Orders/createPackage';
import ShippedOrdersPage from './pages/Orders/shipped';
import UpdateOrderPage from './pages/Orders/update';
import PartPage, { PartsListPage } from './pages/Part';
import SummaryPage, { ProcessDataPage } from './pages/ProcessData';
import ProjectsPage from './pages/Projects';
import StripeQuotesPage from './pages/Quotes';
import ToolsPage from './pages/Tools';
import TravelerPage from './pages/Travelers';
import UserPage from './pages/User';
import InitializeFirebaseApp from './providers/initializeFirebaseApp';
import * as serviceWorker from './serviceWorker';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(
  createBrowserRouter,
);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'user/:userID',
        element: <UserPage />,
      },
      {
        path: 'parts',
        element: <PartsListPage />,
      },
      {
        path: 'part/:partId',
        element: <PartPage />,
      },
      {
        path: 'part/:partId/cad',
        element: <EditCADPage />,
      },
      {
        path: 'part/:partId/feedback',
        element: <EditFeedbackPage />,
      },
      {
        path: 'part/:partId/pricing',
        element: <EditPricingPage />,
      },
      {
        path: 'orders',
        exact: true,
        element: <OrdersPage />,
      },
      {
        path: 'orders/active',
        exact: true,
        element: <ActiveOrdersPage />,
      },
      {
        path: 'orders/shipped',
        exact: true,
        element: <ShippedOrdersPage />,
      },
      {
        path: 'orders/create',
        exact: true,
        element: <CreateOrderPage />,
      },
      {
        path: 'orders/update/:orderId',
        exact: true,
        element: <UpdateOrderPage />,
      },
      {
        path: 'package/create/:orderId',
        exact: true,
        element: <CreatePackagePage />,
      },
      {
        path: 'projects',
        exact: true,
        element: <ProjectsPage />,
      },
      {
        path: 'tools',
        exact: true,
        element: <ToolsPage />,
      },
      {
        path: 'stripe/quotes',
        exact: true,
        element: <StripeQuotesPage />,
      },
      {
        path: 'process-data/forming',
        element: <SummaryPage processType={ProcessNames.FORMING} />,
        exact: true,
      },
      {
        path: 'process-data/forming/:sessionId',
        element: <ProcessDataPage />,
        exact: true,
      },
      {
        path: 'process-data/bonding',
        element: <SummaryPage processType={ProcessNames.BONDING} />,
        exact: true,
      },
      {
        path: 'process-data/bonding/:sessionId',
        element: <ProcessDataPage />,
        exact: true,
      },
      {
        path: '/live/:pressName',
        element: <HMI />,
        exact: true,
      },
      {
        path: '/travelers/:travelerId',
        element: <TravelerPage />,
        exact: true,
      },
    ],
  },
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <InitializeFirebaseApp>
        <I18nProvider locale="en" messages={[enMessages]}>
          <RouterProvider router={router} />
        </I18nProvider>
      </InitializeFirebaseApp>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
