import './fun.css';

import React, { useEffect, useState } from 'react';

export const shouldShowPrank = () => {
  const now = new Date();
  const isAprilFools = now.getMonth() === 3 && now.getDate() === 1;
  return isAprilFools && !window.location.pathname.includes('/live');
};

export default function AprilFoolsPage() {
  const [isSpinning, setIsSpinning] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [trackCount, setTrackCount] = useState(0);
  const [buttonPosition, setButtonPosition] = useState({ top: '50%', left: '50%' });
  const [showPrank, setShowPrank] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [showRealStop, setShowRealStop] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Check the current date
    const shouldShow = shouldShowPrank();
    const lastShownYear = localStorage.getItem('aprilFoolsShown');
    if (shouldShow && lastShownYear !== String(new Date().getFullYear())) {
      setShowPrank(true);
      // Show the real stop button after 30 seconds in case user got stuck
      setTimeout(() => {
        setShowRealStop(true);
      }, 30000);
    }
  }, []);

  useEffect(() => {
    if (clickCount < 4) {
      // Move the button randomly
      const top = Math.random() * 80 + 10; // 10% - 90%
      const left = Math.random() * 80 + 10;
      setButtonPosition({ top: `${top}%`, left: `${left}%` });
    } else {
      setIsSpinning(false);
      setShowGif(true);
    }
  }, [clickCount]);

  const handleRealStop = () => {
    setIsSpinning(false);
    setShowGif(true);
  };

  const handleTrackCount = () => {
    if (isSpinning) {
      setTrackCount((prev) => prev + 1);
    }
  };

  const handleClose = () => {
    setFadeOut(true);
    // Save to local storage to prevent showing again
    localStorage.setItem('aprilFoolsShown', String(new Date().getFullYear()));

    // After fade-out, remove prank page
    setTimeout(() => {
      setShowPrank(false);
    }, 2000);
  };

  if (!showPrank) return null;

  return (
    <div
      className={`page-blocker ${fadeOut ? 'fade-out' : ''}`}
      role="button"
      tabIndex={0}
      onClick={handleTrackCount}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleTrackCount();
        }
      }}
    >
      <div className={`spinContainer ${isSpinning ? 'spinning' : ''}`}>
        <h1>April Fool&apos;s Surprise 🎭</h1>
        <div style={{ fontSize: 24, marginBottom: 8 }}>
          {isSpinning ? `Tried ${trackCount}` : `You stopped the spinning with ${trackCount} clicks!`}
        </div>
        {showGif ? (
          <>
            <div className="gif-container" />
            <button className="stop-button" style={{ marginTop: 20 }} type="button" onClick={handleClose}>Close</button>
          </>
        ) : (
          <button
            type="button"
            className="stop-button"
            onClick={() => setClickCount((prev) => prev + 1)}
            style={{
              position: 'absolute',
              top: buttonPosition.top,
              left: buttonPosition.left,
              transform: 'translate(-50%, -50%)',
            }}
          >
            Stop!
          </button>
        )}
      </div>
      {showRealStop && isSpinning && (
        <button type="button" className="stop-button bottom-right" onClick={handleRealStop}>
          I need help! Stop spinning (for real)!
        </button>
      )}
    </div>
  );
}
