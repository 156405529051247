import {
  Button,
  Container,
  ContentLayout,
  Header,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ManageOrder } from '../../components/Orders';

export default function UpdateOrderPage() {
  const { orderId } = useParams();
  const navigate = useNavigate();

  // fetch order based on orderId
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (!orderId) return undefined;
    const unsubscribe = onSnapshot(
      doc(getFirestore(), 'orders', orderId),
      (document) => {
        if (document.exists()) {
          setOrder({ id: document.id, ...document.data() });
          setStatus('success');
        } else {
          setStatus('error');
        }
      },
      () => {
        setStatus('error');
      },
    );

    return () => unsubscribe();
  }, [orderId]);

  if (!orderId || status === 'loading' || !order) {
    return (
      <ContentLayout
        header={(
          <Header
            actions={<Button variant="normal" onClick={() => { navigate('/orders'); }}>See all orders</Button>}
          >
            Update an Order
          </Header>
        )}
      >
        <Container>{status === 'loading' ? 'Loading' : 'Invalid order ID or something went wrong'}</Container>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout
      header={(
        <Header
          actions={<Button variant="normal" onClick={() => { navigate('/orders'); }}>See all orders</Button>}
        >
          Update an Order
        </Header>
        )}
    >
      <ManageOrder initData={order} isEditing />
    </ContentLayout>
  );
}
