import {
  Box,
  FormField,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { usePLCValue } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

export default function FirebaseSession() {
  const programHandle = useProgramHandle();
  const firebaseSessionId = usePLCValue(`${programHandle}.sFirebaseSessionID`);

  const [observations, setObservations] = useState('');
  const [session, setSession] = useState(null);

  useEffect(() => {
    if (!firebaseSessionId) return undefined;
    const unsubscribe = onSnapshot(doc(getFirestore(), 'process_logs', firebaseSessionId), (document) => {
      const data = document.data();
      setSession(data);
      setObservations(data?.observations || '');
    });

    return () => unsubscribe();
  }, [firebaseSessionId]);

  const debouncedUpdateFirebase = useCallback(
    debounce((newValue) => {
      updateDoc(doc(getFirestore(), 'process_logs', firebaseSessionId), {
        observations: newValue,
      });
    }, 1000),
    [firebaseSessionId],
  );

  return (
    <SpaceBetween size="m">
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Box variant="awsui-key-label">Project</Box>
          <div>{session ? session.project : 'No active session'}</div>
        </div>
        <div style={{ flex: 1 }}>
          <Box variant="awsui-key-label">Number</Box>
          <div>{session ? session.number : 'No active session'}</div>
        </div>
      </div>
      {session?.currentStepNote && (
        <div>
          <Box variant="awsui-key-label">Step Note</Box>
          <div style={{ whiteSpace: 'pre-wrap' }}>{session.currentStepNote}</div>
        </div>
      )}
      <div>
        <Box variant="awsui-key-label">Hypothesis</Box>
        <div style={{ whiteSpace: 'pre-wrap' }}>{session ? session.hypothesis : 'No active session'}</div>
      </div>

      <FormField label="Observations">
        <Textarea
          rows={4}
          disabled={!session}
          value={observations}
          onChange={({ detail }) => {
            setObservations(detail.value);
            debouncedUpdateFirebase(detail.value);
          }}
        />
      </FormField>
    </SpaceBetween>
  );
}
