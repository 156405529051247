import { useQuery } from '@tanstack/react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export default function useInternalProjects() {
  const { data: internalProjects = [], isLoading } = useQuery({
    queryKey: ['getInternalProjects'],
    queryFn: async () => {
      const getInternalPartNumbers = httpsCallable(getFunctions(), 'getInternalPartNumbersV2');

      const { data } = await getInternalPartNumbers();
      return data;
    },
  });

  const internalProjectOptions = useMemo(() => internalProjects.map((project) => ({
    value: project.value,
    label: `${project.value} - ${project.title}`,
  })).sort((a, b) => {
    if (a.value < b.value) return 1;
    if (a.value > b.value) return -1;
    return 0;
  }), [internalProjects]);

  return { internalProjects, internalProjectOptions, isLoading };
}
