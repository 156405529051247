import {
  Box,
  Button,
  Modal,
  TopNavigation,
} from '@cloudscape-design/components';
import { getAuth, signOut } from 'firebase/auth';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '../../features/theme/themeProvider';
import { shouldShowPrank } from '../../pages/AprilFoolsPage';
import { useUserData } from '../../providers/userDataProvider';
import OmniSearch from '../OmniSearch';
import TravelerScanner from '../QRCodeScanner';

function Navigation() {
  const { user } = useUserData();
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [isBatchScan, setIsBatchScan] = useState(false);
  const [scannedTravelers, setScannedTravelers] = useState([]);
  const navigate = useNavigate();
  const { isDarkMode, setIsDarkMode } = useTheme();

  const handleQRCodeSuccess = useCallback((qrCode) => {
    const url = new URL(qrCode);
    if (isBatchScan) {
      const travelerId = url.pathname.split('/').pop();
      setScannedTravelers((prev) => {
        if (prev.includes(travelerId)) {
          return prev;
        }
        return [...prev, travelerId];
      });
    } else {
      window.open(url.pathname, '_blank', 'noopener, noreferrer');
      setIsScannerOpen(false);
    }
  }, [isBatchScan]);

  if (!user) {
    return null;
  }

  return (
    <>
      <Modal
        header="Scan Traveler"
        footer={(
          <Box float="right">
            <Button
              variant="link"
              onClick={() => { setIsScannerOpen(false); }}
            >
              Cancel
            </Button>
          </Box>
        )}
        visible={isScannerOpen}
        onDismiss={() => { setIsScannerOpen(false); setScannedTravelers([]); }}
      >
        {isScannerOpen ? (
          <TravelerScanner
            id="nav-scanner"
            onQRCodeSuccess={handleQRCodeSuccess}
            isBatchScan={isBatchScan}
            scannedTravelers={scannedTravelers}
            onBatchScanChange={(checked) => setIsBatchScan(checked)}
          />
        ) : null}
      </Modal>
      <TopNavigation
        identity={{
          onFollow: () => { navigate('/'); },
          title: <span style={{ cursor: 'pointer' }}>Parascope</span>,
          logo: { src: `${process.env.PUBLIC_URL}/badge-white.svg`, alt: 'Parascope logo' },
        }}
        utilities={[
          ...(shouldShowPrank() ? [{
            type: 'button',
            text: 'April Fools',
            iconName: 'face-happy',
            ariaLabel: 'April Fools',
            disableUtilityCollapse: true,
            onClick: () => {
              localStorage.removeItem('aprilFoolsShown');
              // reload page
              window.location.reload();
            },
          }] : []),
          {
            type: 'button',
            text: 'Scan Traveler',
            iconName: 'file-open',
            ariaLabel: 'Notifications',
            disableUtilityCollapse: true,
            onClick: () => { setIsScannerOpen(true); },
          },
          {
            type: 'button',
            text: isDarkMode ? 'Light Mode' : 'Dark Mode',
            disableUtilityCollapse: true,
            onClick: () => setIsDarkMode((prev) => !prev),
          },
          {
            type: 'button',
            variant: 'link',
            iconName: 'gen-ai',
            text: 'Wishlist',
            ariaLabel: 'Parascope Wishlist',
            disableUtilityCollapse: true,
            href: 'https://form.asana.com/?k=L_SKcM8MkFECzw68gUAivw&d=1202767799562169',
            target: '_blank',
          },
          {
            type: 'button',
            text: 'Sign out',
            iconName: 'user-profile',
            ariaLabel: 'Sign out',
            disableUtilityCollapse: true,
            onClick: () => { signOut(getAuth()); },
          },
        ]}
        search={<OmniSearch />}
      />
    </>
  );
}

export default Navigation;
