import {
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';

export default function useCustomerProjects() {
  const [customerProjects, setCustomerProjects] = useState([]);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(getFirestore(), 'projects'), orderBy('name', 'desc')),
      (snapshot) => {
        const projects = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setCustomerProjects(projects);
        setStatus('success');
      },
      () => {
        setStatus('error');
      },
    );

    return () => unsubscribe();
  }, []);

  const customerProjectOptions = useMemo(
    () => customerProjects.map(({ name, adminLink, filename }) => {
      const partId = adminLink?.match(/.*\/part\/([^/]+)/);
      if (partId && partId.length > 1 && partId[1].length === 20) {
        const quoteId = partId[1].substring(0, 6).toUpperCase();
        return {
          value: name,
          label: `${name} - #${quoteId} (${filename})`,
        };
      }
      return {
        value: name,
        label: `${name} (${filename})`,
      };
    }),
    [customerProjects],
  );

  return { customerProjects, customerProjectOptions, isLoading: status === 'loading' };
}
