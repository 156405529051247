import {
  Badge,
  Button,
  Table,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { StatusColorMap } from '../../constants/orderStatus';
import OrgName from '../Organization/orgName';
import { useOrderDetail } from './orderDetailProvider';

function OrderList({
  orders,
  isLoading,
  Pagination = null,
  header = null,
  variant = 'container',
}) {
  const { setOrderId } = useOrderDetail();
  const navigate = useNavigate();

  const orderRows = useMemo(() => orders.map((order) => {
    const targetShipsArr = [];
    const projectIdSet = new Set();
    order.shipments.forEach((shipment) => {
      targetShipsArr.push(shipment.targetShipDate);
      shipment.lineItems.forEach((lineItem) => {
        projectIdSet.add(lineItem.projectId);
      });
    });
    const targetShips = targetShipsArr.join(', ');
    const projectIds = Array.from(projectIdSet).join(', ');
    return {
      ...order,
      targetShips: <span title={targetShips}>{targetShips}</span>,
      projectIds: <span title={projectIds}>{projectIds}</span>,
      orgName: <OrgName orgId={order.organization} />,
      nameButton: (
        <Button
          variant="inline-link"
          onClick={() => setOrderId(order.id)}
        >
          {order.name || 'MISSING'}
        </Button>
      ),
      statusBadge: (
        <Badge
          color={StatusColorMap[order.status] || 'grey'}
        >
          {order.status}
        </Badge>
      ),
    };
  }), [orders, setOrderId]);

  if (!orderRows?.length) {
    return null;
  }

  return (
    <Table
      header={header}
      variant={variant}
      loading={isLoading}
      items={orderRows}
      stickyHeader={variant === 'full-page'}
      resizableColumns={variant === 'full-page'}
      columnDefinitions={[
        {
          id: 'name',
          header: 'Order ID',
          cell: (item) => item.nameButton,
        },
        {
          id: 'projectIds',
          header: 'Projects',
          cell: (item) => item.projectIds,
          maxWidth: 120,
        },
        {
          id: 'orderDate',
          header: 'Order Date',
          cell: (item) => item.orderDate,
        },
        {
          id: 'targetShips',
          header: 'Target Ship Dates',
          cell: (item) => item.targetShips,
          maxWidth: 120,
        },
        {
          id: 'organization',
          header: 'Organization',
          cell: (item) => item.orgName,
          maxWidth: 120,
        },
        {
          id: 'status',
          header: 'Status',
          cell: (item) => item.statusBadge,
          minWidth: 120,
        },
        ...(variant === 'full-page' ? [
          {
            id: 'actions',
            header: 'Actions',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (item) => <Button onClick={() => navigate(`/orders/update/${item.id}`)}>Edit</Button>,
          },
        ] : []),
      ]}
      pagination={Pagination}
    />
  );
}

OrderList.propTypes = {
  header: PropTypes.node,
  variant: PropTypes.string,
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    status: PropTypes.string,
    shipments: PropTypes.arrayOf(PropTypes.shape({
      targetShipDate: PropTypes.string,
      lineItems: PropTypes.arrayOf(PropTypes.shape({
        projectId: PropTypes.string,
      })),
    })),
    organization: PropTypes.string,
    orderDate: PropTypes.string,
  })).isRequired,
  isLoading: PropTypes.bool.isRequired,
  Pagination: PropTypes.node,
};

export default OrderList;
