import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';

import { useGetUserById } from '../../hooks/use-basicQueries';

const usePart = (partId) => {
  const [part, setPart] = useState({});
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (!partId) return undefined;

    const unsubscribe = onSnapshot(
      doc(getFirestore(), 'parts', partId),
      (snapshot) => {
        setPart({ id: snapshot.id, ...snapshot.data() });
        setStatus('success');
      },
      () => {
        setStatus('error');
      },
    );

    return () => unsubscribe();
  }, [partId]);

  return useMemo(() => ({
    part,
    status,
  }), [part, status]);
};

const usePartAndQuote = (partId) => {
  const { part, status: partQueryStatus } = usePart(partId);
  const quoteId = useMemo(() => part?.quote, [part?.quote]);
  const [quote, setQuote] = useState({});
  const [quoteQueryStatus, setQuoteQueryStatus] = useState('loading');

  useEffect(() => {
    if (!quoteId) return undefined;

    const unsubscribe = onSnapshot(
      doc(getFirestore(), 'quotes', quoteId),
      (snapshot) => {
        setQuote({ id: snapshot.id, ...snapshot.data() });
        setQuoteQueryStatus('success');
      },
      () => {
        setQuoteQueryStatus('error');
      },
    );

    return () => unsubscribe();
  }, [quoteId]);

  const { data: partUser, isFetching, isError } = useGetUserById(part?.uid);

  const status = useMemo(() => {
    if (partQueryStatus === 'loading' || quoteQueryStatus === 'loading' || isFetching) {
      return 'loading';
    }
    if (partQueryStatus === 'error' || quoteQueryStatus === 'error' || isError) {
      return 'error';
    }
    return 'success';
  }, [partQueryStatus, quoteQueryStatus, isFetching, isError]);

  return useMemo(() => {
    if (!partId) {
      return {
        part: {},
        partUser: {},
        quote: {},
        status: 'nonexistent',
      };
    }
    return {
      part: part || {},
      partUser: partUser || {},
      quote: quote || {},
      status,
    };
  }, [part, quote, status, partId, partUser]);
};

export default usePartAndQuote;

export { usePart };
