import {
  Button,
  Header,
  Table,
} from '@cloudscape-design/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import useInternalProjects from '../../hooks/use-internalProjects';

export default function InternalProjectsList() {
  const navigate = useNavigate();
  const { internalProjects, isLoading } = useInternalProjects();

  return (
    <Table
      header={(
        <Header
          variant="awsui-h1-sticky"
        >
          Internal Projects
        </Header>
      )}
      variant="full-page"
      stickyHeader
      loading={isLoading}
      items={internalProjects}
      resizableColumns
      columnDefinitions={[
        {
          id: 'name',
          header: 'Project ID',
          cell: (item) => item.value,
          width: 240,
        },
        {
          id: 'filename',
          header: 'Goal',
          cell: (item) => item.title,
        },
        {
          id: 'tools',
          header: 'Tools',
          // eslint-disable-next-line react/no-unstable-nested-components
          cell: (item) => (
            <Button
              variant="inline-link"
              onClick={() => navigate(`/tools?tools_table-key=project&tools_table-operator=%3D&tools_table-value=${encodeURIComponent(item.value)}&tools_table-operation=and`)}
            >
              Check Tools
            </Button>
          ),
        },
      ]}
    />
  );
}
