import {
  Button,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import { colorBackgroundLayoutMain } from '@cloudscape-design/design-tokens';
import { formatDate } from '@parallel-fluidics/shipping';
import {
  collection,
  getFirestore,
  orderBy,
  where,
} from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveOrderStatus from '../../components/ActiveOrders';
import useCustomInfiniteQuery from '../../hooks/use-customInfiniteQuery';

function ShippedOrdersPage() {
  const navigate = useNavigate();

  const queryParams = useMemo(() => [
    collection(getFirestore(), 'orders'),
    where('hasShippedItems', '==', true),
    orderBy('orderDate', 'desc'),
  ], []);

  const { CustomPagination, pageData } = useCustomInfiniteQuery({ queryKey: 'getShippedOrders', queryParams, pageSize: 10 });

  const shippedOrders = useMemo(() => {
    const projects = [];
    (pageData || []).forEach((order) => {
      (order?.shipments || []).forEach((shipment) => {
        if (!shipment.isShipped) return;
        (shipment?.lineItems || []).forEach((lineItem) => {
          if (lineItem.steps[0]?.goal > 0) {
            const lastTrackingData = shipment.trackingData?.[shipment.trackingData.length - 1];
            const trackShipDate = lastTrackingData?.shipDate
              ? formatDate(lastTrackingData.shipDate)
              : null;

            projects.push({
              lineItemId: lineItem.id,
              organization: order.organization,
              projectNumber: lineItem.projectId,
              shipDate: shipment.targetShipDate,
              shipDateActual: shipment.shipDate || trackShipDate,
              steps: lineItem.steps,
              notes: lineItem.notes,
            });
          }
        });
      });
    });
    projects.sort((a, b) => new Date(b.shipDate).getTime() - new Date(a.shipDate).getTime());
    return projects;
  }, [pageData]);

  return (
    <div>
      <div style={{
        position: 'sticky',
        top: 0,
        zIndex: 5,
        background: colorBackgroundLayoutMain,
        paddingBottom: 16,
      }}
      >
        <Header
          actions={<Button variant="normal" onClick={() => { navigate('/orders/active'); }}>See active orders</Button>}
        >
          Past Orders
        </Header>
        <div style={{ display: 'flex', justifyContent: 'end', paddingTop: 8 }}>
          <CustomPagination />
        </div>
      </div>
      <SpaceBetween direction="vertical" size="l">
        {shippedOrders.map((order) => (
          <ActiveOrderStatus
            key={order.lineItemId || order.projectNumber}
            lineItemId={order.lineItemId || order.projectNumber}
            organization={order.organization}
            projectNumber={order.projectNumber}
            shipDate={order.shipDate}
            shipDateActual={order.shipDateActual}
            steps={order.steps}
            notes={order.notes}
          />
        ))}
      </SpaceBetween>
    </div>
  );
}

export default ShippedOrdersPage;
