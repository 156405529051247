import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from 'firebase/firestore';

export const fetchUserByEmail = async (email) => {
  const normalizedEmail = email.trim().toLowerCase();
  const userQuery = query(
    collection(getFirestore(), 'users'),
    where('email', '==', normalizedEmail),
    limit(1),
  );
  const querySnapshot = await getDocs(userQuery);
  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    return {
      id: userDoc.id,
      ...userDoc.data(),
    };
  }
  throw new Error('User not found');
};

export default fetchUserByEmail;
