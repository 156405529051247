import { getApps, initializeApp } from 'firebase/app';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messageSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export default function InitializeFirebaseApp({ children }) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    try {
      const existingApps = getApps();
      if (existingApps.length > 0) {
        setInitialized(true);
        return;
      }
      initializeApp(firebaseConfig);
      setInitialized(true);
    } catch (error) {
      setInitialized(false);
    }
  }, []);

  if (!initialized) return null;

  return children;
}

InitializeFirebaseApp.propTypes = {
  children: PropTypes.node.isRequired,
};
