/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unknown-property */
import {
  Alert,
  Box,
  Button,
  Container,
  FileUpload,
  FormField,
  Grid,
  Header,
  Modal,
  Select,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import {
  ArcballControls,
  Center,
  Edges,
} from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { useQuery } from '@tanstack/react-query';
import * as Comlink from 'comlink';
import {
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';

import { usePart } from '../../features/firebase';
import CADViewer from '../CADPreview/viewer';
import { CorsWorker as Worker } from './cors-worker';

const HARDWARE_MATERIAL = { opacity: 1.0, color: '#2D92FB' };
const FLUIDIC_LAYER_MATERIAL = { opacity: 1.0, color: '#13A0AA' };
const CAP_MATERIAL = { opacity: 0.5, color: '#B174EF' };
const HIGHLIGHT_MATERIAL = { opacity: 1.0, color: '#F3614D' };
const BLACK_MATERIAL = { opacity: 1.0, color: '#1E1E1E' };
const HIDDEN_MATERIAL = { opacity: 0.0, color: '#FFFFFF' };

const MATERIAL_OPTIONS = [
  { label: 'Hardware Material', value: HARDWARE_MATERIAL },
  { label: 'Fluidic Layer Material', value: FLUIDIC_LAYER_MATERIAL },
  { label: 'Cap Material', value: CAP_MATERIAL },
  { label: 'Highlight Material', value: HIGHLIGHT_MATERIAL },
  { label: 'Black Material', value: BLACK_MATERIAL },
  { label: 'Hidden Material', value: HIDDEN_MATERIAL },
];

function Component({
  attributes, material, index,
}) {
  const positions = useMemo(
    () => new Float32Array(attributes.position.array),
    [attributes.position.array],
  );
  const normals = useMemo(() => {
    if (attributes.normal) {
      return new Float32Array(attributes.normal.array);
    }
    return [];
  }, [attributes.normal.array]);
  const indicies = useMemo(() => new Uint32Array(index.array), [index.array]);

  const { color, opacity } = material;

  return (
    <mesh>
      <bufferGeometry>
        <bufferAttribute attach="index" count={indicies.length} array={indicies} itemSize={1} />
        <bufferAttribute attach="attributes-position" count={positions.length / 3} array={positions} itemSize={3} />
        {attributes.normal ? <bufferAttribute attach="attributes-normal" count={normals.length / 3} array={normals} itemSize={3} /> : null}
      </bufferGeometry>
      <meshPhongMaterial attach="material" color={color} opacity={opacity} transparent={opacity !== 1.0} side={THREE.DoubleSide} />
      <Edges threshold={25} />
    </mesh>
  );
}

Component.propTypes = {
  attributes: PropTypes.shape({
    position: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
    normal: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  material: PropTypes.shape({
    color: PropTypes.string.isRequired,
    opacity: PropTypes.number,
  }).isRequired,
  index: PropTypes.shape({
    array: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

function Viewer({
  meshes, materials, setGenerateGlbFunc,
}) {
  const {
    camera, size, controls, scene,
  } = useThree();
  const groupRef = React.useRef();

  useEffect(() => {
    // create a scene with a camera and controls
    // https://stackoverflow.com/a/23451803
    const saveScene = ({ options, onSuccess, onFailure }) => {
      const exporter = new GLTFExporter();

      exporter.parse(
        scene,
        onSuccess,
        onFailure,
        options,
      );
    };

    setGenerateGlbFunc(() => () => new Promise((resolve, reject) => {
      const onSuccess = (glb) => {
        const blob = new Blob([glb]);
        resolve(window.URL.createObjectURL(blob));
      };

      saveScene({
        options: {
          binary: true,
        },
        onSuccess,
        onFailure: (err) => { reject(err); },
      });
    }));
  }, [scene]);

  const centerPart = () => {
    controls.reset();

    const bbox = new THREE.Box3().setFromObject(groupRef.current);
    const center = bbox.getCenter(new THREE.Vector3());

    const radius = Math.max(...bbox.getSize(new THREE.Vector3()));
    camera.position.set(center.x + radius, center.y + radius, center.z + radius);
    camera.zoom = Math.min(size.width, size.height) / radius;
    camera.lookAt(center);
    camera.updateProjectionMatrix();
  };

  // once we have controls in place, center part
  useEffect(() => {
    if (controls) { centerPart(); }
  }, [controls]);

  return (
    <>
      <Center>
        <ambientLight />
        <group ref={groupRef}>
          {meshes.map((m, i) => {
            const { name, attributes, index } = m;
            return (
              <Component
                key={name + i}
                attributes={attributes}
                material={materials[i]}
                index={index}
              />
            );
          })}
        </group>
      </Center>
      <ArcballControls makeDefault />
    </>
  );
}

Viewer.propTypes = {
  meshes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    attributes: PropTypes.shape({
      position: PropTypes.shape({
        array: PropTypes.arrayOf(PropTypes.number),
      }),
      normal: PropTypes.shape({
        array: PropTypes.arrayOf(PropTypes.number),
      }),
    }),
    index: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
  })).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    opacity: PropTypes.number,
  })).isRequired,
  setGenerateGlbFunc: PropTypes.func.isRequired,
};

function ModelViewer({ fileURL, filename, setGenerateGlbFunc }) {
  const [materials, setMaterials] = useState([]);
  const [error, setError] = useState();

  const { data: meshes, status } = useQuery({
    queryKey: ['generateMeshes', fileURL],
    queryFn: async () => {
      const corsWorker = new Worker(new URL('./getMeshes.js', import.meta.url));
      const worker = Comlink.wrap(corsWorker.getWorker());
      try {
        const result = await worker.generateMeshes(fileURL);
        return result;
      } catch (err) {
        setError(err);
      }
      return [];
    },
    initialData: [],
    enabled: !!fileURL,
  });

  useEffect(() => {
    setMaterials(meshes.map(() => HARDWARE_MATERIAL));
  }, [meshes]);

  if (status === 'error' || error) {
    return (
      <Alert header="Error loading CAD file" type="error">
        {error?.message}
      </Alert>
    );
  }

  if (meshes.length === 0 || materials.length === 0 || meshes.length !== materials.length) {
    return (
      <Alert header="Loading..." type="info">
        Your screen may freeze. Please be patient while the model loads.
      </Alert>
    );
  }

  return (
    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
      <Container
        header={<Header>Preview</Header>}
        fitHeight
        disableContentPaddings
      >
        <Canvas style={{ minHeight: 400, height: 400 }} orthographic>
          <Viewer
            meshes={meshes}
            materials={materials}
            filename={filename}
            setGenerateGlbFunc={setGenerateGlbFunc}
          />
        </Canvas>
      </Container>
      <Container
        header={<Header>Materials</Header>}
        fitHeight
      >
        <SpaceBetween direction="vertical" size="m">
          {materials.map((material, i) => (
            <FormField key={i} label={meshes[i].name ? `Material for ${meshes[i].name}` : `Material for mesh #${i + 1}`}>
              <Select
                selectedOption={MATERIAL_OPTIONS.find((option) => option.value === material)}
                options={MATERIAL_OPTIONS}
                onChange={({ detail }) => {
                  setMaterials((prev) => {
                    const newMaterials = [...prev];
                    newMaterials[i] = detail.selectedOption.value;
                    return newMaterials;
                  });
                }}
              />
            </FormField>
          ))}
        </SpaceBetween>
      </Container>
    </Grid>
  );
}

ModelViewer.propTypes = {
  fileURL: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  setGenerateGlbFunc: PropTypes.func.isRequired,
};

function CADStudio({ partId }) {
  const { part } = usePart(partId);
  const { fileID, filename } = part;

  const previewFileRef = useMemo(() => {
    if (!partId || !fileID) return null;
    return ref(getStorage(), `parts/${partId}/cad/${fileID}.glb`);
  }, [fileID, partId]);

  const LOADING = '<LOADING>';
  const MISSING = '<MISSING>';
  const INVALID = '<INVALID>';

  const [selectedFileType, setSelectedFileType] = useState(undefined);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState('');

  const handleUploadFileUrl = useCallback((files) => {
    const file = files[0];
    if (!file) {
      setSelectedFileUrl('');
      return;
    }
    const fileURL = URL.createObjectURL(file);
    setSelectedFileUrl(fileURL);
  }, []);

  const { data: cadPreviewSrc } = useQuery({
    queryKey: ['cadPreview', previewFileRef],
    queryFn: () => getDownloadURL(previewFileRef),
    enabled: !!previewFileRef,
  });

  const { data: originalFileUrl } = useQuery({
    queryKey: ['getOriginalFileUrl', partId, fileID, filename],
    queryFn: async () => {
      const extension = filename.split('.').pop();
      const storage = getStorage();
      if (['stp', 'step'].includes(extension.toLowerCase())) {
        try {
          await getMetadata(ref(storage, `/parts/${partId}/cad/${fileID}.${extension}`));
          const newUrl = await getDownloadURL(ref(storage, `/parts/${partId}/cad/${fileID}.${extension}`));
          return newUrl;
        } catch (_) {
          return MISSING;
        }
      }
      return INVALID;
    },
    enabled: !!partId && !!fileID && !!filename,
    placeholderData: LOADING,
  });

  useEffect(() => {
    switch (selectedFileType?.value) {
      case 'original': {
        setSelectedFileUrl(originalFileUrl);
        break;
      }
      case 'upload': {
        handleUploadFileUrl(uploadedFiles);
        break;
      }
      default: {
        setSelectedFileUrl('');
        break;
      }
    }
  }, [selectedFileType, originalFileUrl, uploadedFiles, handleUploadFileUrl]);

  const [generateGlb, setGenerateGlbFunc] = useState(null);
  useEffect(() => {
    setGenerateGlbFunc(null);
  }, [selectedFileType?.value]);
  useEffect(() => {
    if (!selectedFileUrl) {
      setGenerateGlbFunc(null);
    }
  }, [selectedFileUrl]);

  const selectOptions = useMemo(() => [{
    label: `Original File${{
      [LOADING]: ' (looking up...)',
      [MISSING]: ' (does not exist)',
      [INVALID]: ' (invalid file type - must be STEP or STP)',
    }[originalFileUrl] || ''}`,
    value: 'original',
    disabled: [LOADING, MISSING, INVALID].includes(originalFileUrl),
  },
  {
    label: 'Upload',
    value: 'upload',
  }], [originalFileUrl]);

  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [confirmationPreviewSrc, setConfirmationPreviewSrc] = useState('');
  useEffect(() => {
    (async () => {
      if (!uploadModalVisible) {
        setConfirmationPreviewSrc('');
        return;
      }
      const glbUrl = await generateGlb();
      setConfirmationPreviewSrc(glbUrl);
    })();
  }, [uploadModalVisible, generateGlb]);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const navigate = useNavigate();

  if (!selectedFileType?.value) {
    return (
      <Container
        header={(
          <Header>
            Edit CAD
          </Header>
        )}
      >
        <Select
          options={selectOptions}
          onChange={({ detail }) => { setSelectedFileType(detail.selectedOption); }}
          selectedOption={selectedFileType}
          placeholder="Select a file to edit"
        />
      </Container>
    );
  }

  return (
    <>
      <Modal
        size="max"
        visible={uploadModalVisible}
        onDismiss={() => setUploadModalVisible(false)}
        header={<Header>Update CAD File</Header>}
        footer={(
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={() => setUploadModalVisible(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  try {
                    setUploading(true);
                    const newFile = await fetch(confirmationPreviewSrc).then((res) => res.blob());
                    await uploadBytes(previewFileRef, newFile);
                    if (uploadedFiles?.length > 0 && selectedFileType?.value === 'upload') {
                      const inputFile = uploadedFiles[0];
                      const storageRef = ref(getStorage(), `parts/${partId}/cad/${fileID}/${inputFile.name}`);
                      await uploadBytes(storageRef, inputFile);
                    }
                    navigate(`/part/${partId}`);
                  } catch (err) {
                    setUploadError(err.message);
                    setUploading(false);
                  }
                }}
                loading={uploading}
                variant="primary"
              >
                Save
              </Button>
            </SpaceBetween>
          </Box>
        )}
      >
        {uploadError ? (
          <Alert type="error">
            {uploadError}
          </Alert>
        ) : null}
        <p>
          You are about to replace the current CAD preview with the updated version.
          You cannot undo this change. Please confirm the changes below before pressing Save.
        </p>
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <Container header={<Header>Old preview</Header>} disableContentPaddings>
            {!cadPreviewSrc ? (
              <Box textAlign="center" margin="m">
                <StatusIndicator type="error">Error with the old CAD preview</StatusIndicator>
              </Box>
            ) : (
              <CADViewer src={cadPreviewSrc} style={{ height: 260, width: '100%' }} />
            )}
          </Container>
          <Container header={<Header>New preview</Header>} disableContentPaddings>
            <CADViewer src={confirmationPreviewSrc} style={{ height: 260, width: '100%' }} />
          </Container>
        </Grid>
      </Modal>
      <SpaceBetween size="xl">
        <Grid gridDefinition={{
          upload: [{ colspan: 8 }, { colspan: 4 }, { colspan: 12 }],
          original: [{ colspan: 12 }, { colspan: 12 }],
        }[selectedFileType.value]}
        >
          <Container
            header={(
              <Header
                actions={(
                  <SpaceBetween>
                    <Button
                      onClick={() => { setUploadModalVisible(true); }}
                      disabled={!generateGlb}
                    >
                      Update
                    </Button>
                  </SpaceBetween>
              )}
              >
                Edit CAD
              </Header>
          )}
          >
            <FormField label="Select a file to edit" stretch>
              <Select
                options={selectOptions}
                onChange={({ detail }) => { setSelectedFileType(detail.selectedOption); }}
                selectedOption={selectedFileType}
                placeholder="Select a file to edit"
              />
            </FormField>
          </Container>
          {selectedFileType?.value === 'upload' && (
            <Container>
              <FormField
                label="Upload a new file"
                description="only .stp and .step files are supported"
              >
                <FileUpload
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
                    dropzoneText: (e) => (e
                      ? 'Drop files to upload'
                      : 'Drop file to upload'),
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: 'Show fewer files',
                    limitShowMore: 'Show more files',
                    errorIconAriaLabel: 'Error',
                  }}
                  onChange={({ detail }) => {
                    setUploadedFiles(detail.value);
                    handleUploadFileUrl(detail.value);
                  }}
                  value={uploadedFiles}
                  tokenLimit={1}
                />
              </FormField>
            </Container>
          )}
        </Grid>
        {selectedFileUrl && selectedFileUrl !== 'upload' ? (
          <ModelViewer
            fileURL={selectedFileUrl}
            filename={filename}
            setGenerateGlbFunc={setGenerateGlbFunc}
          />
        ) : null}
      </SpaceBetween>
    </>
  );
}

CADStudio.propTypes = {
  partId: PropTypes.string.isRequired,
};

export default CADStudio;
