/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { InspectionValues, StepTypes } from '@parallel-fluidics/constants';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { useTheme } from '../../features/theme/themeProvider';

const chipWidth = 8; // passed to CSS as --_chipWidth
const chipHeight = 80; // passed to CSS as --_chipHeight

function formatMinutesToHours(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.round(minutes % 60);
  if (hours === 0) {
    return `${remainingMinutes} mins`;
  }
  return `${hours} hrs ${remainingMinutes} mins`;
}

// DatumWithLabel is only used in OrderHeader and OrderStep
function DatumWithLabel({
  additionalClassName = undefined,
  label,
  value,
  subValue = null,
}) {
  return (
    <p className={`datum ${additionalClassName ?? ''}`}>
      <span className="label">{label}</span>
      {value}
      {subValue > 0 && subValue !== value && (<span className="subValue">{` (${subValue})`}</span>)}
    </p>
  );
}
DatumWithLabel.propTypes = {
  additionalClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  subValue: PropTypes.number,
};

// Chip is only used in OrderStep
function Chip({
  chipDatum,
  title = '',
  onPointerDown = null,
  isHighlighted = false,
  isRangeStart = false,
  isRangeEnd = false,
  isFirst = false,
  isLast = false,
  date = null,
}) {
  const { isDarkMode } = useTheme();

  const getClassName = (inspection, noRunEndDate = false) => {
    switch (inspection) {
      case InspectionValues.NOT_INSPECTED:
        if (noRunEndDate) {
          return ' toBeInspected inProgress';
        }
        return ' toBeInspected';
      case InspectionValues.PASSED:
        return ' passed completed';
      case InspectionValues.REJECT_MAJOR:
        return ' rejectMajor completed';
      case InspectionValues.REJECT_COSMETIC:
        return ' rejectCosmetic completed';
      case InspectionValues.TUNING:
        return ' tuning completed';
      case 'On deck':
        return ' onDeck';
      case 'Nonexistent':
        return ' nonexistent';
      default:
        return '';
    }
  };
  return (
    <li
      className={`
          chip
          ${isHighlighted ? ' highlighted' : ''}
          ${isRangeStart ? ' start' : ''}
          ${isRangeEnd ? ' end' : ''}
          ${isFirst ? ' first' : ''}
          ${isLast ? ' last' : ''}
          ${date ? ' hasDate' : ''}
        `}
    >
      <div className="rangeIndicator" onPointerDown={onPointerDown} />
      {chipDatum.travelerId ? (
        <>
          <Link to={`/travelers/${chipDatum.travelerId}`} target="_blank" data-tooltip-id={`tooltip-${title}-${chipDatum.travelerId}`}>
            <div className={`prevInspection${getClassName(chipDatum.prevInspection)}`} />
            <div className={`curInspection${getClassName(chipDatum.curInspection, !chipDatum.runEndDate)}`}>
              <p className="visuallyHidden">{chipDatum.travelerId}</p>
            </div>
          </Link>
          {(
            chipDatum.inspectedNote
            || chipDatum.failureTags?.length > 0
            || chipDatum.runStartDate
          ) && (
            <Tooltip id={`tooltip-${title}-${chipDatum.travelerId}`} style={{ zIndex: 5 }} variant={isDarkMode ? 'light' : 'dark'}>
              <div>
                {chipDatum?.runStartDate && (
                  <p>
                    <span>Run Start Date: </span>
                    <span>{chipDatum?.runStartDate?.toLocaleString() || 'N/A'}</span>
                  </p>
                )}
                {chipDatum?.runEndDate && (
                  <p>
                    <span>Run End Date: </span>
                    <span>{chipDatum?.runEndDate?.toLocaleString() || 'N/A'}</span>
                  </p>
                )}
                {chipDatum.press && (
                  <p>
                    <span>Press: </span>
                    <span>{chipDatum.press}</span>
                  </p>
                )}
                {chipDatum.runID && (
                  <p>
                    <span>Run ID: </span>
                    <span>{chipDatum.runID}</span>
                  </p>
                )}
                {chipDatum.cavity && (
                  <p>
                    <span>Cavity: </span>
                    <span>{chipDatum.cavity}</span>
                  </p>
                )}
                {chipDatum.failureTags?.length > 0 && (
                  <p>
                    <span>Failure Tags: </span>
                    <span>{chipDatum.failureTags?.join(', ')}</span>
                  </p>
                )}
                {chipDatum.inspectedNote && (
                  <p>
                    <span>Inspected Note: </span>
                    <span>{chipDatum.inspectedNote}</span>
                  </p>
                )}
              </div>
            </Tooltip>
          )}
          {isFirst && date && (
            <p className="date" title={date}>{date}</p>
          )}
          <div className="overlay" />
        </>
      ) : (
        <div className="chipWrapper">
          <div className="prevInspection nonexistent" />
          <div className="curInspection nonexistent">
            <p className="visuallyHidden">Nonexistent chip</p>
          </div>
        </div>
      )}
    </li>
  );
}
Chip.propTypes = {
  chipDatum: PropTypes.shape({
    travelerId: PropTypes.string,
    prevInspection: PropTypes.string,
    curInspection: PropTypes.string,
    runStartDate: PropTypes.instanceOf(Date),
    runEndDate: PropTypes.instanceOf(Date),
    press: PropTypes.string,
    cavity: PropTypes.string,
    runID: PropTypes.number,
    failureTags: PropTypes.arrayOf(PropTypes.string),
    inspectedNote: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  onPointerDown: PropTypes.func,
  isHighlighted: PropTypes.bool,
  isRangeStart: PropTypes.bool,
  isRangeEnd: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  date: PropTypes.string,
};
function OrderStep({
  number,
  title,
  goal,
  statusData,
}) {
  const remainCounts = useMemo(
    () => Math.max(0, goal - statusData.passed),
    [goal, statusData.passed],
  );
  const containerRef = useRef(null);
  const [rangeIdx, setRangeIdx] = useState({ start: 0, end: 0 });
  const [averageProcessTime, setAverageProcessTime] = useState(0);
  const [avgGap, setAvgGap] = useState(0);
  const [estimate, setEstimate] = useState(0);
  const [yieldRate, setYieldRate] = useState(0);
  const [countData, setCountData] = useState({ cycles: 0 });

  // -1 means no pointer is currently captured
  const [draggingPointer, setDraggingPointer] = useState(-1);
  const [draggingStart, setDraggingStart] = useState(false);
  const [draggingEnd, setDraggingEnd] = useState(false);
  const [draggingInvalid, setDraggingInvalid] = useState(false);

  const calculateAverage = useCallback((processLogs, travelersPerLog, stepYield) => {
    if (processLogs.length === 0) return;
    // group processLogs by Date
    const {
      duration, durationCount, gap, gapCount,
    } = processLogs.reduce((acc, log) => {
      const {
        press, start, end,
      } = log;
      const key = `${start.getMonth() + 1}/${start.getDate()}/${start.getFullYear()}`;
      const prevLog = acc[press] || {};
      if (end) {
        acc.duration += end - start;
        acc.durationCount += travelersPerLog;
      }
      if (prevLog.end && prevLog.key === key) {
        acc.gap += start - prevLog.end;
        acc.gapCount += travelersPerLog;
      }

      acc[press] = {
        end: end || null,
        key,
      };
      return acc;
    }, {
      duration: 0, durationCount: 0, gap: 0, gapCount: 0,
    });

    const avgPress = duration / (durationCount || 1) / 1000 / 60;
    const averageGap = gap / (gapCount || 1) / 1000 / 60;
    setAverageProcessTime(Math.round(avgPress));
    setAvgGap(Math.round(averageGap));
    setEstimate(Math.round((avgPress + averageGap) * (remainCounts / ((stepYield / 100) || 1))));
  }, [remainCounts]);

  const groupByDate = useMemo(() => {
    const groupedData = statusData.chipData.reduce((acc, chip, index) => {
      const date = chip.sortDate.toDate();
      const key = `${date.getMonth() + 1}/${date.getDate()}`;
      if (!acc[key]) {
        acc[key] = { startIdx: index, endIdx: index };
      } else {
        acc[key].endIdx = index;
      }
      return acc;
    }, {});

    const groupedArray = Object.keys(groupedData).map((key) => ({
      date: key,
      ...groupedData[key],
    }));

    if ([StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type)) {
      let endGroupIndex = groupedArray.length - 1;
      let rangeEnd = groupedArray[endGroupIndex]?.endIdx || 0;
      let endFound = false;

      // Find endIdx by looking for the last non-"On deck" chip
      while (!endFound && endGroupIndex >= 0) {
        const group = groupedArray[endGroupIndex];
        for (let j = group.endIdx; j >= group.startIdx; j -= 1) {
          if (statusData.chipData[j].curInspection !== 'On deck') {
            rangeEnd = j;
            endFound = true;
            break;
          }
        }
        if (!endFound) {
          endGroupIndex -= 1;
        }
      }

      // After finding rangeEnd, determine rangeStart to meet minimum length
      let rangeStart = groupedArray[endGroupIndex]?.startIdx || 0;
      const minGroupLength = 5; // Minimum length for the range
      let totalLength = rangeEnd - rangeStart;
      endGroupIndex -= 1;

      // Expand start range backwards to meet minimum length if needed
      while (totalLength < minGroupLength && endGroupIndex >= 0) {
        rangeStart = groupedArray[endGroupIndex].startIdx;
        totalLength = rangeEnd - rangeStart;
        endGroupIndex -= 1;
      }
      setRangeIdx({ start: rangeStart, end: rangeEnd });
    }
    return groupedArray;
  }, [statusData.chipData, statusData.type]);

  useEffect(() => {
    if (rangeIdx.end - rangeIdx.start < 1) return;
    // calculate average process time by start to start
    const processMap = {};
    let maxTravelersPerLog = 0;
    const count = statusData.chipData.slice(rangeIdx.start, rangeIdx.end + 1)
      .reduce((acc, curChip) => {
        const {
          processId, runStartDate, runEndDate, press, curInspection, prevInspection,
        } = curChip;

        // calculate yield
        if (prevInspection === InspectionValues.PASSED) {
          if (curInspection === InspectionValues.PASSED) {
            acc.passed += 1;
          } else if (curInspection === InspectionValues.REJECT_COSMETIC) {
            acc.rejectCosmetic += 1;
          } else if (curInspection === InspectionValues.REJECT_MAJOR) {
            acc.rejectMajor += 1;
          } else if (curInspection === InspectionValues.TUNING) {
            acc.tuning += 1;
          } else if (curInspection === InspectionValues.NOT_INSPECTED) {
            acc.toBeInspected += 1;
          } else if (curInspection === 'On deck') {
            acc.onDeck += 1;
          } else {
            acc.unknown += 1;
          }
        }
        if (!runStartDate) return acc; // Skip if runStartDate is undefined

        if (!processMap[processId]) {
          processMap[processId] = {
            press,
            start: runStartDate,
            end: runEndDate,
            travelersCount: 1,
          };
          acc.cycles += 1;
        } else {
          processMap[processId].travelersCount += 1;
        }
        maxTravelersPerLog = Math.max(
          maxTravelersPerLog,
          processMap[processId].travelersCount,
        );

        return acc;
      }, {
        passed: 0,
        toBeInspected: 0,
        onDeck: 0,
        rejectCosmetic: 0,
        rejectMajor: 0,
        tuning: 0,
        unknown: 0,
        cycles: 0,
      });
    setCountData(count);
    const stepYield = Math.round(
      (count.passed / ((count.passed + count.rejectCosmetic + count.rejectMajor) || 1)) * 100,
    );
    setYieldRate(stepYield);
    calculateAverage(Object.values(processMap), maxTravelersPerLog, stepYield);
  }, [rangeIdx.start, rangeIdx.end, statusData, remainCounts, calculateAverage]);

  const handleUpdateRange = useCallback((value, type) => {
    setRangeIdx((prev) => ({
      ...prev,
      [type]: value,
    }));
  }, []);

  const startDragging = useCallback((event, index) => {
    if (index !== rangeIdx.start && index !== rangeIdx.end) return;

    if (draggingPointer !== -1 && draggingPointer !== event.pointerId) {
      // there is a pointer that is already dragging
      // release its capture first
      containerRef.current.releasePointerCapture(draggingPointer);
    }

    // capture pointer
    containerRef.current.setPointerCapture(event.pointerId);
    setDraggingPointer(event.pointerId);

    // deselect current selection
    const selection = document.getSelection();
    selection.empty();

    if (index === rangeIdx.start) {
      setDraggingStart(true);
      setDraggingEnd(false);
    } else if (index === rangeIdx.end) {
      setDraggingStart(false);
      setDraggingEnd(true);
    }
  }, [rangeIdx.start, rangeIdx.end, draggingPointer]);

  const handlePointerMove = useCallback((event) => {
    if (
      (!draggingStart && !draggingEnd)
      || draggingPointer !== event.pointerId
    ) return;

    // get chip index
    const chipsInRow = Math.floor(containerRef.current.getBoundingClientRect().width / chipWidth);
    const columnIndex = Math.floor(event.nativeEvent.offsetX / chipWidth);
    const rowIndex = Math.floor(event.nativeEvent.offsetY / chipHeight);
    const chipIndex = rowIndex * chipsInRow + columnIndex;

    // validate chip index
    if (
      chipIndex < 0
      || chipIndex > statusData.chipData.length - 1
      || (draggingStart && chipIndex === rangeIdx.end)
      || (draggingEnd && chipIndex === rangeIdx.start)
    ) {
      setDraggingInvalid(true);
      return;
    }
    setDraggingInvalid(false);

    if (draggingStart && chipIndex > rangeIdx.end) {
      // flip start and end
      handleUpdateRange(rangeIdx.end, 'start');
      handleUpdateRange(chipIndex, 'end');
      setDraggingStart(false);
      setDraggingEnd(true);
    } else if (draggingStart) {
      handleUpdateRange(chipIndex, 'start');
    } else if (draggingEnd && chipIndex < rangeIdx.start) {
      // flip start and end
      handleUpdateRange(rangeIdx.start, 'end');
      handleUpdateRange(chipIndex, 'start');
      setDraggingEnd(false);
      setDraggingStart(true);
    } else if (draggingEnd) {
      handleUpdateRange(chipIndex, 'end');
    }
  }, [
    draggingStart,
    draggingEnd,
    draggingPointer,
    rangeIdx.start,
    rangeIdx.end,
    statusData.chipData.length,
    handleUpdateRange,
  ]);

  const handlePointerUp = useCallback((event) => {
    if (
      (!draggingStart && !draggingEnd)
      || draggingPointer !== event.pointerId
    ) return;

    // release pointer
    containerRef.current.releasePointerCapture(event.pointerId);
    setDraggingPointer(-1);

    setDraggingStart(false);
    setDraggingEnd(false);
    setDraggingInvalid(false);
  }, [draggingStart, draggingEnd, draggingPointer]);

  return (
    <li
      className="orderStep"
      style={{
        '--_chipWidth': `${chipWidth}px`,
        '--_chipHeight': `${chipHeight}px`,
      }}
    >
      <div className="topRow">
        <h4>
          <span className="number">{number}</span>
          {title}
        </h4>

        <div className="stats">
          {(![StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type)) && (
            <DatumWithLabel label="Yield" value={`${statusData.stepYield}%`} />
          )}
        </div>
      </div>

      {/* data */}
      <ul className="status">
        <li>
          <p className="datum passed">
            <span className="largeNumber">
              <span className="passed">
                {statusData.passed}
                {countData.passed > 0 && countData.passed !== statusData.passed && <span className="subValue">{`(${countData.passed})`}</span>}
              </span>
              <span className="goal">{goal}</span>
            </span>
            <span className="label">Passed</span>
          </p>
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="rejectCosmetic"
            label="Reject (Cosmetic)"
            value={statusData.rejectCosmetic}
            subValue={countData.rejectCosmetic}
          />
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="rejectMajor"
            label="Reject (Major)"
            value={statusData.rejectMajor}
            subValue={countData.rejectMajor}
          />
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="tuning"
            label="Tuning"
            value={statusData.tuning}
            subValue={countData.tuning}
          />
        </li>
        {statusData.toBeInspected > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="toBeInspected"
              label="To-be inspected"
              value={statusData.toBeInspected}
              subValue={countData.toBeInspected}
            />
          </li>
        )}
        {statusData.onDeck > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="onDeck"
              label="On deck"
              value={statusData.onDeck}
              subValue={countData.onDeck}
            />
          </li>
        )}
        {statusData.unknown > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="nonexistent"
              label="Unknown"
              value={statusData.unknown}
              subValue={countData.unknown}
            />
          </li>
        )}
      </ul>

      {[StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type) && (
        <div className="selectedData">
          {statusData.chipData.length > 2 && (
            <button
              type="button"
              className="selectAll"
              onClick={() => setRangeIdx({ start: 0, end: statusData.chipData.length - 1 })}
            >
              Select All
            </button>
          )}
          <ul>
            <li>
              <DatumWithLabel
                additionalClassName="range"
                label="Cycles"
                value={countData.cycles}
              />
            </li>
            <li>
              <DatumWithLabel
                additionalClassName="range"
                label="Average press time"
                value={`${averageProcessTime} min`}
              />
            </li>
            <li>
              <DatumWithLabel
                additionalClassName="range"
                label="Average gap time"
                value={`${avgGap} min`}
              />
            </li>
            {estimate > 0 && (
              <li>
                <DatumWithLabel
                  additionalClassName="range"
                  label="Estimate"
                  value={`${formatMinutesToHours(estimate)}`}
                />
              </li>
            )}
            <li>
              <DatumWithLabel
                additionalClassName="range"
                label="Yield"
                value={`${yieldRate}%`}
              />
            </li>
          </ul>
        </div>
      )}

      {/* chips */}
      <div
        className={`
          chips-container
          ${([StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type)) ? ' rangeSlider' : ''}
          ${draggingStart ? ' draggingStart' : ''}
          ${draggingEnd ? ' draggingEnd' : ''}
          ${draggingInvalid ? ' invalid' : ''}
        `}
        ref={containerRef}
        onPointerMove={(e) => handlePointerMove(e)}
        onPointerUp={(e) => handlePointerUp(e)}
        onPointerCancel={(e) => handlePointerUp(e)}
      >
        <ol className="chips">
          {groupByDate.map(({ startIdx, endIdx, date }, olIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${title}_${olIndex}`}>
              {statusData.chipData.slice(startIdx, endIdx + 1).map((chipDatum, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${title}_${index}`}
                  title={title}
                  chipDatum={chipDatum}
                  onPointerDown={(e) => startDragging(e, startIdx + index)}
                  isHighlighted={
                    rangeIdx.start <= (startIdx + index)
                    && (startIdx + index) <= rangeIdx.end
                  }
                  isRangeStart={(startIdx + index) === rangeIdx.start}
                  isRangeEnd={(startIdx + index) === rangeIdx.end}
                  isFirst={index === 0}
                  isLast={index === endIdx - startIdx}
                  date={date}
                />
              ))}
            </React.Fragment>
          ))}
          {(new Array(remainCounts).fill({})).map((chipDatum, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Chip key={`${title}_${index}`} chipDatum={chipDatum} />
          ))}
        </ol>
      </div>
    </li>
  );
}
OrderStep.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  statusData: PropTypes.shape({
    passed: PropTypes.number.isRequired,
    toBeInspected: PropTypes.number.isRequired,
    rejectCosmetic: PropTypes.number.isRequired,
    rejectMajor: PropTypes.number.isRequired,
    tuning: PropTypes.number.isRequired,
    onDeck: PropTypes.number,
    unknown: PropTypes.number,
    stepYield: PropTypes.number.isRequired,
    chipData: PropTypes.arrayOf(PropTypes.shape({
      travelerId: PropTypes.string,
      prevInspection: PropTypes.string.isRequired,
      curInspection: PropTypes.string.isRequired,
      runStartDate: PropTypes.instanceOf(Date),
    })).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrderStep;
export { DatumWithLabel };
