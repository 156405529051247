import { useQuery } from '@tanstack/react-query';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import PropTypes from 'prop-types';
import React from 'react';

export default function StorageImage({ storagePath, alt = 'Not found', style = {} }) {
  const { data: url } = useQuery({
    queryKey: ['getStorageImage', storagePath],
    queryFn: () => {
      const storage = getStorage();
      const imageRef = ref(storage, storagePath);
      return getDownloadURL(imageRef);
    },
    enabled: !!storagePath,
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
  });

  return url ? <img src={url} alt={alt} style={style} /> : null;
}
StorageImage.propTypes = {
  storagePath: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.shape({}),
};
