import {
  Button,
  Container,
  ContentLayout,
  Grid,
  Header,
} from '@cloudscape-design/components';
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActiveOrdersSummary } from '../../components/ActiveOrders';
import {
  OrderCalendar,
  OrderList,
} from '../../components/Orders';
import OrderDetailProvider from '../../components/Orders/orderDetailProvider';
import PartsList from '../../components/PartsList';
import OrderStatus from '../../constants/orderStatus';
import useRealtimeDocs from '../../hooks/use-realtimeDocs';

function Home() {
  const recentPartsQuery = useMemo(() => query(
    collection(getFirestore(), 'parts'),
    orderBy('expiresAt', 'desc'),
    limit(10),
  ), []);
  const recentOrdersQuery = useMemo(() => query(
    collection(getFirestore(), 'orders'),
    orderBy('orderDate', 'desc'),
    limit(50),
  ), []);

  const { data: parts, status: partsStatus } = useRealtimeDocs({ query: recentPartsQuery });
  const { data: orders, status: ordersStatus } = useRealtimeDocs({ query: recentOrdersQuery });

  const [hasActiveOrders, setHasActiveOrders] = useState(true);

  useEffect(() => {
    const checkOrders = async () => {
      try {
        // Cheapest option: limit(1) + empty check
        const q = query(
          collection(getFirestore(), 'orders'),
          where('status', '==', OrderStatus.IN_PROGRESS),
          limit(1),
        );

        const snapshot = await getDocs(q);
        setHasActiveOrders(!snapshot.empty);
      } catch (error) {
        setHasActiveOrders(false);
      }
    };

    checkOrders();
  }, []);

  const navigate = useNavigate();

  return (
    <OrderDetailProvider>
      <ContentLayout>
        <Grid gridDefinition={[
          ...(hasActiveOrders
            ? [{ colspan: { s: 8, default: 12 } }, { colspan: { s: 4, default: 12 } }]
            : [{ colspan: 12 }]
          ),
          ...[{ colspan: { s: 6, default: 12 } }, { colspan: { s: 6, default: 12 } }],
        ]}
        >
          <Container
            fitHeight
            header={<Header>Upcoming ship dates</Header>}
          >
            <OrderCalendar
              orders={orders}
              isLoading={ordersStatus === 'loading'}
            />
          </Container>
          {hasActiveOrders ? (
            <ActiveOrdersSummary />
          ) : null}
          <Container
            fitHeight
            header={(
              <Header actions={<Button variant="normal" onClick={() => { navigate('/orders'); }}>See all orders</Button>}>
                Orders
              </Header>
            )}
          >
            <OrderList
              variant="embedded"
              orders={orders.slice(0, 10)}
              isLoading={ordersStatus === 'loading'}
            />
          </Container>
          <Container
            fitHeight
            header={(
              <Header actions={<Button variant="normal" onClick={() => { navigate('/parts'); }}>See all parts</Button>}>
                Recent uploads
              </Header>
            )}
          >
            <PartsList
              variant="embedded"
              parts={parts}
              isLoading={partsStatus === 'loading'}
            />
          </Container>
        </Grid>
      </ContentLayout>
    </OrderDetailProvider>
  );
}

export default Home;
