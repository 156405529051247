/* eslint-disable react/no-unstable-nested-components */
import {
  AttributeEditor,
  Box,
  Button,
  Container,
  FormField,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  project: Yup.string().required('Project is required'),
  name: Yup.string().required('Name is required'),
  cavities: Yup.array()
    .of(Yup.object().shape({
      name: Yup.string()
        .required('Cavity name is required')
        .test('unique-cavity-name', 'Cavity names must be unique', (value, context) => {
          if (!value) return true;
          const { options } = context; // Get field info
          const cavities = options.context?.cavities || []; // Get all cavities (using context)
          const duplicateCount = cavities.filter((cavity) => cavity.name === value).length;
          return duplicateCount <= 1; // If count > 1, it's a duplicate
        }),
    }))
    .min(1, 'At least one cavity is required'),
  notes: Yup.string(),
});

export default function AddToolModal({ open, onClose, projects = [] }) {
  const initialValues = useMemo(() => ({
    project: '',
    name: '',
    cavities: [
      {
        name: 'A',
      },
    ],
    notes: '',
  }), []);

  const {
    values, errors, handleSubmit, setFieldValue, isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    onSubmit: async (submitValues) => {
      try {
        await addDoc(collection(getFirestore(), 'tools'), {
          project: submitValues.project,
          name: submitValues.name,
          cavities: submitValues.cavities,
          notes: submitValues.notes,
          createTime: serverTimestamp(),
        });
        onClose();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error adding tool:', error);
      }
    },
  });

  return (
    <Modal
      onDismiss={onClose}
      visible={open}
      header="Create a new tool"
    >
      <form onSubmit={handleSubmit}>
        <SpaceBetween direction="vertical" size="s">
          <FormField
            label="Project Id"
            errorText={errors.project}
          >
            <Select
              filteringType="auto"
              selectedOption={{ label: values.project, value: values.project }}
              onChange={({ detail }) => { setFieldValue('project', detail.selectedOption.value); }}
              options={projects}
            />
          </FormField>
          <FormField
            label="Tool Name"
            errorText={errors.name}
          >
            <Input
              value={values.name}
              onChange={({ detail }) => { setFieldValue('name', detail.value); }}
            />
          </FormField>
          <Container>
            <AttributeEditor
              gridLayout={[
                {
                  rows: [[1]],
                  removeButton: { ownRow: false, width: 'auto' },
                },
              ]}
              onAddButtonClick={() => setFieldValue('cavities', [...values.cavities, { name: String.fromCharCode(65 + values.cavities.length) }])}
              onRemoveButtonClick={({
                detail: { itemIndex },
              }) => {
                if (values.cavities.length === 1) return;
                const tmpItems = [...values.cavities];
                tmpItems.splice(itemIndex, 1);
                setFieldValue('cavities', tmpItems);
              }}
              removeButtonText="Remove"
              items={values.cavities}
              addButtonText="Add new cavity"
              definition={[
                {
                  label: 'Cavity Name',
                  control: (item, itemIndex) => (
                    <FormField
                      errorText={errors.cavities && errors.cavities[itemIndex]?.name}
                    >
                      <Input
                        value={item.name}
                        onChange={({ detail }) => setFieldValue(`cavities[${itemIndex}].name`, detail.value)}
                        placeholder="Enter cavity name"
                      />
                    </FormField>
                  ),
                },
              ]}
              empty="At least one cavity is required."
            />
          </Container>
          <FormField
            label="Notes"
            errorText={errors.notes}
          >
            <Textarea
              value={values.notes}
              onChange={({ detail }) => { setFieldValue('notes', detail.value); }}
            />
          </FormField>

          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onClose}>Cancel</Button>
              <Button variant="primary" type="submit" loading={isSubmitting}>Create</Button>
            </SpaceBetween>
          </Box>
        </SpaceBetween>
      </form>
    </Modal>
  );
}

AddToolModal.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
