import {
  FormField,
  Select,
} from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import {
  collection,
  documentId,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import OrderStatus from '../../../constants/orderStatus';
import useInternalProjects from '../../../hooks/use-internalProjects';

function ProjectSelector({
  setProject,
  value = null,
  error = '',
  includeOther = false,
  enablePastProjectSearch = false,
}) {
  const [searchText, setSearchText] = useState('');
  const { internalProjectOptions } = useInternalProjects();

  const { data: queryProjects = [], isFetching } = useQuery({
    queryKey: ['searchProjects', searchText, enablePastProjectSearch],
    queryFn: async () => {
      // Query on substring of a property value (text search): https://stackoverflow.com/questions/46568142/google-firestore-query-on-substring-of-a-property-value-text-search/56815787#56815787
      const projectsQuery = query(collection(getFirestore(), 'projects'), where('name', '>=', searchText), where('name', '<=', `${searchText}\uf8ff`));
      const querySnapshot = await getDocs(projectsQuery);
      const projects = [];
      if (querySnapshot.empty) return projects;
      querySnapshot.forEach((doc) => {
        const project = doc.data();
        projects.push({
          value: project.name,
          label: `${project.name} - ${project.filename}`,
        });
      });
      return projects;
    },
    enabled: !!searchText && enablePastProjectSearch,
  });

  const handleUpdateSearchText = debounce((newSearchText) => {
    setSearchText(newSearchText);
  }, 1000);

  const [customerOrders, setCustomerOrders] = useState([]);
  const [customerOrdersStatus, setCustomerOrdersStatus] = useState('loading');

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(getFirestore(), 'orders'),
        where('status', '==', OrderStatus.IN_PROGRESS),
      ),
      (snapshot) => {
        const orders = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setCustomerOrders(orders);
        setCustomerOrdersStatus('success');
      },
      () => {
        setCustomerOrdersStatus('error');
      },
    );

    return () => unsubscribe();
  }, []);

  const { data: customerLineItemOptions = [], isPending } = useQuery({
    queryKey: ['lineItems', customerOrders],
    queryFn: async () => {
      if (!customerOrders?.length) return [];

      const lineItems = [];
      const projectIds = new Set();
      const orgIdsSet = new Set();

      customerOrders.forEach((order) => {
        order.shipments.forEach((shipment) => {
          if (shipment.isShipped) return;
          shipment.lineItems.forEach((lineItem) => {
            const lineItemId = lineItem.id;
            projectIds.add(lineItem.projectId);
            orgIdsSet.add(order.organization);
            lineItems.push({
              value: lineItem.projectId,
              targetShipDate: shipment.targetShipDate,
              organization: order.organization,
              orderId: order.id,
              lineItemId,
              steps: lineItem.steps,
            });
          });
        });
      });

      // Sort the line items
      lineItems.sort((a, b) => (a.value < b.value ? 1 : -1));

      // Query Firestore for project filenames
      const projectIdsArr = Array.from(projectIds);
      const projectsQuery = query(collection(getFirestore(), 'projects'), where('name', 'in', projectIdsArr));
      const querySnapshot = await getDocs(projectsQuery);

      // Query Firestore for organization names
      const orgIdsArr = Array.from(orgIdsSet);
      const orgsQuery = query(collection(getFirestore(), 'organizations'), where(documentId(), 'in', orgIdsArr));
      const orgsSnapshot = await getDocs(orgsQuery);

      // Create a map of project IDs to filenames
      const idFileMap = {};
      querySnapshot.forEach((doc) => {
        const project = doc.data();
        idFileMap[project.name] = project.filename;
      });

      // Create a map of organization IDs to names
      const idOrgMap = {};
      orgsSnapshot.forEach((doc) => {
        const org = doc.data();
        idOrgMap[doc.id] = org.name;
      });

      // Add filenames to the line items
      return lineItems.map(({ organization, targetShipDate, ...lineItem }) => ({
        ...lineItem,
        label: `${lineItem.value} - ${idOrgMap[organization]} - ${targetShipDate} - ${idFileMap[lineItem.value]}`,
      }));
    },
    enabled: !!customerOrders?.length, // Only run the query if there are customer orders
  });

  const options = useMemo(() => {
    const opts = [
      { label: 'Customer Projects', options: customerLineItemOptions },
      { label: 'Internal Projects', options: internalProjectOptions },
    ];
    if (queryProjects?.length) {
      opts.unshift({ label: 'Search Results', options: queryProjects });
    }
    if (includeOther) {
      opts.push({
        label: 'Other',
        options: [
          { value: 'other', label: 'Other...' },
        ],
      });
    }
    return opts;
  }, [customerLineItemOptions, internalProjectOptions, queryProjects, includeOther]);

  return (
    <FormField
      label="Project"
      description={(
        <span>
          {'Don\'t see the project you\'re looking for? Add customer projects '}
          <a target="_blank" href="/projects" rel="noreferrer">here</a>
          {' or internal projects '}
          <a target="_blank" href="https://www.notion.so/parallelfluidics/Part-Number-Databases-e357a39850184d9ea21b9b1539a3d95f" rel="noreferrer">here</a>
          .
        </span>
      )}
      stretch
      errorText={error}
    >
      <Select
        selectedOption={value}
        onChange={({ detail }) => setProject(detail.selectedOption)}
        filteringType={enablePastProjectSearch ? 'manual' : 'auto'}
        onLoadItems={({ detail }) => handleUpdateSearchText(detail.filteringText)}
        options={options}
        loadingText="Loading projects"
        placeholder="Choose a project"
        statusType={
          internalProjectOptions.length === 0 || customerOrdersStatus === 'loading' || isPending || isFetching
            ? 'loading' : 'finished'
        }
      />
    </FormField>

  );
}

ProjectSelector.propTypes = {
  setProject: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  error: PropTypes.string,
  includeOther: PropTypes.bool,
  enablePastProjectSearch: PropTypes.bool,
};

export default ProjectSelector;
