import {
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import {
  collection,
  doc,
  getFirestore,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import FileUploader from '../../components/FileUploader';
import { formatCurrency } from '../../utils';

const validationSchema = Yup.object().shape({
  poDate: Yup.string()
    .required('Purchase Order Date is required')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Purchase Order Date must be in the format YYYY-MM-DD'),
});

export default function AddPOModal({ open, onClose, quote }) {
  const functions = getFunctions();
  const navigate = useNavigate();
  const orderRef = useMemo(() => doc(collection(getFirestore(), 'orders')), []);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleCreateOrder = useCallback(async (values) => {
    try {
      const addOrderFromQuote = httpsCallable(functions, 'addOrderFromQuoteV2');
      const reqBody = {
        ...values,
        quoteId: quote.id,
        orderId: orderRef.id,
      };

      const { data: orderId } = await addOrderFromQuote(reqBody);
      // redirect to the order edit page
      navigate(`/orders/update/${orderId}`);
    } catch (error) {
      setErrorMessage(error.message);
    }
  }, [functions, navigate, orderRef.id, quote.id]);

  return (
    <Modal
      onDismiss={onClose}
      visible={open}
      header={`Create an Order for ${quote.number} (${formatCurrency(quote.amount_total / 100)})`}
    >
      <Formik
        initialValues={{
          poDate: '',
          purchaseOrder: [],
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values) => {
          await handleCreateOrder(values);
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <SpaceBetween direction="vertical" size="s">
              <FormField
                label="Purchase Order Date"
                errorText={errors.poDate}
              >
                <Input
                  value={values.poDate}
                  onChange={({ detail }) => { setFieldValue('poDate', detail.value); }}
                />
              </FormField>

              <Container header={(<Header>Upload Purchase Order</Header>)}>
                <FileUploader
                  uploadPath={`orders/${orderRef.id}/purchaseOrder`}
                  uploadedFiles={values.purchaseOrder || []}
                  uploadErrors={errors.purchaseOrder || []}
                  onEmpty={() => setFieldValue('purchaseOrder', [])}
                  onUpload={(newFiles) => setFieldValue('purchaseOrder', newFiles)}
                  fileTypeName="Purchase Order"
                />
              </Container>

              {errorMessage && (
                <Box color="text-status-error">{errorMessage}</Box>
              )}

              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" onClick={onClose}>Cancel</Button>
                  <Button variant="primary" type="submit" loading={isSubmitting}>Create</Button>
                </SpaceBetween>
              </Box>
            </SpaceBetween>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
AddPOModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    id: PropTypes.string,
    amount_total: PropTypes.number,
    number: PropTypes.string,
  }).isRequired,
};
